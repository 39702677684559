import {Grid, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import img from '../../images/noresults.jpg'
import MyDiv from './noDataCard.style'

export default function NoDataCard() {
  const {t} = useTranslation()
  return (
    <MyDiv>
      <Grid container md={12}>
        <Grid item md={4} xs={12} sm={12}>
          <img
            src={img}
            className="img_styl"
          />
        </Grid>
        <Grid sx={{backgroundColor: '#fff', padding: {xs: 1, sm: 1, md: 15}}} item md={8} xs={12} sm={12}>
          <Typography className="text_name">{t('nodatacard1')}</Typography>
          <Typography className="text_name2">{t('nodatacard2')}</Typography>
        </Grid>
      </Grid>
    </MyDiv>
  )
}
