import React from 'react'
import MyDiv from './paymentFailure.style'

export default function PaymentFailure() {
  return (
    <MyDiv>
      <div className="App">
        <header className="App-header">
          <h1>Payment Failed</h1>
        </header>
        <div className="App-content">
          <p>We apologize, but there was an issue with your payment.</p>
          <p>Please check your payment details and try again.</p>
          <div className="button-container">
            <a href="/subscription">Return to Subscription Page</a>
            <a href="/">Go to Home</a>
          </div>
        </div>
      </div>
    </MyDiv>
  )
}
