import {AlternateEmail, Assignment, CreditCard, SportsBasketball} from '@mui/icons-material'
import {TabContext, TabList} from '@mui/lab'
import {Box, Checkbox, FormControl, Grid, IconButton, MenuItem, Select, Tab, Table, TableBody, TableCell, TableContainer, TableRow, Typography} from '@mui/material'
import {styled} from '@mui/system'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import NoDataCard from '../../NoDataCard/noDataCard'
import MyDiv from './lessonAbout.style'

const ITEM_HEIGHT = 60
const Menu = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5,
    },
  },
}
const CustomMenuItem = styled(MenuItem)(({theme}) => ({
  '&.MuiMenuItem-root': {
    backgroundColor: '#ffffff',
    fontFamily: 'Proxima Nova',
    fontSize: '15px',
    fontWeight: '600',
    color: '#000000',
  },
  '&.MuiMenuItem-root:nth-child(2)': {
    // borderTop: '3px solid #1D3160',
    marginTop: '-8px',
  },
  '&.MuiMenuItem-root:last-child': {
    marginBottom: '-8px',
  },
}))

const Placeholder = styled(MenuItem)(({theme}) => ({
  '&.MuiMenuItem-root': {
    display: 'none',
  },
}))
export default function LessonAbout(props) {

  const [value, setValue] = useState(props.teachersData?.data?.userLesson[0]?.id)

  const data = props.teachersData?.data?.userLesson?.filter((item) => item.id === value)

  const {t} = useTranslation()
  const monthData = [
    {
      id: 0,
      name: t('Days'),
    },
    {
      id: 1,
      name: 'morning',
    },
    {
      id: 2,
      name: 'afternoon',
    },
    {
      id: 3,
      name: 'evening',
    },
  ]

  // const textHandle = (text) => {
  //   const regex = /(<([^>]+)>)/ig
  //   return text?.replace(regex, '')
  // }

  const handleChange = (e, newValue) => {
    if (e.target.value) {
      setValue(e.target.value)
    } else if (newValue) {
      setValue(newValue)
    }
  }

  return (
    <MyDiv>
      {props.teachersData?.data?.userLesson.length === 0 ?
        <Box mt={9} sx={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px'}}>
          {/* <Typography
            sx={{display: {xs: 'none', sm: 'none', md: 'block'}}}
            className="category_data"
          >
            {t('Lesson')}
          </Typography> */}
          <NoDataCard />
        </Box> :
        <>
          <Grid container>
            <Grid item md={2.7} className="card_box" xs={12} sx={{display: {xs: 'none', sm: 'none', md: 'block'}}}>
              <TabContext value={value}>
                <Box>
                  <Typography mb={3}
                    className="heading_name"
                  >
                    {t('availableLessons')}
                  </Typography>
                  {props.teachersData?.data?.userLesson?.map((item) => {
                    return (
                      <TabList
                        key={item.id}
                        orientation="vertical"
                        onChange={(e) => handleChange(e, item.id)}
                      >
                        <Tab className="data_label2" label={item.sub_category_name} value={item.id} />
                      </TabList>
                    )
                  })}
                </Box>
              </TabContext>
            </Grid>
            <Grid item md={2.7} xs={12} sx={{display: {xs: 'block', sm: 'block', md: 'none'}}}>
              <FormControl fullWidth>
                <Select
                  MenuProps={Menu}
                  className="select_menu"
                  value={value || 0}
                  onChange={handleChange}
                >
                  <Placeholder value={0}>{t('genderPlaceholder')}</Placeholder>
                  {props.teachersData?.data?.userLesson?.map((item) => {
                    return (
                      <CustomMenuItem key={item.id} value={item.id}>
                        {item.sub_category_name}
                      </CustomMenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={0.3} />
            <Grid item md={9} xs={12}>
              {data?.map((val, index) => {
                return (
                  <Box key={index}>
                    <Grid container>
                      <Grid item md={4} xs={12} className="card_box">
                        <Typography
                          sx={{display: {xs: 'none', sm: 'none', md: 'block'}}}
                          className="heading_name"
                        >
                          {t('Lesson')}
                        </Typography>
                        <Box className="card_data">
                          <Box className="">
                            <Typography className="category_data">
                              {val.sub_category_name}
                            </Typography>
                          </Box>
                          <Box className="flex_wrapper">
                            <IconButton className="list_icons">
                              <Assignment />
                            </IconButton>
                            <Box>
                              <Typography className="data_label">
                                {t('Providedto')}{' '}
                              </Typography>
                              {val.provided_to_list?.map((item, index) => {
                                return (
                                  <Typography className="data_name" key={index}>
                                    {item.provided_to_name}
                                  </Typography>
                                )
                              })}
                            </Box>
                          </Box>
                          <Box className="flex_wrapper">
                            <IconButton className="list_icons">
                              <AlternateEmail />
                            </IconButton>
                            <Box>
                              <Typography className="data_label">
                                {t('LessonisProvided')}{' '}
                              </Typography>
                              {val.lesson_is_provided_list?.map((item, index) => {
                                return (
                                  <Typography className="data_name" key={index}>
                                    {item.member_type}
                                  </Typography>
                                )
                              })}
                            </Box>
                          </Box>
                          <Box className="flex_wrapper">
                            <IconButton className="list_icons">
                              <SportsBasketball />
                            </IconButton>
                            <Box>
                              <Typography className="data_label">
                                {t('Providedfor')}
                              </Typography>
                              {val.lesson_provided_to_place_list?.map((item, index) => {
                                return (
                                  <Typography className="data_name" key={index}>
                                    {item.provided_via_name}
                                  </Typography>
                                )
                              })}
                            </Box>
                          </Box>
                          <Box className="flex_wrapper">
                            <IconButton className="list_icons">
                              <CreditCard />
                            </IconButton>
                            <Box>
                              <Typography className="data_label">
                                {t('Cost')}{' '}
                              </Typography>
                              <Typography className="data_name">
                                €{val.cost} / {val.cost_per_name}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item md={0.5} />
                      <Grid className="card_box" item md={7.5} xs={12}>
                        <Typography className="heading_name">
                          {t('aboutLesson')}
                        </Typography>
                        <Box className="card_data">
                          <Typography className="data_name">
                            <div dangerouslySetInnerHTML={{__html: val.description}} />
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    <Box className="card_box">
                      <Typography className="heading_name">
                        {t('Availability')}
                      </Typography>
                      <Box className="parent_box">
                        <Box className="w-100">
                          <TableContainer>
                            <Table>
                              <TableBody>
                                <TableRow key={index}>
                                  <TableCell className="cell_table">
                                    <Typography className="table_title">
                                      {t('Days')}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                                <TableRow key={index}>
                                  <TableCell className="cell_table">
                                    <Typography className="table_title">
                                      {t('Morning')}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                                <TableRow key={index}>
                                  <TableCell className="cell_table">
                                    <Typography className="table_title">
                                      {t('Afternoon')}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                                <TableRow key={index}>
                                  <TableCell className="cell_table">
                                    <Typography className="table_title">
                                      {t('Evening')}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                        <Box className="w-100">
                          <TableContainer>
                            <Table>
                              <TableBody>
                                <TableRow>
                                  <TableCell className="cell_table">
                                    <Typography className="table_title2">
                                      {t('Monday')}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                                {monthData?.map((day) =>
                                  val.availability
                                    ?.filter((item) => +item?.day_id === 1)
                                    ?.map((value) =>
                                      +value.lot_id ===
                                        +day.id ? (
                                          <TableRow key={value.id}>
                                            <TableCell className="check_select cell_table">
                                              <Checkbox
                                                sx={{padding: '0px!important'}}
                                                checked={value.is_available}
                                                disabled
                                              />
                                            </TableCell>
                                          </TableRow>
                                        ) : null
                                    )
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                        <Box className="w-100">
                          <TableContainer>
                            <Table>
                              <TableBody>
                                <TableRow>
                                  <TableCell className="cell_table">
                                    <Typography className="table_title2">
                                      {t('Tuesday')}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                                {monthData?.map((day) =>
                                  val.availability
                                    ?.filter((item) => +item?.day_id === 2)
                                    ?.map((value) =>
                                      +value.lot_id ===
                                        +day.id ? (
                                          <TableRow key={value.id}>
                                            <TableCell className="check_select cell_table">
                                              <Checkbox
                                                sx={{padding: '0px!important'}}
                                                checked={value.is_available}
                                                disabled
                                              />
                                            </TableCell>
                                          </TableRow>
                                        ) : null
                                    )
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                        <Box className="w-100">
                          <TableContainer>
                            <Table>
                              <TableBody>
                                <TableRow>
                                  <TableCell className="cell_table">
                                    <Typography className="table_title2">
                                      {t('Wednesday')}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                                {monthData?.map((day) =>
                                  val.availability
                                    ?.filter((item) => +item?.day_id === 3)
                                    ?.map((value) =>
                                      +value.lot_id ===
                                        +day.id ? (
                                          <TableRow key={value.id}>
                                            <TableCell className="check_select cell_table">
                                              <Checkbox
                                                sx={{padding: '0px!important'}}
                                                checked={value.is_available}
                                                disabled
                                              />
                                            </TableCell>
                                          </TableRow>
                                        ) : null
                                    )
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                        <Box className="w-100">
                          <TableContainer>
                            <Table>
                              <TableBody>
                                <TableRow>
                                  <TableCell className="cell_table">
                                    <Typography className="table_title2">
                                      {t('Thursday')}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                                {monthData?.map((day) =>
                                  val.availability
                                    ?.filter((item) => +item?.day_id === 4)
                                    ?.map((value) =>
                                      +value.lot_id ===
                                        +day.id ? (
                                          <TableRow key={value.id}>
                                            <TableCell className="check_select cell_table">
                                              <Checkbox
                                                sx={{padding: '0px!important'}}
                                                checked={value.is_available}
                                                disabled
                                              />
                                            </TableCell>
                                          </TableRow>
                                        ) : null
                                    )
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                        <Box className="w-100">
                          <TableContainer>
                            <Table>
                              <TableBody>
                                <TableRow>
                                  <TableCell className="cell_table">
                                    <Typography className="table_title2">
                                      {t('Friday')}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                                {monthData?.map((day) =>
                                  val.availability
                                    ?.filter((item) => +item?.day_id === 5)
                                    ?.map((value) =>
                                      +value.lot_id ===
                                        +day.id ? (
                                          <TableRow key={value.id}>
                                            <TableCell className="check_select cell_table">
                                              <Checkbox
                                                sx={{padding: '0px!important'}}
                                                checked={value.is_available}
                                                disabled
                                              />
                                            </TableCell>
                                          </TableRow>
                                        ) : null
                                    )
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                        <Box className="w-100">
                          <TableContainer>
                            <Table>
                              <TableBody>
                                <TableRow>
                                  <TableCell className="cell_table">
                                    <Typography className="table_title2">
                                      {t('Saturday')}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                                {monthData?.map((day) =>
                                  val.availability
                                    ?.filter((item) => +item?.day_id === 6)
                                    ?.map((value) =>
                                      +value.lot_id ===
                                        +day.id ? (
                                          <TableRow key={value.id}>
                                            <TableCell className="check_select cell_table">
                                              <Checkbox
                                                sx={{padding: '0px!important'}}
                                                checked={value.is_available}
                                                disabled
                                              />
                                            </TableCell>
                                          </TableRow>
                                        ) : null
                                    )
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                        <Box className="w-100">
                          <TableContainer>
                            <Table>
                              <TableBody>
                                <TableRow>
                                  <TableCell className="cell_table">
                                    <Typography className="table_title2">
                                      {t('Sunday')}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                                {monthData?.map((day) =>
                                  val.availability
                                    ?.filter((item) => +item?.day_id === 7)
                                    ?.map((value) =>
                                      +value.lot_id ===
                                        +day.id ? (
                                          <TableRow key={value.id}>
                                            <TableCell className="check_select cell_table">
                                              <Checkbox
                                                sx={{padding: '0px!important'}}
                                                checked={value.is_available}
                                                disabled
                                              />
                                            </TableCell>
                                          </TableRow>
                                        ) : null
                                    )
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )
              })}
            </Grid>
          </Grid>
        </>
      }
    </MyDiv>
  )
}
