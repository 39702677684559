export const SEARCH = '/search'
export const HOME = '/'
export const DASHBOARD = '/dashboard'
export const MYPROFILE = '/myprofile'
export const SCHOOLPROFILE = '/school'
export const MYLOCATIONS = '/mylocations'
export const ABOUTME = '/aboutme'
export const MYLESSONS = '/mylessons'
export const EDUCATIONS = '/educations'
export const EXPERIENCE = '/experience'
export const SKILLSINTEREST = '/skills&interest'
export const GALLERY = '/gallery'
export const MESSAGES = '/messages'
export const FEEDBACK = '/feedback'
export const LOGIN = '/login'
export const SIGNUP = '/signup'
export const REGISTER = '/register'
export const EMAILVERIFICATION = '/emailverify'
export const SUBSCRIPTION = '/subscription'
export const PAYMENTDETAILS = '/paymentdetails'
export const PAYMENTSUCCESS = '/payment-success'
export const UPGRADESUCCESS = '/upgrade-payment-success'
export const PAYMENTFAILURE = '/payment-cancel'
export const UPDATEPASSWORD = '/updatepassword'
export const DISABLEACCOUNT = '/disablemyacccount'

// Proper zunpakaloudela(admin) Routes
export const ADMINDASHBOARD = '/zunpakaloudela/dashboard'
export const SALES = '/zunpakaloudela/sales'
export const CUSTOMER = '/zunpakaloudela/customer'
export const PACKAGES = '/zunpakaloudela/packages'
export const APPROVALS = '/zunpakaloudela/approvals'
export const CATEGORIES = '/zunpakaloudela/categories'
export const REGISTRATIONS = '/zunpakaloudela/registrations'
export const PAGENOTFOUND = '/pagenotfound'

