/* eslint-disable no-constant-condition */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
import {KeyboardArrowDown, KeyboardArrowUp} from '@mui/icons-material'
import {TabContext, TabList, TabPanel} from '@mui/lab'
import {Box, Dialog, IconButton, Slide, Tab} from '@mui/material'
import {useJsApiLoader} from '@react-google-maps/api'
import axios from 'axios'
import React, {lazy, Suspense, useCallback, useEffect, useState} from 'react'
import {isMobile} from 'react-device-detect'
import {useTranslation} from 'react-i18next'
import {connect, useSelector} from 'react-redux'
import {bindActionCreators} from 'redux'
import {CustomButton, Loader, SnackBar} from '../../components'
import {ActionCreators} from '../../redux/actions'
import {SearchDialog} from '../HomePageFilter/components'
import MyDiv from './homePageFilter.style'

// const CategoriesTab = lazy(() => import('./components/CategoriesTab'))
const DistanceTab = lazy(() => import('./components/DistanceTab'))
const FiltersTab = lazy(() => import('./components/FiltersTab'))
const ListviewTab = lazy(() => import('./components/ListviewTab'))
const MapViewTab = lazy(() => import('./components/MapViewTab'))
const MapFilter = lazy(() => import('./components/MapFilter'))
const AuthTopPanelMain = lazy(() => import('../MainDashboard/components/AuthTopPanelMain'))

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="down" ref={ref} {...props} />
})
const containerStyle = {
  width: '100%',
  height: '90vh',
}
const center = {lat: 35.1351148, lng: 32.9527879}
const LIBRARIES = ['places']

const handleFilterChange = (filterProvidedTo, filterProvidedType, filterProvidedWhere, filterDay, filterSlot) => {
  let providedTo = Object.keys(filterProvidedTo).filter((key) => filterProvidedTo[key] === true).toString()
  let providedType = Object.keys(filterProvidedType).filter((key) => filterProvidedType[key] === true).toString()
  let providedWhere = Object.keys(filterProvidedWhere).filter((key) => filterProvidedWhere[key] === true).toString()
  let day = Object.keys(filterDay).filter((key) => filterDay[key] === true)
  let slot = Object.keys(filterSlot).filter((key) => filterSlot[key] === true)

  providedTo = providedTo && `&ages=${providedTo}`
  providedType = providedType && `&placeWhere=${providedType}`
  providedWhere = providedWhere && `&memberType=${providedWhere}`
  day = day && `&day=${day}`
  slot = slot && `&lot=${slot}`
  return {providedTo, providedType, providedWhere, day, slot}
}

// todo -- to extract all localStorage getItems here (tried but it says not defined)
const HomePageFilter = (props) => {
  // console.log(localStorage.getItem('country'))
  const {t} = useTranslation()
  const [value, setValue] = useState('1')
  const [categoryList, setCategoryList] = useState()
  const [filterData, setFilterData] = useState()
  // todo - merge in a reducer (need help from faraz)
  // const [filterSubcategory, setfilterSubCategory] = useState({})
  const [filterProvidedTo, setFilterProvidedTo] = useState({})
  const [filterProvidedType, setFilterProvidedType] = useState({})
  const [filterProvidedWhere, setFilterProvidedWhere] = useState({})
  const [filterDay, setFilterDay] = useState({})
  const [filterSlot, setFilterSlot] = useState({})
  const [marker, setMarker] = useState(null)
  const [distanceValue, setDistanceValue] = useState([])
  const [selectOpen, setSelectOpen] = useState(false)
  const [valueListItem, setValueListItem] = useState([])
  const [openMenu, setOpenMenu] = useState(false)
  const masterState = useSelector((state) => state.masterState)
  const categoryState = useSelector((state) => state.categoryState)
  const globalState = useSelector((state) => state.globalState)
  const [updateBounds, setUpdateBounds] = useState(false)
  const [hasChangedBounds, setHasChangedBounds] = useState(false)
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('')
  const [zoom, setZoom] = useState(null)
  const [show, setShow] = useState(false)
  const [teacherList, setTeacherList] = useState(null)
  const [filteredTeachers, setFilteredTeachers] = useState([])
  const [selectedLocation, setSelectedLocation] = useState(null)
  const [currentIndex, setCurrentIndex] = useState(null)
  const [map, setMap] = useState(null)
  // const [searchName, setSearchName] = useState('')
  const [hasNext, setHasNext] = useState(false)
  const [hasPrevious, setHasPrevious] = useState(false)
  const [theCurrentTeacher, setCurrentTeacher] = useState(null)
  const [categoryValue, setCategoryValue] = useState('')
  const [categoryName, setCategoryName] = useState('')
  const [searchResult, setSearchResult] = useState('Result: none')
  const [location, setLocation] = useState()
  const [country, setCountry] = useState()
  const [city, setCity] = useState()
  const [state, setState] = useState()
  const [dialog, setDialog] = useState(false)
  const [allSubCategories, setAllSubCategories] = useState([])
  const [openTo, setOpenTo] = useState(false)
  const [openSlot, setOpenSlot] = useState(false)
  const [openDay, setOpenDay] = useState(false)
  const [openType, setOpenType] = useState(false)
  const [openWhere, setOpenWhere] = useState(false)


  const handleClose = (e, type) => {
    if (type === 'to') {
      setOpenTo(false)
    }
    if (type === 'slot') {
      setOpenSlot(false)
    }
    if (type === 'day') {
      setOpenDay(false)
    }
    if (type === 'type') {
      setOpenType(false)
    }
    if (type === 'where') {
      setOpenWhere(false)
    }

  }

  const handleOpen = (e, type) => {
    if (type === 'to') {
      setOpenTo(true)
    }
    if (type === 'slot') {
      setOpenSlot(true)
    }
    if (type === 'day') {
      setOpenDay(true)
    }
    if (type === 'type') {
      setOpenType(true)
    }
    if (type === 'where') {
      setOpenWhere(true)
    }
  }

  useEffect(() => {
    props.actions.getTeacherAction()
    props.actions.getListAction()
    props.actions.getTeachersCardListAction()
    if (localStorage.getItem('country') || localStorage.getItem('state') || localStorage.getItem('city')) {
      props.actions.getCategoryAction('search', localStorage.getItem('country'), localStorage.getItem('state'), localStorage.getItem('city'))
    }
    props.actions.getRegisteredTeachersListAction()
  }, [localStorage.getItem('country'), localStorage.getItem('state'), localStorage.getItem('city')])

  useEffect(() => {
    if (localStorage.getItem('lang') === 'EN' || 'GK') {
      if (localStorage.getItem('country') || localStorage.getItem('state') || localStorage.getItem('city')) {
        props.actions.getCategoryAction('search', localStorage.getItem('country'), localStorage.getItem('state'), localStorage.getItem('city'))
      }
      props.actions.getListAction()
      let {providedTo, providedType, providedWhere, day, slot} = handleFilterChange(filterProvidedTo, filterProvidedType, filterProvidedWhere, filterDay, filterSlot)
      let payload = `subCategory=${localStorage.getItem('subCat') === null ? '' : localStorage.getItem('subCat')}&country=${localStorage.getItem('country') === null ? '' : localStorage.getItem('country')}&state=${localStorage.getItem('state') === null ? '' : localStorage.getItem('state')}&city=${localStorage.getItem('city') === null ? '' : localStorage.getItem('city')}${providedTo}${providedType}${providedWhere}${day.toLowerCase()}${slot.toLowerCase()}`
      props.actions.getSummaryAction(payload)
    }
  }, [localStorage.getItem('lang'), localStorage.getItem('country'), localStorage.getItem('state'), localStorage.getItem('city')])

  useEffect(() => {
    setCategoryList(categoryState?.getCategorySubCategory?.data)
  }, [categoryState?.getCategorySubCategory?.data])

  useEffect(() => {
    setFilterData(globalState?.getList?.data?.payload)
  }, [globalState?.getList?.data])

  useEffect(() => {
    const subCategories = categoryList?.flatMap((category) => category.admin_sub_category)
    setAllSubCategories(subCategories)
  }, [categoryList])

  useEffect(() => {
    if (filteredTeachers?.length > 0 && !hasChangedBounds) {
      setUpdateBounds(true)
      handleMapBounds()
      setValue('1')
      setCategoryName('')
    }
  }, [filteredTeachers])

  useEffect(() => {
    // if (masterState?.getTeacherSummary?.data?.length > 0) return
    let {providedTo, providedType, providedWhere, day, slot} = handleFilterChange(filterProvidedTo, filterProvidedType, filterProvidedWhere, filterDay, filterSlot)
    if (dialog === false) {
      let payload = `subCategory=${localStorage.getItem('subCat') === null ? '' : localStorage.getItem('subCat')}&country=${localStorage.getItem('country') === null ? '' : localStorage.getItem('country')}&state=${localStorage.getItem('state') === null ? '' : localStorage.getItem('state')}&city=${localStorage.getItem('city') === null ? '' : localStorage.getItem('city')}${providedTo}${providedType}${providedWhere}${day.toLowerCase()}${slot.toLowerCase()}`
      props.actions.getSummaryAction(payload)
      setUpdateBounds(true)
      handleMapBounds()
    }
  }, [localStorage.getItem('subCat')])

  useEffect(() => {
    if (location?.types?.[0] === 'country') {
      setCountry(location?.formatted_address)
      setState('')
      setCity('')
      // localStorage.setItem('country', location?.formatted_address)
      // todo -- removeItem
      // localStorage.setItem('state', '')
      // localStorage.setItem('city', '')
    } else if (location?.types?.[0] === 'locality' && location.formatted_address.split(',').length === 2) {
      const locality = location?.address_components.filter(
        (i) => i.types[0] === 'locality'
      )[0].long_name
      setState(locality)
      setCountry('')
      setCity('')
      // localStorage.setItem('state', locality)
      // // todo -- removeItem
      // localStorage.setItem('country', '')
      // localStorage.setItem('city', '')
    } else if (location?.types?.[0] === 'administrative_area_level_1') {
      const state = location?.address_components.filter(
        (i) => i.types[0] === 'administrative_area_level_1'
      )[0].long_name
      setState(state)
      setCountry('')
      setCity('')
      // localStorage.setItem('state', state)
      // // todo -- removeItem
      // localStorage.setItem('country', '')
      // localStorage.setItem('city', '')
    } else if (location?.types?.[0] === 'locality') {
      const city = location?.address_components.filter(
        (i) => i.types[0] === 'locality'
      )[0].long_name
      setCity(city)
      setState('')
      setCountry('')
      // localStorage.setItem('city', city)
      // localStorage.setItem('state', '')
      // localStorage.setItem('country', '')
    } else if (location?.formatted_address?.split(',').length > 3) {
      setMessage(t('locValid'))
      setStatus('error')
    } else if (location !== undefined) {
      if (location?.types?.[0] !== 'country' || location?.types?.[0] !== 'locality' || location?.types?.[0] !== 'administrative_area_level_1') {
        setMessage(t('wrongLocationValidation'))
        setStatus('error')
      }
    }
  }, [location])

  // todo - faraz will help
  useEffect(() => {
    // if (!selectedLocation) return
    const filteredList = filteredTeachers?.filter(
      (item) =>
        +item.latitude === +theCurrentTeacher?.latitude &&
        +item.longitude === +theCurrentTeacher?.longitude
    )
    const nextIndex = (currentIndex + 1) % filteredList.length
    const previousIndex =
      (currentIndex - 1 + filteredList.length) % filteredList.length
    if (
      filteredList[currentIndex]?.longitude ===
        filteredList[nextIndex]?.longitude &&
      filteredList[currentIndex]?.latitude === filteredList[nextIndex]?.latitude && nextIndex > currentIndex
    ) {
      setHasNext(true)
    } else {
      setHasNext(false)
    }
    if (
      filteredList[currentIndex]?.longitude ===
        filteredList[previousIndex]?.longitude &&
      filteredList[currentIndex]?.latitude ===
        filteredList[previousIndex]?.latitude &&
      nextIndex < currentIndex
    ) {
      setHasPrevious(true)
    } else {
      setHasPrevious(false)
    }
    //     const currentIndex = filteredList.findIndex(item => item.teacher_id === theCurrentTeacher.teacher_id);
    // const nextIndex = (currentIndex + 1) % filteredList.length;
    // const previousIndex = (currentIndex - 1 + filteredList.length) % filteredList.length;

  // setHasNext(nextIndex !== currentIndex); // hasNext is true if the list wraps around to a different item
  // setHasPrevious(previousIndex !== currentIndex); // hasPrevious is true if the list wraps around to a different item
  }, [selectedLocation, theCurrentTeacher])

  useEffect(() => {
    if (masterState?.getTeacherSummary?.data?.length === 0) {
      setMessage(t('noTeachers'))
      setStatus('error')
      return
    }
    setTeacherList(masterState?.getTeacherSummary?.data)
    setFilteredTeachers(masterState?.getTeacherSummary?.data)
    handleMapBounds()
  }, [masterState?.getTeacherSummary?.data])

  // useEffect(() => {
  //   const localStorageValues = localStorage.getItem('subCat')
  //   const filterValuesArray = localStorageValues?.split(',')

  //   const newFilterSubcategory = {}
  //   filterValuesArray?.map((value) => {
  //     newFilterSubcategory[Number(value)] = true
  //   })
  //   setfilterSubCategory(newFilterSubcategory)
  // }, [localStorage.getItem('subCat')])

  useEffect(() => {
    const filterValuesArray = localStorage.getItem('subCat')?.split(',')
    const filteredCategories = categoryList?.reduce((acc, curr) => {
      const filteredSubCategories = curr.admin_sub_category?.filter(
        (subCategory) => filterValuesArray?.includes(subCategory.id.toString())
      )
      const subCategoriesObject = filteredSubCategories.reduce((subAcc, subCurr) => {
        subAcc[subCurr.id] = subCurr.sub_category_name
        return subAcc
      }, {})
      return {...acc, ...subCategoriesObject}
    }, {})
    setValueListItem(filteredCategories)
  }, [localStorage.getItem('subCat'), categoryList])

  useEffect(() => {
    if (country || state || city) {
      getTranslatedData()
    }
  }, [country, state, city])

  window.addEventListener('popstate', (event) => {
    if (document.location.href === `${process.env.REACT_APP_REDIRECT_URL}/`) {
      localStorage.removeItem('subCat')
      localStorage.removeItem('country')
      localStorage.removeItem('city')
      localStorage.removeItem('state')
    }
  })

  const getTranslatedData = async() => {
    let res = await axios.post(
      `https://translation.googleapis.com/language/translate/v2?key=${process.env.REACT_APP_TRANSLATION_API_KEY}`,
      // {q: 'Κύπρος', target: 'en'}
      {q: country || city || state, target: 'en'}
    )
    if (country) {
      const t = res.data.data.translations[0]?.translatedText
      setCountry(t)

      localStorage.setItem('country', t)
      localStorage.removeItem('city')
      localStorage.removeItem('state')
    }
    if (city) {
      const t = res.data.data.translations[0]?.translatedText
      setCity(t)
      localStorage.setItem('city', t)
      localStorage.removeItem('country')
      localStorage.removeItem('state')
    }
    if (state) {
      const t = res.data.data.translations[0]?.translatedText
      setState(t)
      localStorage.setItem('state', t)
      localStorage.removeItem('city')
      localStorage.removeItem('country')
    }
  }

  const handleButtonClick = () => {
    setDialog(!dialog)
  }

  const handleMenu = () => {
    // setOpenMenu(!openMenu)
    setDialog(!dialog)
  }

  const hideSlider = () => {
    setSelectedLocation(null)
  }

  const hideSidebar = () => {
    setShow(!show)
  }

  const handleInfoWindowClose = () => {
    setCurrentTeacher(null)
  }

  const handleSelect = () => {
    if (!isMobile) {
      setSelectOpen(!selectOpen)
    }
  }

  const handleChange = (event, newValue) => {
    // if (newValue === '1') {
    //   // window.location.reload()
    //   let {providedTo, providedType, providedWhere, day, slot} = handleFilterChange(filterProvidedTo, filterProvidedType, filterProvidedWhere, filterDay, filterSlot)
    //   const params = `subCategory=${Object.keys(valueListItem).toString()}`
    //   const payload = `${params === 'subCategory=' ? '' : params}${providedTo}${providedType}${providedWhere}${day.toLowerCase()}${slot.toLowerCase()}&country=${country === undefined ? localStorage.getItem('country') : country}&state=${state === undefined ? localStorage.getItem('state') : state}&city=${city === undefined ? localStorage.getItem('city') : city}`
    //   props.actions.getSummaryAction(payload)
    // }
    hideSlider()
    setValue(newValue)
  }


  // clearing distance
  const handleClearDistance = () => {
    setMarker(null)
    setDistanceValue([])
    let payload = `subCategory=${localStorage.getItem('subCat') === null ? '' : localStorage.getItem('subCat')}&country=${localStorage.getItem('country') === null ? '' : localStorage.getItem('country')}&state=${localStorage.getItem('state') === null ? '' : localStorage.getItem('state')}&city=${localStorage.getItem('city') === null ? '' : localStorage.getItem('city')}`
    props.actions.getSummaryAction(payload)
  }

  const handleClear = () => {
    setMarker(null)
    setDistanceValue([])
    setValue('1')
  }

  // clearing filters
  const handleClearFilters = () => {
    setMarker(null)
    setDistanceValue([])
    // setfilterSubCategory({})
    setFilterProvidedTo({})
    setFilterProvidedType({})
    setFilterProvidedWhere({})
    setFilterDay({})
    setFilterSlot({})
    let payload = `subCategory=${localStorage.getItem('subCat') === null ? '' : localStorage.getItem('subCat')}&country=${localStorage.getItem('country') === null ? '' : localStorage.getItem('country')}&state=${localStorage.getItem('state') === null ? '' : localStorage.getItem('state')}&city=${localStorage.getItem('city') === null ? '' : localStorage.getItem('city')}`
    props.actions.getSummaryAction(payload)
  }

  // selecting categories and other filters in desktop and mobile
  const handleCheckBox = (e, id) => {
    switch (e.target.name) {
      case 'subCategory':
        // setfilterSubCategory({...filterSubcategory, [id]: e.target.checked})
        break
      case 'providedTo':
        setFilterProvidedTo({...filterProvidedTo, [e.target.value]: e.target.checked})
        break
      case 'providedType':
        setFilterProvidedType({...filterProvidedType, [e.target.value]: e.target.checked})
        break
      case 'providedWhere':
        setFilterProvidedWhere({...filterProvidedWhere, [e.target.value]: e.target.checked})
        break
      case 'day':
        setFilterDay({...filterDay, [e.target.value]: e.target.checked})
        break
      case 'slot':
        setFilterSlot({...filterSlot, [e.target.value]: e.target.checked})
        break
      default:
        break
    }
  }


  // selecting category in desktop
  const onSelectButton = (name, id) => (event) => {
    event.preventDefault()
    const updatedValueListItem = {...valueListItem}
    if (updatedValueListItem[id] === name) {
      delete updatedValueListItem[id]
    } else {
      updatedValueListItem[id] = name
    }
    setValueListItem(updatedValueListItem)
  }

  // for clicking a particular profile card in map, or listview/sidebar
  const handleClickProfile = (id) => {
    let obj = {
      teacher_id: id,
    }
    props.actions.postProfileClickCountAction(obj)
  }

  //---------------------------------------map------------------------------------------------//
  const {isLoaded} = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY,
    libraries: LIBRARIES,
  })

  const onLoad = useCallback((map) => setMap(map), [])

  const handleMapBounds = () => {
    setHasChangedBounds(false)
    if (updateBounds) {
      if (!map) return
      if (!filteredTeachers || filteredTeachers.length === 0) {
        setHasChangedBounds(false)
        return
      }
      const bounds = new window.google.maps.LatLngBounds()
      filteredTeachers.forEach(({latitude, longitude}) => bounds.extend({lat: +latitude, lng: +longitude}))
      map.fitBounds(bounds)
      setHasChangedBounds(true)
    }
  }

  const handleMapPosition = () => {
    if (!map) return
    if (distanceValue.length > 0) return
    const ne = map.getBounds()?.getNorthEast(),
      sw = map.getBounds()?.getSouthWest()

    setFilteredTeachers(
      teacherList?.filter(
        (item) =>
          parseFloat(item.latitude) >= sw?.lat() &&
          parseFloat(item.latitude) <= ne?.lat() &&
          parseFloat(item.longitude) >= sw?.lng() &&
          parseFloat(item.longitude) <= ne?.lng()
      )
    )
  }

  if (!isLoaded) return

  // for web search/filters
  const handleSubmit = (e, type) => {
    handleClose(e, type)
    setValue('1')
    // let subCategories = Object.keys(filterSubcategory).filter((key) => filterSubcategory[key] === true).toString()
    // subCategories = subCategories && `&subCategory=${subCategories}`

    let {providedTo, providedType, providedWhere, day, slot} = handleFilterChange(filterProvidedTo, filterProvidedType, filterProvidedWhere, filterDay, filterSlot)
    const params = `subCategory=${Object.keys(valueListItem).toString()}`
    const payload = `${params === 'subCategory=' ? '' : params}${providedTo}${providedType}${providedWhere}${day.toLowerCase()}${slot.toLowerCase()}&country=${country === undefined ? localStorage.getItem('country') : country}&state=${state === undefined ? localStorage.getItem('state') : state}&city=${city === undefined ? localStorage.getItem('city') : city}`
    props.actions.getSummaryAction(payload)
    const subCatkeys = Object.keys(valueListItem).join(',')
    localStorage.setItem('subCat', subCatkeys)
    const localStorageCateg = localStorage.getItem('subCat')
    if (isMobile) {
      // if (subCategories) {
      //   localStorage.setItem('subCat', subCategories.replace('&subCategory=', ''))
      // } else {
      //   localStorage.setItem('subCat', localStorageCateg)
      // }
      // if (subCategories === '') {
      //   localStorage.removeItem('subCat')
      // }
    } else {
      if (subCatkeys) {
        localStorage.setItem('subCat', subCatkeys)
      } else {
        localStorage.setItem('subCat', localStorageCateg)
      }
    }
    setOpenMenu(false)
  }

  // for web search/filters
  const handleWebSearch = (e, type) => {
    let providedTo = Object.keys(filterProvidedTo).filter((key) => filterProvidedTo[key] === true).toString()
    let providedType = Object.keys(filterProvidedType).filter((key) => filterProvidedType[key] === true).toString()
    let providedWhere = Object.keys(filterProvidedWhere).filter((key) => filterProvidedWhere[key] === true).toString()
    let day = Object.keys(filterDay).filter((key) => filterDay[key] === true)
    let slot = Object.keys(filterSlot).filter((key) => filterSlot[key] === true)

    providedTo = providedTo && `&ages=${providedTo}`
    providedType = providedType && `&placeWhere=${providedType}`
    providedWhere = providedWhere && `&memberType=${providedWhere}`
    day = day && `&day=${day}`
    slot = slot && `&lot=${slot}`


    let payload = `subCategory=${categoryValue}&country=${country === undefined ? '' : country}&state=${state === undefined ? '' : state}&city=${city === undefined ? '' : city}${providedTo}${providedType}${providedWhere}${day.toLowerCase()}${slot.toLowerCase()}`

    if (type === 'webSearch') {
      payload = `subCategory=${localStorage.getItem('subCat')}&country=${country === undefined ? localStorage.getItem('country') : country}&state=${state === undefined ? localStorage.getItem('state') : state}&city=${city === undefined ? localStorage.getItem('city') : city}${providedTo}${providedType}${providedWhere}${day.toLowerCase()}${slot.toLowerCase()}`
      props.actions.getSummaryAction(payload)
    } else {
      if ((categoryValue) && (country || state || city)) {
        props.actions.getSummaryAction(payload)
      } else {
        setMessage(t('bothFields'))
        setStatus('error')
      }
    }
    setDialog(false)
  }

  // setting distance filters on mobile and desktop
  const handleDistanceCheck = (e, value) => {
    setSelectOpen(true)
    if (isMobile) {
      setSelectOpen(false)
    }
    setDistanceValue([value])
  }


  // for web search/filters distance based on distance from selected point (marker)
  const filterPlacesByDistance = () => {
    if (isMobile) {
      setValue('1')
    }
    if (marker === null) {
      setMessage(t('markValidation'))
      setStatus('error')
    } else {
      const circle = new window.google.maps.Circle({
        center: marker || center,
        radius: distanceValue[0],
      })
      const filteredPlaces = teacherList?.filter((place) => {
        return circle.getBounds()?.contains({lat: parseFloat(place.latitude), lng: parseFloat(place.longitude)})
      })
      setFilteredTeachers(filteredPlaces)
    }
    if (!isMobile) {
      setSelectOpen(!selectOpen)
    }
    if (!isLoaded) return
  }


  // setting marker clip manually
  const handleMarkerPosition = (e) => {
    setMarker({lat: e.latLng.lat(), lng: e.latLng.lng()})
  }


  // marker click
  const handleActiveMarker = (teacherId) => (e) => {
    // ? removed as it seems to serve no purpose rn...
    // if (teacherId === selectedLocation) {
    //   return setSelectedLocation(null) //! what's the point?
    // }
    //! 1) get the teacher data from the entire list of teachers after coategory filters
    const currentTeacher = filteredTeachers.find((item) => +item.teacher_id === +teacherId)
    //! 2) get the teachers which have the same lat/lng as the selected teacher(currentTeacher)
    const filteredList = filteredTeachers?.filter(
      (item) =>
        +item.latitude === +currentTeacher?.latitude &&
        +item.longitude === +currentTeacher?.longitude
    )

    //! 3) now get the index of the current teacher from teh list of teachers with teh same lat/lng
    const index = filteredList?.findIndex(
      (m) => +m.latitude === +currentTeacher.latitude &&
      +m.longitude === +currentTeacher.longitude && +m.teacher_id === +teacherId
    )
    //! 4) because of conflicting issues on mobile, we now get the index of the teachers item from the entire teachers list
    const currentTeacherIndex = filteredTeachers?.findIndex((item) => +item.teacher_id === +teacherId)

    //! 5) update the list once we do find the teacher in the list -> reason being conflicting behaviour that is causing issues for card in mobile map
    const myfilteredTeachers = [...filteredTeachers]
    if (myfilteredTeachers.length > 0) {
      if (myfilteredTeachers[currentTeacherIndex]) {
        const currentElement = myfilteredTeachers.splice(currentTeacherIndex, 1)[0]
        myfilteredTeachers.unshift(currentElement)
        setFilteredTeachers(myfilteredTeachers)
      }
    }
    setCurrentIndex(index)
    setCurrentTeacher(currentTeacher)
  }

  // todo - try to optimise with faraz
  // next button and previous button in cards
  const handleNextClick = () => {
    const filteredList = filteredTeachers?.filter(
      (item) =>
        +item.latitude === +theCurrentTeacher?.latitude &&
            +item.longitude === +theCurrentTeacher?.longitude
    )
    const nextIndex = (currentIndex + 1) % filteredList.length !== -1 ? (currentIndex + 1) % filteredList.length : (currentIndex - 1) % filteredList.length
    setCurrentIndex(nextIndex)

    setSelectedLocation(filteredList[nextIndex].teacher_id)
    setCurrentTeacher(filteredList[nextIndex])
  }
  const handlePreviousClick = () => {
    const filteredList = filteredTeachers?.filter(
      (item) =>
        +item.latitude === +theCurrentTeacher?.latitude &&
            +item.longitude === +theCurrentTeacher?.longitude
    )
    const previousIndex =
      (currentIndex - 1 + filteredList.length) % filteredList.length
    setCurrentIndex(previousIndex)
    setSelectedLocation(filteredList[previousIndex].teacher_id)
    setCurrentTeacher(filteredList[previousIndex])
  }


  // // name and surname search in mobile and desktop
  // const handleSearch = (e) => {
  //   const searchInput = e.target.value.toLowerCase()
  //   setSearchName(searchInput)
  //   const filtered = teacherList.filter((item) => {
  //     const fullName = `${item.name} ${item.surname}`.toLowerCase()
  //     return fullName.includes(searchInput)
  //   })
  //   setFilteredTeachers(filtered)
  //   return filtered
  // }

  // autocomplete
  const onPlaceChanged = () => {
    if (searchResult != null) {
      const place = searchResult.getPlace()
      setLocation(place)
    }
  }
  const onPlaceLoad = (autocomplete) => {
    setSearchResult(autocomplete)
  }

  const handleSnackBarClose = () => {
    setMessage('')
    setStatus('')
    props.actions.cleanUpUserState()
  }

  return (
    <MyDiv>
      {(masterState?.getTeacherSummary?.loading ||
        masterState?.getTeacherData?.loading ||
        masterState?.getCategorySubCategory?.loading) && (
        <div>
          <Loader />
        </div>
      )}
      <Suspense fallback={<Loader />}>
        <AuthTopPanelMain
          setCategoryName={setCategoryName}
          setDistanceValue={setDistanceValue}
          setFilterProvidedTo={setFilterProvidedTo}
          setFilterProvidedType={setFilterProvidedType}
          setFilterProvidedWhere={setFilterProvidedWhere}
          setFilterDay={setFilterDay}
          setFilterSlot={setFilterSlot}
          handleButtonClick={handleButtonClick}
          // setSearchName={setSearchName}
          title5={t('Login')}
          title6={t('Register')}
          handleMenu={handleMenu}
          handleWebSearch={handleWebSearch}
          searchResult={searchResult}
          setSearchResult={setSearchResult}
          setLocation={setLocation}
          setCategoryValue={setCategoryValue}
          handleClearFilters={handleClearFilters}
          categoryList={categoryList}
          openMenu={openMenu}
          handleSubmit={handleSubmit}
          valueListItem={valueListItem}
          onSelectButton={onSelectButton}
        />
        {!isMobile ? <MapFilter
          openTo={openTo}
          openSlot={openSlot}
          openDay={openDay}
          openType={openType}
          openWhere={openWhere}
          handleClose={handleClose}
          handleOpen={handleOpen}
          handleClearFilters={handleClearFilters}
          handleClearDistance={handleClearDistance}
          handleInfoWindowClose={handleInfoWindowClose}
          openMenu={openMenu}
          valueListItem={valueListItem}
          onSelectButton={onSelectButton}
          handleMenu={handleMenu}
          currentIndex={currentIndex}
          handleNextClick={handleNextClick}
          hasNext={hasNext}
          hasPrevious={hasPrevious}
          theCurrentTeacher={theCurrentTeacher}
          handlePreviousClick={handlePreviousClick}
          filterPlacesByDistance={filterPlacesByDistance}
          setSelectedLocation={setSelectedLocation}
          selectedLocation={selectedLocation}
          handleActiveMarker={handleActiveMarker}
          teacherList={teacherList}
          containerStyle={containerStyle}
          show={show}
          hideSidebar={hideSidebar}
          center={center}
          onLoad={onLoad}
          handleMapPosition={handleMapPosition}
          handleMarkerPosition={handleMarkerPosition}
          marker={marker}
          // searchName={searchName}
          filteredTeachers={filteredTeachers}
          // handleSearch={handleSearch}
          handleClickProfile={handleClickProfile}
          handleCheckBox={handleCheckBox}
          handleSubmit={handleSubmit}
          masterState={masterState}
          filterData={filterData}
          categoryList={categoryList}
          // filterSubcategory={filterSubcategory}
          filterProvidedTo={filterProvidedTo}
          filterProvidedType={filterProvidedType}
          filterProvidedWhere={filterProvidedWhere}
          filterDay={filterDay}
          filterSlot={filterSlot}
          handleDistanceCheck={handleDistanceCheck}
          distanceValue={distanceValue}
          selectOpen={selectOpen}
          handleSelect={handleSelect}
        /> : <TabContext value={value}>
          <TabList
            className="tab_container"
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            TabIndicatorProps={{
              style: {
                display: 'none',
                backgroundColor: '#FFFFFF',
              },
            }}
          >
            {value === '1' ? (
              <Tab
                className="tab_names"
                label={t('ListView')}
                value="2"
              />
            ) : (
              <Tab className="tab_names" label={t('MapView')} value="1" />
            )}
            {/* <Tab
                    className="tab_names"
                    label={t('Categories')}
                    value="3"
                  /> */}
            {value === '1' && <Tab className="tab_names" label={t('Distance')} value="5" />}
            <Tab className="tab_names" label={t('Filters')} value="4" />
          </TabList>
          <TabPanel value="2">
            <ListviewTab filteredTeachers={filteredTeachers} />
          </TabPanel>
          <TabPanel value="1" className="no-space-mobile">
            {/* <MapViewTab
              zoom={zoom}
              hideSlider={hideSlider}
              marker={marker}
              handleMarkerPosition={handleMarkerPosition}
              filteredTeachers={filteredTeachers}
              center={center}
              onLoad={onLoad}
              handleActiveMarker={handleActiveMarker}
              handleMapPosition={handleMapPosition}
              containerStyle={containerStyle}
              filterData={filterData}
              teacherList={teacherList}
              selectedLocation={selectedLocation}
              setSelectedLocation={setSelectedLocation}
              distanceValue={distanceValue}
              selectOpen={selectOpen}
              handleSelect={handleSelect}
              theCurrentTeacher={theCurrentTeacher}
              hasNext={hasNext}
              hasPrevious={hasPrevious}
              handlePreviousClick={handlePreviousClick}
              handleNextClick={handleNextClick}
              setCurrentTeacher={setCurrentTeacher}
            /> */}
          </TabPanel>
          {/* <TabPanel value="3">
                  <CategoriesTab
                    handleCheckBox={handleCheckBox}
                    filterSubcategory={filterSubcategory}
                    categoryList={categoryList}
                  />
                </TabPanel> */}
          <TabPanel value="4">
            <FiltersTab
              handleCheckBox={handleCheckBox}
              filterProvidedType={filterProvidedType}
              filterProvidedTo={filterProvidedTo}
              filterProvidedWhere={filterProvidedWhere}
              filterData={filterData}
              filterSlot={filterSlot}
              filterDay={filterDay}
            />
          </TabPanel>
          <TabPanel value="5">
            <DistanceTab
              handleCheckBox={handleCheckBox}
              filterData={filterData}
              filterPlacesByDistance={filterPlacesByDistance}
              handleDistanceCheck={handleDistanceCheck}
              handleClear={handleClear}
              distanceValue={distanceValue}
            />
          </TabPanel>
        </TabContext>}
        {!(value === '1' || value === '2' || value === '5') && (
          <Box className="filter_actions">
            <CustomButton
              className="btn_submit"
              onClick={handleSubmit}
              title="Submit"
            />
          </Box>
        )}
        {/* this is a hot fix for the map zoom issue on mobile devices. the map works withou any issue,
         when used with the current implementation but fails to zoom when using it with tabs implementation */}
        {isMobile &&
        <Box className={value === '1' ? null : 'hide_map'}>
          <MapViewTab
            zoom={zoom}
            hideSlider={hideSlider}
            marker={marker}
            handleMarkerPosition={handleMarkerPosition}
            filteredTeachers={filteredTeachers}
            center={center}
            onLoad={onLoad}
            handleActiveMarker={handleActiveMarker}
            handleMapPosition={handleMapPosition}
            containerStyle={containerStyle}
            filterData={filterData}
            teacherList={teacherList}
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
            distanceValue={distanceValue}
            selectOpen={selectOpen}
            handleSelect={handleSelect}
            theCurrentTeacher={theCurrentTeacher}
            hasNext={hasNext}
            hasPrevious={hasPrevious}
            handlePreviousClick={handlePreviousClick}
            handleNextClick={handleNextClick}
            setCurrentTeacher={setCurrentTeacher}
          />
        </Box>
        }
      </Suspense>
      <SnackBar
        message={message}
        severity={status}
        open={message?.length > 0}
        close={handleSnackBarClose}
      />
      {/* //dialog for mobile web re-search */}
      <Dialog
        fullScreen={!!isMobile}
        open={dialog}
        TransitionComponent={Transition}
        onClose={handleButtonClick}
      >
        <Box mt={isMobile ? 7 : 0}>
          <SearchDialog
            setDialog={setDialog}
            dialog={dialog}
            allSubCategories={allSubCategories}
            setAllSubCategories={setAllSubCategories}
            setCategoryName={setCategoryName}
            setCategoryValue={setCategoryValue}
            categoryName={categoryName}
            onPlaceChanged={onPlaceChanged}
            onPlaceLoad={onPlaceLoad}
            handleWebSearch={handleWebSearch}
          />
        </Box>
        {isMobile && <IconButton onClick={handleButtonClick}><KeyboardArrowUp /></IconButton>}
      </Dialog>
    </MyDiv>
  )
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
})
export default connect(null, mapDispatchToProps)(HomePageFilter)
