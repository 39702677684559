import styled from 'styled-components'

const MyDiv = styled.div`
.grid_dialog{
    height: 300px!important;
}
.reactEasyCrop_Container{
    height: 300px!important;
}
.grid_dialog{
    position: relative
}
.upload_btn{
    text-align: center;
    position: absolute;
    right: 10px;
    top: 10px;
}

`
export default MyDiv
