/* eslint-disable no-use-before-define */
import {KeyboardReturn} from '@mui/icons-material'
import {Box, IconButton, Typography} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {connect, useSelector} from 'react-redux'
import {bindActionCreators} from 'redux'
import {CustomAddNew, CustomTable, Loader, SnackBar} from '../../../../components'
import CustomDialog from '../../../../components/CustomDialog/customDialog'
import {ActionCreators} from '../../../../redux/actions'
import PackageForm from '../PackageForm'
import PackagesEdit from '../PackagesEdit'
import MyDiv from './packages.style'

const tableHeading = [
  {
    id: 1,
    title: 'Name',

  },
  {
    id: 2,
    title: 'Amount',

  },
  {
    id: 3,
    title: 'Date Modified',

  },
  {
    id: 4,
    title: 'Edit',

  },
  // {
  //   id: 5,
  //   title: 'Delete',

  // },
]

const Packages = (props) => {
  const [promoFlag, setPromoFlag] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [deleteId, setDeleteId] = useState()
  const [show, setShow] = useState(true)
  const [sidebar, setSidebar] = useState(false)
  const [packageData, setPackageData] = useState({})
  const [packagesList, setPackagesList] = useState([])
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('')
  const globalState = useSelector((state) => state.globalState)
  const tableData = globalState.getList.data?.payload?.packages
  const [errorData, setErrorData] = useState()
  const [actionType, setActionType] = useState('')


  useEffect(() => {
    props.actions.getListAction()
  }, [])

  useEffect(() => {
    setPackagesList(tableData)
  }, [tableData])

  useEffect(() => {
    setErrorData(globalState?.postPackage?.error?.errors)
  }, [globalState?.postPackage?.error?.errors])

  useEffect(() => {
    if (globalState?.postPackage?.data?.status === 'success') {
      setMessage(globalState?.postPackage?.data?.message)
      setStatus('success')
      setSidebar(false)
    } else if (globalState?.postPackage?.error?.meta?.status === 'failure') {
      setMessage(globalState?.postPackage?.error?.meta?.message)
      setStatus('error')
    }
  }, [globalState?.postPackage])

  useEffect(() => {
    if (globalState?.deletePackage?.data?.status === 'success') {
      setMessage(globalState?.deletePackage?.data?.message)
      setStatus('success')
    }
  }, [globalState?.deletePackage])

  const handleClick = (type) => {
    setActionType(type)
    setSidebar(!sidebar)
    setErrorData()
    setPackageData({})
  }

  const regex1 = new RegExp('^[a-zA-Z ]+$')
  const regex2 = new RegExp('^[0-9 ]+$')
  const isString = regex1.test(packageData.name)
  const isAmount = regex2.test(packageData.amount)

  const handleChange = (e) => {
    setPackageData({...packageData, [e.target.name]: e.target.value})
  }

  const handleSubmit = () => {
    if (actionType === 'edit' && (isString && isAmount) && packageData.amount < 999999) {
      props.actions.postPackageAction(packageData)
      setPackageData({})
    } else if (actionType === 'add' && (isString && isAmount) && packageData.amount < 999999) {
      props.actions.addPackageAction(packageData)
      setPackageData({})
    } else if (!isString || !isAmount) {
      setMessage('Package name must be in characters [a-z] and amount must be in numbers [0-9].')
      setStatus('error')
    } else if (+packageData.amount?.length > 6) {
      setMessage('Amount out of range.')
      setStatus('error')
    }
  }


  // handle delete icon
  const handleDelete = (item) => {
    setOpenDialog(!openDialog)
    setDeleteId(item)
  }

  const handleConfirm = () => {
    setOpenDialog(!openDialog)
    let obj = {
      package_id: deleteId,
    }
    props.actions.deletePackageAction(obj)
  }

  const handleEditPackage = (e, item) => {
    setActionType('edit')
    setSidebar(!sidebar)
    const currentCategory = packagesList.filter((data) => data.package_id === item)
    setPackageData({
      package_id: item,
      name: currentCategory[0]?.name,
      amount: currentCategory[0]?.amount,
      promotion_days: currentCategory[0]?.promotion_days,
      promotion_price: currentCategory[0]?.promotion_price,
      is_promotional: currentCategory[0]?.is_promotional,
    })
    if (currentCategory[0]?.is_promotional === 1) {
      setPromoFlag(true)
    }
  }

  const handleSnackBarClose = () => {
    setMessage('')
    setStatus('')
    props.actions.cleanUpUserState()
  }


  const handleCheckBox = (e, id) => {
    if (e.target.checked === false) {
      setPromoFlag(false)
      setPackageData({...packageData,
        promotion_days: '',
        promotion_price: '',
        is_promotional: 0,
      })
    } else if (e.target.checked === true) {
      setPromoFlag(true)
      const currentCategory = packagesList.filter((data) => data.package_id === id)
      setPackageData({
        package_id: id,
        name: currentCategory[0]?.name,
        amount: currentCategory[0]?.amount,
        promotion_days: currentCategory[0]?.promotion_days,
        promotion_price: currentCategory[0]?.promotion_price,
        is_promotional: 1,
      })
    }
  }

  return (
    <MyDiv>
      {(globalState?.postPackage?.loading || globalState?.getList?.loading) &&
        <div>
          <Loader />
        </div>
      }
      <Box className="main_box">
        <Box className="header_top">
          <Typography className="page_title">Packages</Typography>
          {/* <CustomButton startIcon={<Add />} title="Add New" onClick={() => handleClick('add')} className="add_btn" /> */}
        </Box>
        {!show ? <Box><IconButton className="btn_return" onClick={() => setShow(!show)}><KeyboardReturn /></IconButton><PackagesEdit /></Box> : null}
        {show ? <CustomTable
          tableHeading={tableHeading}
          type="packages"
          tableData={tableData}
          handleDelete={handleDelete}
          handleEditPackage={handleEditPackage}
        /> : null}
        <CustomAddNew sidebar={sidebar} sidebarTitle="Add Packages" handleClick={handleClick}>
          <PackageForm
            errorData={errorData}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            handleClick={handleClick}
            packageData={packageData}
            handleCheckBox={handleCheckBox}
            promoFlag={promoFlag}
          />
        </CustomAddNew>
      </Box>
      <SnackBar
        message={message}
        severity={status}
        open={message?.length > 0}
        close={handleSnackBarClose}
        type="admin"
        msg={message}
      />
      <CustomDialog
        title="Do you want to delete the Package?"
        handleConfirm={handleConfirm}
        openDialog={openDialog}
        handleDelete={handleDelete}
        type="admin"
      />
    </MyDiv>
  )
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
})

export default connect(null, mapDispatchToProps)(Packages)
