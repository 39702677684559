import {AlternateEmail, Assignment, Call, CreditCard, Email, LocationOn, NearMe, Public, SportsBasketball} from '@mui/icons-material'
import {Box, Grid, IconButton, ImageList, ImageListItem, Typography} from '@mui/material'
import {GoogleMap, MarkerF, useJsApiLoader} from '@react-google-maps/api'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {connect, useSelector} from 'react-redux'
import {bindActionCreators} from 'redux'
import {Loader} from '../../../../components'
import {ActionCreators} from '../../../../redux/actions'
import MyDiv from './registrationData.style'

const containerStyle = {
  width: '100%',
  height: '500px',
}
const LIBRARIES = ['places']


const RegistrationData = (props) => {
  const textHandle = (text) => {
    const regex = /(<([^>]+)>)/ig
    return text?.replace(regex, '')
  }

  const [marker, setMarker] = useState()
  const [trailStatus, setTrailStatus] = useState()
  const [paidStatus, setPaidStatus] = useState()
  const masterState = useSelector((state) => state.masterState)

  useEffect(() => {
    setTrailStatus(masterState?.postAccountStatus?.data?.data?.payload)
    setPaidStatus(masterState?.getIsPublish?.data?.is_paid)
  }, [masterState])


  useEffect(() => {
    setMarker({lat: Number(props.customerData?.userLocation[0]?.lat), lng: Number(props.customerData?.userLocation[0]?.long)})
  }, [props.customerData?.userLocation])

  const {isLoaded} = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY,
    libraries: LIBRARIES,
  })

  if (!isLoaded) return

  return (
    <MyDiv>
      {(masterState?.getDetailsData?.loading) ?
        <div>
          <Loader />
        </div> : <Box>
          <Typography className="userName">
            {props.customerData?.userDetails?.[0].name}{' '}{props.customerData?.userDetails?.[0].surname}
          </Typography>
          {trailStatus?.is_trial === 1 ? <Typography className="pending_label">Trail Account</Typography> : null}
          {trailStatus?.is_trial === 2 ? <Typography className="pending_label">Trail Expired</Typography> : null}
          {paidStatus ? <Typography className="pending_label">Subscribed</Typography> : <Typography className="pending_label">Unpaid Account</Typography>}
          <Box className="user-info">
            <Typography className="user_location">
              {props.customerData?.userLocation?.[0]?.address}{' '}{props.customerData?.userLocation?.[0]?.city}
              {' '}{props.customerData?.userLocation?.[0]?.state}{' '}{props.customerData?.userLocation?.[0]?.country}
            </Typography>
          </Box>
          <Box className="wrap-flex">
            <Typography className="labelName">
              Phone Number:
            </Typography>
            <Typography className="labelData">{props.customerData?.userDetails?.[0]?.phone_number}</Typography>
          </Box>
          <Box className="wrap-flex">
            <Typography className="labelName">
              Category:
            </Typography>
            <Typography className="labelData">{props.customerData?.userLesson?.[0]?.category_name}</Typography>
          </Box>
          <Box className="about_box">
            <Typography className="card_heading">Main Information</Typography>
            <Grid container className="grid_space">
              <Grid item md={4}>
                <Box className="wrap-flex box-space">
                  <Box className="left_space">
                    <img src={props.customerData?.userDetails?.[0].profile_image} className="profile_img" />
                  </Box>
                  <Box className="right_space">
                    <Typography className="labelData">
                      About {props.customerData?.userDetails?.[0].name}
                    </Typography>
                    <Box className="inner-wrap-flex">
                      <Typography className="labelName">
                        Experience:
                      </Typography>
                      <Typography className="labelData">{props.customerData?.userDetails?.[0].experience_name}</Typography>
                    </Box>
                  </Box>
                </Box>
                <Box className="wrap-flex box-space">
                  <Box className="left_space info-space">
                    <IconButton className="info-icons">
                      <LocationOn />
                    </IconButton>
                  </Box>
                  <Box className="right_space">
                    <Typography className="labelData">
                      {props.customerData?.userLocation?.[0]?.address}{' '}{props.customerData?.userLocation?.[0]?.city}
                      {' '}{props.customerData?.userLocation?.[0]?.state}{' '}{props.customerData?.userLocation?.[0]?.country}
                    </Typography>
                  </Box>
                </Box>
                <Box className="wrap-flex box-space">
                  <Box className="left_space info-space">
                    <IconButton className="info-icons">
                      <Call />
                    </IconButton>
                  </Box>
                  <Box className="right_space">
                    <Typography className="labelData">
                      {props.customerData?.userDetails?.[0]?.phone_number}
                    </Typography>
                  </Box>
                </Box>
                <Box className="wrap-flex box-space">
                  <Box className="left_space info-space">
                    <IconButton className="info-icons">
                      <Email />
                    </IconButton>
                  </Box>
                  <Box className="right_space">
                    <Typography className="labelData">
                      {props.customerData?.userDetails?.[0]?.email}
                    </Typography>
                  </Box>
                </Box>
                <Box className="wrap-flex box-space">
                  <Box className="left_space info-space">
                    <IconButton className="info-icons">
                      <Public />
                    </IconButton>
                  </Box>
                  <Box className="right_space">
                    <Typography className="labelData">
                      {props.customerData?.userDetails?.[0]?.website}
                    </Typography>
                  </Box>
                </Box>
                <Box className="wrap-flex box-space">
                  <Box className="left_space info-space">
                    <IconButton className="info-icons">
                      <NearMe />
                    </IconButton>
                  </Box>
                  <Box mt={2} className="right_space inner-wrap-flex">
                    {props.customerData?.userSocialMedia?.map((item, index) => {
                      return (
                        <Box key={index}>
                          <img className="sm_icn" src={item?.social_media_icon} />
                        </Box>
                      )
                    })}
                  </Box>
                </Box>
              </Grid>
              <Grid item md={8}>
                <Box mt={3}>
                  <Typography className="labelName label_space">
                    About Me
                  </Typography>
                  <Typography className="labelData">{
                    textHandle(props.customerData?.userAboutMe?.[0]?.about_me)
                  }
                  </Typography>
                </Box>
                <Box mt={3}>
                  <Typography className="labelName label_space">
                    Languages
                  </Typography>
                  {props.customerData?.userLanguage?.map((item, index) => {
                    return (
                      <Box className="inner-wrap-flex lang-space" key={index}>
                        <Typography className="labelNameBold">{item.language_name}:</Typography>
                        <Typography className="labelData">{item.proficiency_name}</Typography>
                      </Box>
                    )
                  })}
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box className="about_box">
            <Typography className="card_heading">Location</Typography>
            <GoogleMap
              center={marker}
              mapContainerStyle={containerStyle}
              zoom={5}
            >
              {props.customerData?.userLocation?.map((item, index) => {
                return (
                  <MarkerF key={index} position={{lat: Number(item?.lat), lng: Number(item?.long)}} />
                )
              })}
            </GoogleMap>
          </Box>
          <Box className="about_box">
            <Grid container className="grid_space" columnSpacing={{sm: 4, md: 4}}>
              <Grid item md={4}>
                <Typography className="card_heading">Lesson</Typography>
                <Box mt={3}>
                  <Box className="category_box box-space">
                    <Typography className="categoryName">
                      Category:
                    </Typography>
                    <Typography className="categoryData">
                      {props?.customerData?.userLesson?.[0]?.category_name}
                    </Typography>
                  </Box>
                  <Box className="wrap-flex box-space">
                    <Box className="left_space info-space">
                      <IconButton className="info-icons">
                        <Assignment />
                      </IconButton>
                    </Box>
                    <Box className="right_space">
                      <Typography className="labelNameBold">
                        Provided for
                      </Typography>
                      {props?.customerData?.userLesson?.[0]?.lesson_is_provided_list?.map((item, index) => {
                        return (
                          <Typography className="labelData" key={index}>
                            {item.member_type}
                          </Typography>
                        )
                      })}
                    </Box>
                  </Box>
                  <Box className="wrap-flex box-space">
                    <Box className="left_space info-space">
                      <IconButton className="info-icons">
                        <AlternateEmail />
                      </IconButton>
                    </Box>
                    <Box className="right_space">
                      <Typography className="labelNameBold">
                        Provided to
                      </Typography>
                      {props?.customerData?.userLesson?.[0]?.provided_to_list?.map((item, index) => {
                        return (
                          <Typography className="labelData" key={index}>
                            {item.provided_to_name}
                          </Typography>
                        )
                      })}
                    </Box>
                  </Box>
                  <Box className="wrap-flex box-space">
                    <Box className="left_space info-space">
                      <IconButton className="info-icons">
                        <SportsBasketball />
                      </IconButton>
                    </Box>
                    <Box className="right_space">
                      <Typography className="labelNameBold">
                        Is provided
                      </Typography>
                      {props?.customerData?.userLesson?.[0]?.lesson_provided_to_place_list?.map((item, index) => {
                        return (
                          <Typography className="labelData" key={index}>
                            {item.provided_via_name}
                          </Typography>
                        )
                      })}
                    </Box>
                  </Box>
                  <Box className="wrap-flex box-space">
                    <Box className="left_space info-space">
                      <IconButton className="info-icons">
                        <CreditCard />
                      </IconButton>
                    </Box>
                    <Box className="right_space">
                      <Typography className="labelNameBold">
                        Cost
                      </Typography>
                      <Typography className="labelData">
                        {'€'}{props?.customerData?.userLesson?.[0]?.cost_per}{' / '}{props?.customerData?.userLesson?.[0]?.cost_per_name}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item md={8}>
                <Typography className="card_heading">About the lesson</Typography>
                <Box mt={3} className="lesson_about">
                  <Typography className="labelData">
                    {textHandle(props?.customerData?.userLesson?.[0]?.description)}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box className="about_box">
            <Typography className="card_heading">Education</Typography>
            <Box className="education_box">
              {props?.customerData?.userEducation?.map((item, index) => {
                return (
                  <Box className="education_data" key={index}>
                    <Box className="wrap-flex">
                      <Typography className="labelNameBold">
                        {item.institute_name}
                      </Typography>
                      <Typography className="labelData">
                        {item.country_name}{', '}{item.city_name}
                      </Typography>
                    </Box>
                    <Box mt={2} className="desc_space">
                      <Typography className="labelData">
                        {item.degree_title}
                      </Typography>
                      <Typography className="labelData">
                        {item.start_date ? moment(item?.start_date).format('YYYY') : ''}{' - '}{item.end_date ? moment(item?.end_date).format('YYYY') : ''}
                      </Typography>
                    </Box>
                    <Box mt={2}>
                      <Typography className="labelNameBold underline_text">Description</Typography>
                      <Typography className="labelData desc_space">
                        {textHandle(item.description)}
                      </Typography>
                    </Box>
                  </Box>
                )
              })}
            </Box>
          </Box>
          <Box className="about_box">
            <Typography className="card_heading">Experience</Typography>
            <Box className="education_box">
              {props?.customerData?.userExperience?.map((item, index) => {
                return (
                  <Box className="education_data" key={index}>
                    <Box className="wrap-flex">
                      <Typography className="labelNameBold">
                        {item.experience_name}
                      </Typography>
                    </Box>
                    <Box mt={2} className="desc_space">
                      <Typography className="labelData">
                        {item.start_date ? moment(item?.start_date).format('YYYY') : ''}{' - '}{item.end_date ? moment(item?.end_date).format('YYYY') : ''}
                      </Typography>
                    </Box>
                    <Box mt={2}>
                      <Typography className="labelNameBold underline_text">Description</Typography>
                      <Typography className="labelData desc_space">
                        {textHandle(item.description)}
                      </Typography>
                    </Box>
                  </Box>
                )
              })}
            </Box>
          </Box>
          <Box className="about_box">
            <Typography className="card_heading">Image Gallery</Typography>
            <Box className="image_wrapper">
              <ImageList variant="masonry" cols={5} gap={8} className="image_list">
                {props?.customerData?.gallery?.images?.map((item, index) => {
                  return (
                    <ImageListItem key={index}>
                      <img
                        src={item?.media_url}
                        srcSet={item?.media_url}
                        alt={item?.media_type}
                        loading="lazy"
                        className="existing_img"
                      />
                    </ImageListItem>
                  )
                })}
              </ImageList>
            </Box>
          </Box>
          <Box className="about_box">
            <Typography className="card_heading">Video Gallery</Typography>
            <Box className="image_wrapper">
              <Grid container className="image_list" columnSpacing={{sm: 4, md: 4}}>
                {props?.customerData?.gallery?.video?.map((item, index) => {
                  return (
                    <Grid item md={4} key={index} className="video_space">
                      <iframe
                        className="existing_video"
                        src={item?.media_url.replace('watch?v=', 'embed/')} height="150px" width="250px"
                      />
                    </Grid>
                  )
                })}
              </Grid>
            </Box>
          </Box>
        </Box>}
    </MyDiv>
  )
}


const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
})

export default connect(null, mapDispatchToProps)(RegistrationData)
