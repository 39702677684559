import {Box, Typography, Grid} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {connect, useSelector} from 'react-redux'
import {bindActionCreators} from 'redux'
import {ActionCreators} from '../../../../redux/actions'
import InfoCard from '../InfoCard'
import MyDiv from './dashBoard.style'
const DashBoard = (props) => {
  const [salesData, setSalesData] = useState()
  const globalState = useSelector((state) => state.globalState)

  useEffect(() => {
    props.actions.getStaticAdminDataAction()
  }, [])
  useEffect(() => {
    props.actions.getUsersAction()
  }, [])

  useEffect(() => {
    setSalesData(globalState?.getStaticAdminData?.data?.data?.payload)
  }, [globalState?.getStaticAdminData])

  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0')

  const currentMonthDate = `${currentYear}-${currentMonth}`
  const currentMonthObject = salesData?.monthly_sales?.find((item) => item.month_date === currentMonthDate)

  return (
    <MyDiv>
      <Box className="main_box">
        <Typography mb={2} className="page_title">Welcome Marios</Typography>
        <Box mb={6}>
          <Typography className="text_typo2">Current month</Typography>
          <Grid container rowSpacing={4} columnSpacing={{sm: 4, md: 4}}>
            <Grid item md={4}>
              <InfoCard currentMonthObject={currentMonthObject} />
            </Grid>
          </Grid>
        </Box>
        <Box mb={6}>
          <Typography mt={1} className="text_typo2">Approvals</Typography>
          <Grid container rowSpacing={4} columnSpacing={{sm: 4, md: 4}}>
            <Grid item md={4}>
              <Box className="box_style">
                <Typography className="title_text">Pending Approvals: {salesData?.pending_approvals}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </MyDiv>
  )
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
})

export default connect(null, mapDispatchToProps)(DashBoard)

