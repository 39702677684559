/* eslint-disable max-len */
/* eslint-disable prefer-template */
import React, {useEffect, useState} from 'react'
import {Box, Chip, Typography} from '@mui/material'
import {Link} from 'react-router-dom'
// import moment from 'moment'
import {LocationOn} from '@mui/icons-material'
import {useTranslation} from 'react-i18next'
import MyDiv from './listviewTab.style'
export default function ListviewTab(props) {
  const {t} = useTranslation()
  const [data, setData] = useState()

  useEffect(() => {
    setData(props.filteredTeachers?.slice(0, 15))
  }, [props.filteredTeachers])

  return (
    <MyDiv>
      {data?.length > 0 ? <>{data?.map((item, index) => {
        return (
          <Box className="list_users" key={index}>
            <Box className="inner_list">
              {item?.account_type === 2 ?
                item?.school_image.includes('storage/upload') ? <img src={item?.school_image} /> : <Typography className="avatar_cam title_cam" >{item?.school_name?.substring(0, 1).toUpperCase()}</Typography> :
                item?.profile_image.includes('storage/upload') ? <img src={item?.profile_image} /> : <Typography className="avatar_cam title_cam" >{item?.name?.substring(0, 1).toUpperCase()}{item?.surname?.substring(0, 1).toUpperCase()}</Typography>}
              <Box className="list_items">
                <Typography className="username">
                  {item?.name}{' '}{item?.surname}
                </Typography>
                {item?.is_doc_verified === 1 ? <Typography className="verified_text">
                  Verified
                </Typography> : null}
                {/* <Typography className="list_data">
                  {moment(item?.dob, 'YYYYMMDD').fromNow().split('ago')} {t('old')}
                </Typography> */}
                {item?.experience_name === null ? <Typography className="list_data">
                  0 - 1 years of experience
                </Typography>
                  : <Typography className="list_data">
                    {item?.experience_name}+ years of experience
                  </Typography>}
              </Box>
            </Box>
            <Box className="category_box">
              {item?.sub_category_name === null ?
                <Chip
                  label={t('Nodatayet')}
                  className="category_items"
                /> : <Chip
                  label={item?.sub_category_name}
                  className="category_items"
                />}
            </Box>
            <Box className="flex_box">
              <Box className="list_row">
                <LocationOn />
                <Typography className="list_data">
                  {item?.country_name}, {item?.state_name}, {item?.address}
                </Typography>
              </Box>
              {/* <Box className="list_row">
                <Euro />
                <Typography className="list_data">
                  {t('StartingCost')}{item?.cost} / {item?.cost_per}
                </Typography>
              </Box> */}
            </Box>
            <Link target="_blank" className="view_btn" to={'/teacher/' + item?.name + item?.surname + item?.teacher_id}>{t('ViewProfile')}</Link>
          </Box>
        )
      })}</> : <>No Results found.</>}
    </MyDiv>
  )
}
