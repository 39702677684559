export const POST_CONFIRMPAYMENT_REQUEST = 'POST_CONFIRMPAYMENT_REQUEST'
export const POST_CONFIRMPAYMENT_FAILURE = 'POST_CONFIRMPAYMENT_FAILURE'
export const POST_CONFIRMPAYMENT_SUCCESS = 'POST_CONFIRMPAYMENT_SUCCESS'

export const POST_CONFIRMUPGRADE_REQUEST = 'POST_CONFIRMUPGRADE_REQUEST'
export const POST_CONFIRMUPGRADE_FAILURE = 'POST_CONFIRMUPGRADE_FAILURE'
export const POST_CONFIRMUPGRADE_SUCCESS = 'POST_CONFIRMUPGRADE_SUCCESS'

export const GET_BILLING_LIST_REQUEST = 'GET_BILLING_LIST_REQUEST'
export const GET_BILLING_LIST_FAILURE = 'GET_BILLING_LIST_FAILURE'
export const GET_BILLING_LIST_SUCCESS = 'GET_BILLING_LIST_SUCCESS'

export const GET_PAYMENT_DETAILS_REQUEST = 'GET_PAYMENT_DETAILS_REQUEST'
export const GET_PAYMENT_DETAILS_FAILURE = 'GET_PAYMENT_DETAILS_FAILURE'
export const GET_PAYMENT_DETAILS_SUCCESS = 'GET_PAYMENT_DETAILS_SUCCESS'

export const POST_CANCEL_SUBSCRIPTION_REQUEST = 'POST_CANCEL_SUBSCRIPTION_REQUEST'
export const POST_CANCEL_SUBSCRIPTION_FAILURE = 'POST_CANCEL_SUBSCRIPTION_FAILURE'
export const POST_CANCEL_SUBSCRIPTION_SUCCESS = 'POST_CANCEL_SUBSCRIPTION_SUCCESS'

export const POST_RENEW_SUBSCRIPTION_REQUEST = 'POST_RENEW_SUBSCRIPTION_REQUEST'
export const POST_RENEW_SUBSCRIPTION_FAILURE = 'POST_RENEW_SUBSCRIPTION_FAILURE'
export const POST_RENEW_SUBSCRIPTION_SUCCESS = 'POST_RENEW_SUBSCRIPTION_SUCCESS'
