export const GET_CATEGORIES_REQUEST = 'GET_CATEGORIES_REQUEST'
export const GET_CATEGORIES_FAILURE = 'GET_CATEGORIES_FAILURE'
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORY_SUCCESS'

export const GET_ADMIN_CATEGORIES_REQUEST = 'GET_ADMIN_CATEGORIES_REQUEST'
export const GET_ADMIN_CATEGORIES_FAILURE = 'GET_ADMIN_CATEGORIES_FAILURE'
export const GET_ADMIN_CATEGORIES_SUCCESS = 'GET_ADMIN_CATEGORY_SUCCESS'

export const POST_CATEGORY_REQUEST = 'POST_CATEGORY_REQUEST'
export const POST_CATEGORY_FAILURE = 'POST_CATEGORY_FAILURE'
export const POST_CATEGORY_SUCCESS = 'POST_CATEGORY_SUCCESS'

export const POST_SUBCATEGORY_REQUEST = 'POST_SUBCATEGORY_REQUEST'
export const POST_SUBCATEGORY_FAILURE = 'POST_SUBCATEGORY_FAILURE'
export const POST_SUBCATEGORY_SUCCESS = 'POST_SUBCATEGORY_SUCCESS'

export const POST_CATEGORYIMAGE_REQUEST = 'POST_CATEGORYIMAGE_REQUEST'
export const POST_CATEGORYIMAGE_FAILURE = 'POST_CATEGORYIMAGE_FAILURE'
export const POST_CATEGORYIMAGE_SUCCESS = 'POST_CATEGORYIMAGE_SUCCESS'

export const POST_SUBCATEGORYIMAGE_REQUEST = 'POST_SUBCATEGORYIMAGE_REQUEST'
export const POST_SUBCATEGORYIMAGE_FAILURE = 'POST_SUBCATEGORYIMAGE_FAILURE'
export const POST_SUBCATEGORYIMAGE_SUCCESS = 'POST_SUBCATEGORYIMAGE_SUCCESS'
