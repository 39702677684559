/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import {ArrowDropDown} from '@mui/icons-material'
import {Box, FormControl, MenuItem, Select, Typography, Menu, Grid, Button} from '@mui/material'
import {styled} from '@mui/system'
import {Autocomplete} from '@react-google-maps/api'
import React, {useEffect, useState} from 'react'
import {isMobile} from 'react-device-detect'
import {useTranslation} from 'react-i18next'
import {connect, useSelector} from 'react-redux'
import {bindActionCreators} from 'redux'
import {CustomButton, CustomTextBox} from '../../../../components'
import {ActionCreators} from '../../../../redux/actions'
import MyDiv from './searchDialog.style'

const StyledMenuMobile = styled((props) => (
  <Menu sx={{width: '95%', height: '100%', position: 'absolute', left: '-5px', top: '0px', padding: '8px'}}
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({theme}) => ({
  '& .MuiPaper-root': {
    'height': '300px',
    'width': '100%',
    'borderRadius': 6,
    'padding': '8px',
    // 'marginTop': theme.spacing(1),
    'minWidth': 180,
    'color':
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    'boxShadow':
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: '#ffffff',
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: '#ffffff',
      },
    },
  },
}))


const categoryStyle = {
  fontSize: '18px',
  fontWeight: '500',
  fontFamily: 'Proxima Nova',
  background: 'rgb(247, 248, 250)',
  padding: '7px 10px',
  color: 'rgb(240, 203, 71)',
}

const subCategoryStyle = {
  fontSize: '14px',
  fontWeight: '600',
  fontFamily: 'Proxima Nova',
  textTransform: 'capitalize',
  color: '#000',
  justifyContent: 'start',
}

const boxStyle = {
  display: 'flex', flexWrap: 'wrap', padding: '16px',
}

const SearchDialog = (props) => {
  const {t} = useTranslation()
  const [categoryList, setCategoryList] = useState()
  const [anchorEl, setAnchorEl] = useState(null)
  const categoryState = useSelector((state) => state.categoryState)
  const open = Boolean(anchorEl)
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredCategories, setFilteredCategories] = useState()

  useEffect(() => {
    setFilteredCategories(categoryState?.getCategorySubCategory?.data)
    setCategoryList(categoryState?.getCategorySubCategory?.data)
  }, [categoryState?.getCategorySubCategory?.data])

  useEffect(() => {
    // props.actions.getTeacherAction()
    // props.actions.getListAction()
    // props.actions.getTeachersCardListAction()
    if (localStorage.getItem('country') || localStorage.getItem('state') || localStorage.getItem('city')) {
      props.actions.getCategoryAction('search', localStorage.getItem('country'), localStorage.getItem('state'), localStorage.getItem('city'))
    }
    // props.actions.getRegisteredTeachersListAction()
  }, [localStorage.getItem('country'), localStorage.getItem('state'), localStorage.getItem('city')])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
    setSearchTerm('')
    if (!isMobile) {
      props.setDialog(!props.dialog)
    }
    setFilteredCategories(categoryState?.getCategorySubCategory?.data)
  }

  const searchSubCategories = (searchTerm) => {
    const results = categoryList.map((category) => {
      const filteredSubCategories = category.admin_sub_category?.filter((subCategory) => subCategory.sub_category_name.toLowerCase().includes(searchTerm.toLowerCase())
      )
      return {...category, admin_sub_category: filteredSubCategories}
    })
    return results
  }

  const handleSearchCateg = (e) => {
    setSearchTerm(e.target.value)
    const results = searchSubCategories(e.target.value)
    setFilteredCategories(results)
  }

  const filteredData = filteredCategories?.filter((item) => item.admin_sub_category.length > 0)
  const subCategories = filteredData?.flatMap((category) => category.admin_sub_category)
  const subCategoryName = subCategories?.filter((item) => item.id === Number(localStorage.getItem('subCat')))[0]?.sub_category_name
  const handleSubCategoryChange = (event, val, valName) => {
    handleClose()
    props.setCategoryValue(event.target.value || val)
    props.setCategoryName(valName)
    localStorage.setItem('subCat', event.target.value || val)
    props.setAllSubCategories(props.allSubCategories)
  }

  console.log(filteredData, 'filteredData')

  return (
    <MyDiv>
      {/* <Typography className="web_heading">
        {t('webHeading')}
      </Typography> */}
      <Box className="flex_bar" mt={1}>
        {isMobile ? <>
          <Typography className="web_heading">
            {t('webHeading')}
          </Typography>
          <Box className="w-40">
            <Autocomplete
              className="auto_complete"
              onPlaceChanged={props.onPlaceChanged}
              onLoad={props.onPlaceLoad}
            >
              <input type="text" placeholder="Country or City" />
            </Autocomplete>
          </Box>
          <Box>
            <CustomButton onClick={handleClick} className="category_btn_mob" title={subCategoryName || t('SelectCategory')} endIcon={<ArrowDropDown />} />
            <StyledMenuMobile
              id="demo-customized-menu"
              MenuListProps={{
                'aria-labelledby': 'demo-customized-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <Box sx={{
                position: 'fixed',
                marginTop: {xs: '-4px', md: '-70px', sm: '-4px'},
                width: {xs: '85.7%', md: '47.7%', sm: '85.7%'},
                zIndex: 11111,
              }}
              >
                <CustomTextBox
                  onKeyDown={(e) => {
                    e.stopPropagation()
                  }}
                  autoFocus
                  fieldLabel={t('SearchCategory')}
                  value={searchTerm}
                  onChange={handleSearchCateg}
                  className="text_box"
                />
              </Box>
              <Box sx={{paddingTop: '35px'}}>
                <Grid container columnSpacing={{sm: 4, md: 4}} mt={1}>
                  {filteredData?.map((item, index) => {
                    return (
                      <Grid item xs={12} key={index} mb={2}>
                        <Typography style={categoryStyle}>{item.category_name}</Typography>
                        <Box style={boxStyle}>
                          {item?.admin_sub_category?.map((subItem, index) => {
                            return (
                              <Box sx={{width: {xs: '100%', md: '50%', sm: '100%'}}} key={index}>
                                <Button
                                  onClick={(e) => handleSubCategoryChange(e, subItem.id, subItem.sub_category_name) && props.handleClose()}
                                  style={subCategoryStyle}
                                >
                                  {subItem.sub_category_name}
                                </Button>
                              </Box>
                            )
                          })}
                        </Box>
                      </Grid>
                    )
                  })}
                </Grid>
              </Box>
            </StyledMenuMobile>
          </Box>
          <Box className="btn_search_main">
            <CustomButton
              onClick={(e) => props.handleWebSearch(e, 'webSearch')}
              title={t('search')}
              className="btn-search"
            />
          </Box>
        </> : <Box sx={{width: '45vw', height: '100vh'}}>
          <Typography className="web_heading2">
            {t('categoryPlaceholder')}
          </Typography>
          <CustomTextBox
            onKeyDown={(e) => {
              e.stopPropagation()
            }}
            autoFocus
            fieldLabel={t('SearchCategory')}
            value={searchTerm}
            onChange={handleSearchCateg}
          />
          <Grid container columnSpacing={{sm: 4, md: 4}} mt={1}>
            {filteredData?.map((item, index) => {
              return (
                <Grid item xs={12} key={index} mb={2}>
                  <Typography style={categoryStyle}>{item.category_name}</Typography>
                  <Box style={boxStyle}>
                    {item?.admin_sub_category?.map((subItem, index) => {
                      return (
                        <Box sx={{width: {xs: '100%', md: '50%', sm: '100%'}}} key={index}>
                          <Button
                            onClick={(e) => handleSubCategoryChange(e, subItem.id, subItem.sub_category_name) && props.handleClose()}
                            style={{
                              ...subCategoryStyle,
                              backgroundColor: subItem.sub_category_name === subCategoryName ? 'rgb(240, 203, 71)' : 'initial',
                            }}
                          >
                            {subItem.sub_category_name}
                          </Button>
                        </Box>
                      )
                    })}
                  </Box>
                </Grid>
              )
            })}
          </Grid>
        </Box>}
      </Box>
    </MyDiv>
  )
}


const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
})
export default connect(null, mapDispatchToProps)(SearchDialog)
