import React from 'react'
import {Box} from '@mui/material'
import MyDiv from './AuthLeftPanel.style'

export default function AuthLeftPanel() {
  return (
    <MyDiv>
      <Box className="authleft_panel" />
    </MyDiv>

  )
}
