/* eslint-disable max-len */
/* eslint-disable no-constant-condition */
import React, {useState, useEffect} from 'react'
import {Article, Email, Error, Image, List, ListAlt, LocationOn, Reply, School, Videocam} from '@mui/icons-material'
import {Box, Divider, FormControl, Grid, IconButton, MenuItem, Modal, Select, Tab, Typography} from '@mui/material'
import {TabContext, TabList, TabPanel} from '@mui/lab'
import {connect, useSelector} from 'react-redux'
import {bindActionCreators} from 'redux'
import moment from 'moment'
import {useLocation, useParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {FacebookShareButton, TwitterShareButton, EmailShareButton, WhatsappShareButton} from 'react-share'
import {styled} from '@mui/system'
import ReCAPTCHA from 'react-google-recaptcha'
import {ActionCreators} from '../../redux/actions'
import {CustomButton, CustomTextArea, CustomTextBox} from '../../components/Inputs'
import {Loader, SnackBar} from '../../components'
import facebookIcon from '../../images/facebook.png'
import whatsappIcon from '../../images/whatsapp.png'
import twitterIcon from '../../images/twitter.png'
import mailIcon from '../../images/mail.png'
import {About, EduExp, Lessons, Images, Video, ContactUs, AuthTopPanelMain, SkillsInterests} from './components'
import MyDiv from './mainDashBoard.style'

const selectStyle = {
  height: '40px',
  width: '100%',
  fontFamily: 'Proxima Nova',
  fontWeight: '500px',
  fontSize: '14px',
  color: '#0F1350',
  borderRadius: '2px',
}
const CustomMenuItem = styled(MenuItem)(({theme}) => ({
  '&.MuiMenuItem-root': {
    backgroundColor: '#ffffff',
    fontFamily: 'Proxima Nova',
    fontSize: '15px',
    fontWeight: '600',
    color: '#000000',
  },
  '&.MuiMenuItem-root:nth-child(2)': {
    marginTop: '-8px',
  },
  '&.MuiMenuItem-root:last-child': {
    marginBottom: '-8px',
  },
}))

const Placeholder = styled(MenuItem)(({theme}) => ({
  '&.MuiMenuItem-root': {
    display: 'none',
  },
}))

const ITEM_HEIGHT = 60
const Menu = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5,
    },
  },
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 150,
  bgcolor: '#ffffff',
  border: '1px solid #rgb(217, 217, 217)',
  borderRadius: 4,
  p: 4,
  display: 'flex',
  justifyContent: 'center',
  columnGap: 1,
}

const style2 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: '#ffffff',
  border: '1px solid #rgb(217, 217, 217)',
  borderRadius: 4,
  p: 4,
}

const icnStyle = {
  width: '36px',
  height: '36px',
}

const textArea1 = {
  border: '0.1px solid #d9d9d9',
  borderRadius: '2px',
  width: '100%',
  height: '40px',
  fontFamily: 'Arial',
  fontSize: '14px',
  fontWeight: '400',
}
const textArea2 = {
  border: '0.2px solid #d9d9d9',
  borderRadius: '2px',
  width: '293px',
  height: '100px',
  resize: 'none',
  fontFamily: 'Arial',
  fontSize: '14px',
  fontWeight: '400',
}

const textStyle = {
  marginTop: 4,
  fontFamily: 'Arial',
  fontSize: '14px',
  fontWeight: '400',
}

const textStyle2 = {
  fontFamily: 'Arial',
  fontSize: '16px',
  fontWeight: '600',
}

const btnStyle = {
  fontFamily: 'Arial',
  fontSize: '14px',
  fontWeight: '400',
  color: 'rgb(255, 255, 255)',
  backgroundColor: 'rgb(240, 203, 71)',
  textTransform: 'capitalize',
  border: '1px solid rgb(240, 203, 71)',
  width: '50%',
  borderRadius: '5px',
  marginTop: 4,
}
const btnStyleDisabled = {
  fontFamily: 'Arial',
  fontSize: '14px',
  fontWeight: '400',
  color: '#000000',
  backgroundColor: 'grey',
  textTransform: 'capitalize',
  border: '1px solid grey',
  width: '50%',
  borderRadius: '5px',
  marginTop: 4,
}

const MainDashBoard = (props) => {
  const {t} = useTranslation()
  const [categoryList, setCategoryList] = useState()
  const [teachersData, setTeachersData] = useState([])
  const [value, setValue] = useState('1')
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('')
  const [popUp, setPopUp] = useState(false)
  const [popUpReport, setPopUpReport] = useState(false)
  const [accountStatus, setAccountStatus] = useState()
  const [inputValue, setInputValue] = useState({})
  const [reportData, setReportData] = useState({})
  const [verified, setVerified] = useState(false)
  const masterState = useSelector((state) => state.masterState)
  const globalState = useSelector((state) => state.globalState)
  const categoryState = useSelector((state) => state.categoryState)
  const teacherId = useParams()
  const location = useLocation()

  const validEmail = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$')
  const id = teacherId?.id
  let result = id.replace(/[^0-9]/g, '')
  const urlToShare = 'https://example.com'
  const url = process.env.REACT_APP_REDIRECT_SERVER + location.pathname
  const creationDate = new Date(teachersData?.data?.userDetails[0]?.created_at)
  const currentDate = new Date()
  const timeDifference = currentDate - creationDate
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24))

  const reasons = [
    {id: 1, reasonName: t('fakeAccount')},
    {id: 2, reasonName: t('inappropriateImageVideo')},
    {id: 3, reasonName: t('inappropriateLanguage')},
    {id: 4, reasonName: t('other')},
  ]

  useEffect(() => {
    props.actions.getCategoryAction()
  }, [])

  useEffect(() => {
    setCategoryList(categoryState?.getCategorySubCategory?.data)
  }, [categoryState?.getCategorySubCategory?.data])

  useEffect(() => {
    props.actions.getTeacherAction(Number(result))
  }, [])

  useEffect(() => {
    setTeachersData(masterState?.getTeacherData?.data)
  }, [masterState?.getTeacherData?.data])

  useEffect(() => {
    if (localStorage.getItem('lang') === 'EN' || 'GK') {
      props.actions.getListAction()
      props.actions.getTeacherAction(Number(result))
    }
  }, [localStorage.getItem('lang')])

  useEffect(() => {
    let obj = {
      user_id: Number(result),
    }
    props.actions.postAccountStatusAction(obj)
  }, [result])

  useEffect(() => {
    setAccountStatus(masterState?.postAccountStatus?.data?.data?.payload?.status)
  }, [masterState?.postAccountStatus?.data?.data?.payload?.status])

  useEffect(() => {
    if (globalState?.postMessage?.data?.status === 'success') {
      setMessage(globalState?.postMessage?.data?.message)
      setStatus('success')
      setInputValue({})
    }
  }, [globalState?.postMessage?.data])

  useEffect(() => {
    if (globalState?.reportUser?.data?.data?.meta?.status === 'success') {
      setMessage(globalState?.reportUser?.data?.data?.meta?.message)
      setStatus('success')
      setReportData({})
      setPopUpReport(false)
    } else if (globalState?.reportUser?.error?.meta?.status === 'failure') {
      setMessage(globalState?.reportUser?.error?.meta?.message)
      setStatus('error')
    }
  }, [globalState?.reportUser])

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handlePhone = (val, country) => {
    const str = country.format
    const fullStopCount = (str.match(/\./g) || []).length
    localStorage.setItem('phoneNumberLength', fullStopCount)
    setInputValue({...inputValue, mobile: val})
  }

  const handleChangeMessage = (e) => {
    setInputValue({...inputValue, [e.target.name]: e.target.value, user_id: Number(result)})
  }
  const handleSubmit = () => {
    if (!inputValue.name || !inputValue.email || !inputValue.mobile || !inputValue.subject || !inputValue.description === '') {
      setMessage(t('allFieldMandatory'))
      setStatus('error')
    } else if (!validEmail.test(inputValue.email)) {
      setMessage(t('validEmailRegEx'))
      setStatus('error')
    } else if (Number(localStorage.getItem('phoneNumberLength')) !== inputValue?.mobile?.length) {
      setMessage(t('phoneNumberValidation'))
      setStatus('error')
    } else {
      if (inputValue.name?.length <= 30 && inputValue.subject?.length <= 30 && inputValue.description?.length <= 300) {
        props.actions.postMessageAction(inputValue)
        setInputValue({})
      } else if (inputValue.name?.length >= 30 || inputValue.subject?.length >= 30 || inputValue.description?.length >= 300) {
        setMessage(t('contactValidation'))
        setStatus('error')
      }
    }
  }

  const handleSnackBarClose = () => {
    setMessage('')
    setStatus('')
    props.actions.cleanUpUserState()
  }

  const handleSharePopUp = () => {
    setPopUp(!popUp)
  }

  const handleReportPopup = () => {
    setPopUpReport(!popUpReport)
    setReportData({})
    setVerified(false)
  }

  const handleReportForm = (e) => {
    setReportData({...reportData, [e.target.name]: e.target.value})
  }
  const handleReportUser = () => {
    let payload = {
      'subject': reportData.subject,
      'reason': reportData.reason,
      'message': reportData.message,
      'teacher_page_url': url,
      'specify-other-reason': reportData.specification,
    }
    props.actions.postReportUserAction(payload)
  }

  function handleCaptcha() {
    setVerified(true)
  }
  const handleCaptchaExpired = () => {
    setVerified(false)
  }

  return (
    <MyDiv>
      {(masterState?.getTeacherData?.loading || globalState?.postMessage?.loading) && (
        <div>
          <Loader />
        </div>
      )}
      <AuthTopPanelMain categoryList={categoryList} title1={t('Home')} title2={t('allCategories')} title3={t('Blog')} title4={t('ContactUs')} title5={t('Login')} title6={t('Register')} />
      {accountStatus === 1 && <Box>
        <Grid className="img_grid" container>
          {teachersData?.data?.gallery?.coverimage?.[0]?.media_url ?
            <img className="img_style" src={teachersData?.data?.gallery?.coverimage?.[0]?.media_url} alt="pic" />
            : <div className="NoBackground" />}
        </Grid>
        <Box className="main_body">
          <Box className="card_style">
            <Box className="bg_white">
              <Grid container md={12}>
                <Box className="flex_box2">
                  {teachersData?.is_doc_verified === 1 ? <Typography className="verified_text">
                    Verified
                  </Typography> : null}
                  {teachersData?.account_type === 1 && <Typography className="text1_label">{teachersData?.data?.userDetails?.[0]?.name} {teachersData?.data?.userDetails?.[0]?.surname}</Typography>}
                  {teachersData?.account_type === 2 && <Typography className="text1_label">{teachersData?.data?.school_details?.[0]?.school_name}</Typography>}
                  {daysDifference > 30 ? null : <CustomButton title={t('New')} className="btn1_text" />}
                </Box>
              </Grid>
              <Grid container md={12}>
                <Grid item md={6}>
                  <Box className="flex_box">
                    <IconButton className="icn_btn"><LocationOn /></IconButton>
                    <Typography className="text2_label">{teachersData?.data?.userLocation?.[0]?.address}{' '}{teachersData?.data?.userLocation?.[0]?.city}{' '}{teachersData?.[0]?.data?.userLocation?.[0]?.state}{' '}{teachersData?.[0]?.data?.userLocation?.[0]?.country}
                    </Typography>
                  </Box>
                  <Box className="flex_box">
                    <Typography className="text3_label">{t('membersince')}:&nbsp;</Typography>
                    <Typography className="text4_label">{teachersData?.created_at ? moment(teachersData?.created_at).format('DD-MM-YYYY') : ''}</Typography>
                  </Box>
                  <Box className="flex_box">
                    <Typography className="text3_label">{t('views')}:&nbsp;</Typography>
                    <Typography className="text4_label">{teachersData?.views}</Typography>
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <Box className="grid_box">
                    <CustomButton onClick={handleSharePopUp} title={t('Share')} startIcon={<Reply className="reply_icn" />} className="btn3_text" />
                    <CustomButton onClick={handleReportPopup} title={t('Report')} startIcon={<Error />} className="btn4_text" />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box className="tab_box">
              <TabContext value={value}>
                <Box className="bg_white1">
                  <TabList className="tab_container"
                    textColor="#262728"
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    TabIndicatorProps={{
                      style: {
                        display: 'none',
                        backgroundColor: '#FFFFFF',
                      },
                    }}
                  >
                    <Tab className="tab_names" icon={<Article className="icn_style" />} iconPosition="start" label={t('About')} value="1" />
                    <Tab className="tab_names" icon={<ListAlt className="icn_style" />} iconPosition="start" label={t('Lessons')} value="2" />
                    <Tab className="tab_names" icon={<School className="icn_style" />} iconPosition="start" label={t('eduExp')} value="3" />
                    <Tab className="tab_names" icon={<List className="icn_style" />} iconPosition="start" label={t('Skills&Interests')} value="4" />
                    <Tab className="tab_names" icon={<Image className="icn_style" />} iconPosition="start" label={t('Images')} value="5" />
                    <Tab className="tab_names" icon={<Videocam className="icn_style" />} iconPosition="start" label={t('Videos')} value="6" />
                    <Tab className="tab_names" icon={<Email className="icn_style" />} iconPosition="start" label={t('ContactMe')} value="7" />
                  </TabList>
                </Box>
                <Box className="bg_white2">
                  <TabPanel value="1" className="tab_panel">
                    <About
                      handleSharePopUp={handleSharePopUp}
                      handleReportPopup={handleReportPopup}
                      teachersData={teachersData}
                    />
                  </TabPanel>
                  <TabPanel value="2" className="tab_panel"><Lessons teachersData={teachersData} /></TabPanel>
                  <TabPanel value="3" className="tab_panel"><EduExp teachersData={teachersData} /></TabPanel>
                  <TabPanel value="4" className="tab_panel"><SkillsInterests teachersData={teachersData} /></TabPanel>
                  <TabPanel value="5" className="tab_panel"><Images teachersData={teachersData} /></TabPanel>
                  <TabPanel value="6" className="tab_panel"><Video teachersData={teachersData} /></TabPanel>
                  <TabPanel value="7" className="tab_panel">
                    <ContactUs
                      handlePhone={handlePhone}
                      handleSubmit={handleSubmit}
                      handleChangeMessage={handleChangeMessage}
                      inputValue={inputValue}
                    />
                  </TabPanel>
                </Box>
              </TabContext>
            </Box>
          </Box>
        </Box>
      </Box>}
      {accountStatus === 2 && <Typography sx={{display: 'flex', justifyContent: 'center', margin: '300px'}}>Your Account is disabled. Please Enable your Account.</Typography>}
      <SnackBar
        message={message}
        severity={status}
        open={message?.length > 0}
        close={handleSnackBarClose}
      />
      <Modal
        open={popUp}
        onClose={handleSharePopUp}
      >
        <Box sx={style}>
          <FacebookShareButton url={urlToShare}>
            <img style={icnStyle} src={facebookIcon} />
          </FacebookShareButton>
          <WhatsappShareButton url={urlToShare}>
            <img style={icnStyle} src={whatsappIcon} />
          </WhatsappShareButton>
          <TwitterShareButton url={urlToShare}>
            <img style={icnStyle} src={twitterIcon} />
          </TwitterShareButton>
          <EmailShareButton url={urlToShare}>
            <img style={icnStyle} src={mailIcon} />
          </EmailShareButton>
        </Box>
      </Modal>
      <Modal
        open={popUpReport}
        onClose={handleReportPopup}
      >
        <Box sx={style2}>
          <Typography style={textStyle2}>{t('Report')}</Typography>
          <Divider style={{marginTop: 3, marginBottom: 7}} />
          <Box>
            <Typography style={textStyle}>{t('Subject')}</Typography>
            <CustomTextBox
              name="subject"
              value={reportData.subject}
              onChange={handleReportForm}
              style={textArea1}
            />
            <Typography style={textStyle}>{t('Reason')}</Typography>
            <FormControl fullWidth>
              <Select
                MenuProps={{...Menu, autoFocus: false}}
                style={selectStyle}
                name="reason"
                value={reportData.reason}
                onChange={handleReportForm}
                id="language_list"
              >
                <Placeholder value={0}>Select</Placeholder>
                {reasons.map((i) => {
                  return (
                    <CustomMenuItem key={i.id} value={i.id} >
                      {i.reasonName}
                    </CustomMenuItem>
                  )
                })}
              </Select>
            </FormControl>
            {reportData.reason === 4 ? <>
              <Typography style={textStyle}>{t('pleaseSpecify')}</Typography>
              <CustomTextBox
                name="specification"
                value={reportData.specification}
                onChange={handleReportForm}
                style={textArea1}
              />
            </> : null}
            <Typography style={textStyle}>{t('Message')}</Typography>
            <CustomTextArea
              name="message"
              value={reportData.message}
              onChange={handleReportForm}
              style={textArea2}
            />
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_CAPTCHA_KEY}
              onChange={handleCaptcha}
              onExpired={handleCaptchaExpired}
            />
            <CustomButton disabled={!verified} onClick={handleReportUser} style={verified ? btnStyle : btnStyleDisabled} title={t('Report')} />
          </Box>
        </Box>
      </Modal>
    </MyDiv>
  )
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
})

export default connect(null, mapDispatchToProps)(MainDashBoard)
