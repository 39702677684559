import styled from 'styled-components'

const MyDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;

.web_subheading1{
    font-family: "Proxima Nova";
    font-size: 18px;
    font-weight: 600;
    margin-top: 16px;
    margin-bottom: 16px;
    @media(max-width:767px){
    text-align: center;
    margin-top: 8px;
    margin-bottom: 8px;
    padding-top: 10px;
    }
}
.web_subheading2{
    font-family: "Proxima Nova";
    font-size: 18px;
    font-weight: 400;
    margin-top: 16px;
    margin-top: 8px;
        margin-bottom: 8px;
    width: 75%;
    line-height: 20px;
    @media(max-width:767px){
        font-size: 18px;
    font-weight: 400;
    width: 100%;
    text-align: center;
    margin-top: 8px;
        margin-bottom: 8px;
    }
}

.register_btn{
    margin-top: 8px;
    background-color: #f0cb47;
    color: #000000;
    font-size: 16px;
    font-weight: 600;
    font-family: "Proxima Nova";
    text-transform: capitalize;
    border: 1px solid transparent;
    height: 40px;
    border-radius: 5px;
    @media(max-width:767px){
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    }
}
.register_btn:hover{
    background-color: #f0cb47;
    color: #000000;
}

.btn_style2{
    font-family: "Arial";
    font-size: 18px;
    font-weight: 400;
    color: #ffffff;
    background-color: #f0cb47;
    border-radius: 5px;
    text-transform: capitalize;
    width: 40%;
    height: 40px;
}
.btn_style2: hover{
    color: #ffffff;
    background-color: #f0cb47;
}

.list_texts{
    padding: 0px 160px 0px 160px;
}

.img_text1{
    font-family: "Proxima Nova";
    font-size: 18px;
    font-weight: 400;
}
.img_text2{
    font-family: "Arial";
    font-size: 32px;
    font-weight: 600px;
    margin-top: 8px;
}
.img_text3{
    font-family: "Proxima Nova";
    font-size: 18px;
    font-weight: 400;
    margin-top: 8px;
    margin-bottom: 24px;
}

.width_style{
    width: 63%;
}

.text_img{
    position: absolute;
    color: #FFFFFF;
    background: rgb(0 0 0 / 37%);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align:center;
    @media(max-width:767px){
        display:none!important;
    }
    @media(min-width:768px) and (max-width:1023px){
        display: none!important;
    }
}

.img_grid{
    position: relative;
    width: 100%;
    height: 455px;
    // background: url(https://blog.academbee.com/wp-content/uploads/2023/03/teacher.jpg)
}

.main_box1{
    background-color: #F9FBFD;
    display: block;
    @media(max-width:767px){
        display:none;
    }
    @media(min-width:768px) and (max-width:1023px){
        display: none;
    }
}
.main_home_box{
    background-color: rgb(240, 203, 71);
    padding: 1px 100px;
    height: 100vh;
    @media(max-width:767px){
        padding: 16px;
        height: 100vh;
    }
    @media(min-width:768px) and (max-width:1023px){
        padding: 16px;
        height: 100vh;
    }
}
.home_box{
    background-color: #ffffff;
    border-radius: 8px;
    margin: 125px 0px;
    padding: 50px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px -1px 41px;
    @media(max-width:767px){
        margin: 50px 0px;
        padding: 8px;
    }
    @media(min-width:768px) and (max-width:1023px){
        margin: 50px 0px;
        padding: 8px;
    }
}
.popular_box{
    padding: 0px 200px;
    margin-bottom:60px;
}
.text_title{
    color: #000000;
    font-family: "Proxima Nova";
    font-size: 36px;
    font-weight: 500px;
    text-align: center;
    @media(max-width:767px){
        text-align: start;
    }
    @media(min-width:768px) and (max-width:1023px){
        text-align: start;
    }
}
.btn_box{
    margin-top: 30px;
    display:flex;
    justify-content:center;
}
.btn_style{
    font-family: "Arial";
    font-size: 18px;
    font-weight: 400;
    color: #ffffff;
    background-color: #f0cb47;
    border-radius: 5px;
    text-transform: capitalize;
}
.btn_style: hover{
    color: #ffffff;
    background-color: #f0cb47;
}
`
export default MyDiv
