
import styled from 'styled-components'

const MyDiv = styled.div`
.css-1t1j96h-MuiPaper-root-MuiDialog-paper{
    width: 100%!important;
}
.search_box{
    width: 100%;
}
.cat_name{
    font-size: 18px;
    font-weight: 500;
    background: rgb(247, 248, 250);
    padding: 7px 10px;
    color: rgb(240, 203, 71);
}
.category_menu{
    position: absolute;
    color: #000;
    top: 0px;
    right: 0px;
}
.category_btn_mob{
    flex: 1;
    text-align: start;
    width: 100%;
    height: 40px;
    color: #000;
    font-family: "Proxima Nova";
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
    position: relative;
    display: block;
    background: #fff;
    border: 1px solid rgb(217 218 216);
}
.category_btn:hover{
    display: block;
    background: #fff;
}
.category_btn svg{
    position: absolute;
    color: #000;
    top: 7px;
    right: 9px;
}
.category_btn{
    flex: 1;
    text-align: start;
    width: 350px!important;
    height: 40px;
    color: #000;
    font-family: "Proxima Nova";
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
    position: relative;
    display: block;
    background: #fff;
    border: 1px solid rgb(217 218 216);
}
.category_btn_mob:hover{
    display: block;
    background: #fff;
}
.category_btn_mob svg{
    position: absolute;
    color: #000;
    top: 7px;
    right: 9px;
}
.flex_bar{
    display: flex;
  justify-content: space-between;
//   align-items: center;
    position: relative;
    flex-direction: row;
    column-gap: 10px;
    background: #fff;
    padding: 10px;
    border-radius: 5px;
    @media(max-width:767px){
        flex-direction: column;
        row-gap: 10px;
    }
    @media(min-width:768px) and (max-width:1023px){
        flex-direction: column;
    }
}
.select_box{
    flex: 1;
    margin: 0 10px;
    display: none;
    @media(max-width:767px){
        width: 100%;
        display: block;
    }
    @media(min-width:768px) and (max-width:1023px){
        width: 100%;
        display: block;
    }
  }
  .mu_data{
    height:40px;
    width: 100%;
    border-radius: 2px;
}
.mu_data fieldset{
    border-color: #d9d9d9;
  }
  .w-40{
    @media(max-width:767px){
        width: 100%; 
    }
  }
  .auto_complete input{
    width: 100%;
    width: -moz-available;
    font-family: "Proxima Nova";
    font-size: 14px;
    font-weight: 400;
    padding: 0px 208px 0px 20px;
    border-radius: 3px;
    height: 38px;
    border: 1px solid rgb(217, 217, 217);
    @media(max-width:767px){
        width: -webkit-fill-available;
    }
    @media(min-width:768px) and (max-width:1023px){
        width: -webkit-fill-available;
    }
  }
  .auto_complete input:hover{
    border: 1px solid blue;
  }
  .auto_complete input:focus-visible{
    outline: none;
    border-radius: 0px!important;
  }
  .auto_complete{
    padding-right: 42px;
    @media(max-width:767px){
        padding-right: 0px;
    }
    @media(min-width:768px) and (max-width:1023px){
        padding-right: 0px;
    }
}
.btn_search_main{
    flex: 1;
//   margin: 0 10px;
    @media(max-width:767px){
        width: 100%;
    }
}
.btn_search_main .btn-search{
    width: 100%;
    background-color: #F0CB47;
    padding: 0px 50px;
}
.btn-search {
    background-color: #F0CB47;
    color: #000;
    font-size: 16px;
    font-weight: 600;
    font-family: "Proxima Nova";
    text-transform: capitalize;
    border: 1px solid transparent;
    height: 40px;
    border-radius: 10px;
}
.btn-search :hover{
    background-color: #F0CB47;
    color: #000;
}
.web_heading{
    font-family: "Proxima Nova";
    font-size: 45px;
    font-weight: 600;
    width: 75%;
    line-height: 50px;
    @media(max-width:767px){
        font-size: 30px;
    font-weight: 600;
    text-align: center;
    width: 100%;
    line-height: 40px;
    }
}

.web_heading2{
    font-family: "Proxima Nova";
    font-size: 20px;
    font-weight: 600;
    width: 75%;
    line-height: 50px;
    @media(max-width:767px){
        font-size: 30px;
    font-weight: 600;
    text-align: center;
    width: 100%;
    line-height: 40px;
    }
}
`
export default MyDiv
