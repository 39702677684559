export const AUTH_LOGIN_REQUEST = 'AUTH_LOGIN_REQUEST'
export const AUTH_LOGIN_FAILURE = 'AUTH_LOGIN_FAILURE'
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS'
export const AUTH_SIGNUP_REQUEST = 'AUTH_SIGNUP_REQUEST'
export const AUTH_SIGNUP_FAILURE = 'AUTH_SIGNUP_FAILURE'
export const AUTH_SIGNUP_SUCCESS = 'AUTH_SIGNUP_SUCCESS'
export const AUTH_SIGNUP_START = 'AUTH_SIGNUP_START'
export const AUTH_LOGOUT_USER = 'AUTH_LOGOUT_USER'
export const CLEANUP_SNACKBAR = 'CLEANUP_SNACKBAR'
export const AUTH_RESET_PASSWORD_REQUEST = 'AUTH_RESET_PASSWORD_REQUEST'
export const AUTH_RESET_PASSWORD_FAILURE = 'AUTH_RESET_PASSWORD_FAILURE'
export const AUTH_RESET_PASSWORD_SUCCESS = 'AUTH_RESET_PASSWORD_SUCCESS'
export const DISABLE_USER_REQUEST = 'DISABLE_USER_REQUEST'
export const DISABLE_USER_FAILURE = 'DISABLE_USER_FAILURE'
export const DISABLE_USER_SUCCESS = 'DISABLE_USER_SUCCESS'
export const DISABLE_ACCOUNT_REQUEST = 'DISABLE_ACCOUNT_REQUEST'
export const DISABLE_ACCOUNT_FAILURE = 'DISABLE_ACCOUNT_FAILURE'
export const DISABLE_ACCOUNT_SUCCESS = 'DISABLE_ACCOUNT_SUCCESS'
export const ENABLE_USER_REQUEST = 'ENABLE_USER_REQUEST'
export const ENABLE_USER_FAILURE = 'ENABLE_USER_FAILURE'
export const ENABLE_USER_SUCCESS = 'ENABLE_USER_SUCCESS'
export const VERIFY_EMAIL_REQUEST = 'VERIFY_EMAIL_REQUEST'
export const VERIFY_EMAIL_FAILURE = 'VERIFY_EMAIL_FAILURE'
export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS'
export const SEND_OTP_REQUEST = 'SEND_OTP_REQUEST'
export const SEND_OTP_FAILURE = 'SEND_OTP_FAILURE'
export const SEND_OTP_SUCCESS = 'SEND_OTP_SUCCESS'
export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD__REQUEST'
export const UPDATE_PASSWORD__FAILURE = 'UPDATE_PASSWORD__FAILURE'
export const UPDATE_PASSWORD__SUCCESS = 'UPDATE_PASSWORD__SUCCESS'
export const AUTH_GOOGLE_SIGNUP = 'AUTH_GOOGLE_SIGNUP'
