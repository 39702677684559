import {Email, Home, Close} from '@mui/icons-material'
import {Box, Divider, IconButton, MenuItem} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {NavLink, useHistory} from 'react-router-dom'
import {CustomButton} from '../../../../components'
import * as routesNames from '../../../../constants/routes'
import {getToken, removeToken} from '../../../../utilities/authUtils'
import MyDiv from './mobSidebar.style'

export default function MobSidebar(props) {
  const {t} = useTranslation()
  const history = useHistory()

  const handleSignUp = () => {
    removeToken('token')
    removeToken('adminToken')
    history.push('/signup')
  }

  return (
    <MyDiv>
      <Box className="main_box" width={200}>
        <IconButton className="close_btn" onClick={props.onClose}><Close /></IconButton>
        <Box onClick={props.onClose}>
          <MenuItem className="flex_menu">
            <Home />
            <NavLink onClick={props.handleLink} className="link_style" to={routesNames.HOME} style={{textDecoration: 'none'}}>{t('Home')}
            </NavLink>
          </MenuItem>
          {/* <MenuItem className="flex_menu">
            <Newspaper />
            <a className="link_style">{t('Blog')}</a>
          </MenuItem> */}
          <MenuItem className="flex_menu">
            <Email />
            <a className="link_style" target="_blank" href="https://academbee.com/contact-us/">{t('ContactUs')}</a>
          </MenuItem>
          <Divider />
          {/* <MenuItem className="flex_menu">
            <ErrorOutline />
            <a className="link_style">{t('Report')}</a>
          </MenuItem> */}
        </Box>
        {!getToken('token') ? <CustomButton className="signup_btn" onClick={handleSignUp} title={t('signUp')} /> : null}

      </Box>
    </MyDiv>
  )
}
