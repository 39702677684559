import apiInstance from '../config/api/axios'
export const paymentRequest = async(payload) => {
  try {
    let config = {
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json',
      },
    }
    const res = await apiInstance.post(
      'stripe-order-request',
      payload,
      config,
    )
    return res.data.payload
  } catch (error) {
    throw error
  }
}
export const upgradeRequest = async(payload) => {
  try {
    let config = {
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json',
      },
    }
    const res = await apiInstance.post(
      'upgrade-order-request',
      payload,
      config,
    )
    return res.data.payload
  } catch (error) {
    throw error
  }
}

export const postConfirmPayment = async(payload) => {
  let config = {
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json',
    },
  }
  const response = await apiInstance.post(`stripe-confirm-payment?session_id=${payload}`, config)
  return response
}

export const postConfirmUpgrade = async(payload) => {
  let config = {
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json',
    },
  }

  const response = await apiInstance.post(`upgrade-confirm-order?session_id=${payload}`, config)
  return response
}

export const getBillingList = async() => {
  let config = {
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json',
    },
  }
  const response = await apiInstance.get('invoice-list', config)
  return response
}

export const postCancelSubscription = async(payload) => {
  let config = {
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json',
    },
  }
  const response = await apiInstance.post(
    'subscription/cancel',
    payload,
    config
  )
  return response
}

export const postRenewSubscription = async(payload) => {
  let config = {
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json',
    },
  }
  const response = await apiInstance.post(
    'subscription/resume',
    payload,
    config
  )
  return response
}

export const getCardDetails = async() => {
  let config = {
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json',
    },
  }
  const response = await apiInstance.get('payment/card-details', config)
  return response
}
