import styled from 'styled-components'

const MyDiv = styled.div`
.date_box .MuiInputBase-root{
    height: 40px;
    border-radius: 2px;
}
.stack_clr{
    background-color: #FFFFFF;
}
.place_holder{
    background: #ffffff;
    color: #000000;
    text-transform: capitalize;
    border: 1px solid #d9d9d9;
    width: 100%;
    height: 56px;
}
.place_holder:hover{
    background: #ffffff;
    color: #000000;
    
}
`
export default MyDiv
