import styled from 'styled-components'

const MyDiv = styled.div`
.menu_container{
    position: absolute;
    width:100%;
    z-index: 1111;
    right: 0;
    top: 0;
  }
  .menu_wrapper{
    background-color: rgba(0,0,0,0.9);
    color: #FFFFFF;
    padding: 60px 100px;
    height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
}
.btn-close{
    font-family: Arial;
        font-size: 14px;
        font-weight: 400;
        color: #f0cb47;
        background-color: transparent;
        border: 1px solid #fff;
        border-radius: 5px;
        text-transform: capitalize;
  }
  .btn-close:hover{
        background-color: transparent;
        text-transform: capitalize;
  }
  .btn-green{

    font-family: Arial;
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    background-color: #f0cb47;
    border-radius: 5px;
    text-transform: capitalize;
  }
  .btn-green:hover{
    color: #000000;
    background-color: #f0cb47;
  } 
  .selected_items{
    display:flex;
    align-items:center;
    column-gap: 4px;
  }
  .category_list{
    width: 100%;
    margin: 35px 10px;
  }
  .menu_box{
    padding: 0px;
    margin-bottom:50px;
  }
  .subctegory_items{
    padding: 0px 5px!important;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 8px;
    display: inline-block;
  }
  .subctegory_items:hover {
    color: #FFFFFF;
    background-color: #f0cb47;
  }
  .text_box{
    width: 100%;
  }
  .search input {
    color: #000;
    font-size: 14px;
    padding: 11px 0px 11px 8px;
  }
  .search svg {
    color: #f0cb47;
    margin-right: -7px;
  }
.dialog_close{
}
.search_results{
    font-family: "Proxima Nova";
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    line-height: 16px;
    
}
.mob_box{
    text-transform: capitalize;
    justify-content: start;
    color: #000000;
    display: flex;
    column-gap: 8px;
    align-items: center;
    border-radius: 8px;
    padding: 16px;
    height: 40px;
    width: 150px;
    border: 1px solid #5c5d5e;
    background-color: #ced5dd;
}
.mob_search{
    position: absolute;
    right: 65px;
    top: 1px;
    display: none;
    @media(max-width:767px){
        display: block;
    }
    @media(min-width:768px) and (max-width:1023px){
        display: block;
    }
}
.logo_link{
    display: block;
    @media(max-width:767px){
        display: none;
    }
    @media(min-width:768px) and (max-width:1023px){
        display: none;
    }
}
.search_filter_box{
    position: relative;
    display: flex;
    align-items: center;
    column-gap: 8px;
    position: absolute;
    left: 25%;
    @media(max-width:767px){
        display: none;
    }
    @media(min-width:768px) and (max-width:1023px){
        display: none;
    }
}
.nav_btn{
    height: 40px;
    font-size: 16px;
    font-weight: 400;
    text-transform: capitalize;
    color: #000000!important;
    background-color: #f0cb47!important;
    font-size: 16px;
    font-weight: 500;
    font-family: "Proxima Nova";
    @media(max-width:767px){
        display: none;
    }
    @media(min-width:768px) and (max-width:1023px){
        display: none;
    }
  }
  .nav_btn: hover{
    color: #000000!important;
    background-color: #f0cb47!important;
  }
.ReactFlagsSelect-module_selectBtn__19wW7{
    border: none;
    padding-top: 8px;
}
.ReactFlagsSelect-module_selectBtn__19wW7:after{
    display: none;
}
.flex_box{
    display: flex;
}
.menu_item{
    height: 45px;
}
.btn_flex{
    display: flex;
    coulmn-gap: 10px;
    align-items: center;
    cursor: pointer;
}
.icn_btn{
    display: none;
    @media(max-width:767px){
        display: block;
        color: #000000;
    }
    @media(min-width:768px) and (max-width:1023px){
        display: block;
        color: #000000;
    }
}
.text_logo{
    font-family: "Proxima Nova";
    font-weight: 400;
    font-size: 22px;
    color: #000000;
    @media(max-width:767px){
        color: #FFFFFF;
        font-weight: 500;
    }
    @media(min-width:768px) and (max-width:1023px){
        color: #FFFFFF;
        font-weight: 500;
    }
}
.app_bar{
    background-color: #FFFFFF;
    // height: 72px;
    width: 100%;
    @media(max-width:767px){
        background-color: #FFFFFF;
        height: 60px;
    }
    @media(min-width:768px) and (max-width:1023px){
        background-color: #FFFFFF;
        height: 60px;
    }
}
.tool_bar{
    display: flex;
    justify-content: space-between;
    box-shadow: none;
    position: relative;
}

.app_bar img{
    max-width:175px;
    @media(max-width:767px){
        // max-width: 100%;
        width: 80% !important;
    }
    @media(min-width:768px) and (max-width:1023px){
        // max-width: 100%;
        width: 80% !important;
    }
    
}

.btn_text2{
    font-family: "Arial";
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
    color: #FFFFFF;
    background-color: #f0cb47;
    width: 139px;
    height: 40px;
}
.btn_text2: hover{
    font-family: "Arial";
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
    color: #FFFFFF;
    background-color: #f0cb47;
    width: 139px;
    height: 40px;
}
.btn_gap{
    display: flex;
    column-gap: 10px;
    @media(max-width:767px){
        display: none;
    }
    @media(min-width:768px) and (max-width:1023px){
        display: none;
    }
}
.title_text1{
    font-family: "Arial";
    font-size: 16px;
    font-weight: 400;
}
.title_text2{
    font-family: "Arial";
    font-size: 15px;
    font-weight: 400;
}
.title_text2:hover{
    background-color: #f0cb47;
    border-radius: 3px;
}

.main-menu:hover{
    background-color: transparent;
}
.active_menu:hover{
    background-color: transparent;
}
.main-menu .link_names{
    font-family: "Proxima Nova";
    font-weight: 400;
    font-size: 16px;
    color: #000;
    padding: 2px 10px;
    border-radius:4px;
}
.active_menu .link_names{
    font-family: "Proxima Nova";
    font-size: 16px;
    // background-color: #160647;
    padding: 2px 10px;
    // border-radius:4px;
    color: #160647;
    font-weight: 700;
}
.main-menu:hover .link_names{
    color: #f0cb47;
    background-color: #ffffff; 
}
.nav_btn{
    font-size: 16px;
    font-weight: 400;
    text-transform: capitalize;
    color: #000000;
    background-color: transparent;
}
.nav_btn: hover{
    color: #000000;
    background-color: transparent;
}
.btn-logout{
    background-color: #f0cb47; 
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    font-family: "Proxima Nova";
    text-transform: capitalize;
    border: 1px solid transparent;
}
.btn-logout:hover{
    color: #000000;
    background-color: #f0cb47;
}
.btn_profile{
    background-color: transparent; 
    color: #000000;
    font-size: 16px;
    font-family: "Proxima Nova";
    font-weight: 500;
    text-transform: capitalize;
    border: 1px solid #000000;
}
.btn_profile:hover{
    color: #000000;
    background-color: transparent;
}

.mobile_btn{
    position: relative;
    display: none;
    @media(max-width:767px){
        display: block;
    }
    @media(min-width:768px) and (max-width:1023px){
        display: block;
    }
}
.text_search{
    right: 125px;
    top: 3px;
    position: absolute;
    width: 225px;
    z-index: 1;
}
`
export default MyDiv
