import {CameraAltOutlined, Delete} from '@mui/icons-material'
import {Avatar, Box, Grid, IconButton, Typography} from '@mui/material'
import React, {useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import ReactQuill from 'react-quill'
import {connect, useSelector} from 'react-redux'
import {bindActionCreators} from 'redux'
import {Loader, SnackBar} from '../../components'
import {CustomButton, CustomTextBox} from '../../components/Inputs'
import {toolbarOptions} from '../../constants/constant'
import {ActionCreators} from '../../redux/actions'
import ImageUploadDialog from '../MyProfile/components/ImageUploadDialog/imageUploadDialog'
import MyDiv from './schoolProfile.style'
function SchoolProfile(props) {
  const {t} = useTranslation()
  const imageRef = useRef()
  const [file, setFile] = useState()
  const [imageUploadDialog, setImageUploadDialog] = useState(false)
  const userDataState = useSelector((state) => state.userDataState)
  const userData = useSelector((state) => state.masterState)
  const [inputValue, setInputValue] = useState({})
  const [errorData, setErrorData] = useState({})
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState()
  const [description, setDescription] = useState()


  useEffect(() => {
    props.actions.getDetailsAction()
  }, [])

  useEffect(() => {
    setInputValue({...inputValue,
      school_name: userData?.getDetailsData?.data?.school_detail[0]?.school_name,
      school_image: userData?.getDetailsData?.data?.school_detail[0]?.school_image,
    })
    setDescription(userData?.getDetailsData?.data?.school_detail[0]?.description)
  }, [userData])

  useEffect(() => {
    if (userDataState?.postSchoolProfileData?.data?.status === 'success') {
      setMessage(userDataState?.postSchoolProfileData?.data?.message)
      setStatus('success')
      props.actions.getDetailsAction()
    }
    setErrorData(userDataState?.postSchoolProfileData?.error?.errors)
    setInputValue({...inputValue, school_image: `${process.env.REACT_APP_IMAGE_BASE_URL}${userDataState.postProfilePicture?.data}`})
  }, [userDataState])

  const showOpenFileDialog = () => {
    imageRef.current.click()
  }
  const handleChangeImage = (event) => {
    let fileObject = event.target.files[0]
    setFile(URL.createObjectURL(fileObject))
    if (fileObject) {
      setImageUploadDialog(true)
    }
  }
  const handleImageUploadDialog = () => {
    setImageUploadDialog(!imageUploadDialog)
  }

  const handleChange = (e) => {
    setInputValue({...inputValue, [e.target.name]: e.target.value})
  }
  const handleChange2 = (e) => {
    setDescription(e)
  }

  const handleSubmit = () => {
    let obj = {
      id: userData?.getDetailsData?.data?.school_detail[0]?.school_detail_version_id === undefined ? null : userData?.getDetailsData?.data?.school_detail[0]?.school_detail_version_id,
      user_id: userData?.getDetailsData?.data?.userDetails[0]?.user_id,
      school_name: inputValue?.school_name,
      description: description,
      school_image: inputValue?.school_image?.replace(process.env.REACT_APP_IMAGE_BASE_URL, ''),
    }
    props.actions.postSchoolProfileDataAction(obj)
  }

  const handleRemoveImage = () => {
    setInputValue({...inputValue, school_image: null})
  }

  // handling snackbar close
  const handleSnackBarClose = () => {
    setMessage('')
    setStatus('')
    props.actions.cleanUpUserState()
    props.actions.getDetailsAction()
  }


  return (
    <MyDiv>
      {(userDataState?.postUserProfileData?.loading || userData?.getDetailsData?.loading) &&
        <div>
          <Loader />
        </div>
      }
      <SnackBar
        message={message}
        severity={status}
        open={message?.length > 0}
        close={handleSnackBarClose}
      />
      <Box className="school_box">
        <Typography className="page_title">{t('Schoolprofile')}</Typography>
        <Box className="main_box">
          <Grid container md={6} className="flex_box">
            <Box className="avatar_box">
              <Avatar src={inputValue?.school_image} className="avatar_cam" />
              <IconButton onClick={showOpenFileDialog} className="camera_upload">
                <CameraAltOutlined className="camera_icon" />
              </IconButton>
              <IconButton onClick={handleRemoveImage} className="delete_img">
                <Delete className="delete_icon" />
              </IconButton>
              <input
                ref={imageRef}
                type="file"
                style={{display: 'none'}}
                accept="image/*"
                onChange={handleChangeImage}
              />
            </Box>
            <Grid className="text_box" item md={12}>
              <Typography className="title3_text">{t('Schoolname')}<span> *</span></Typography>
              <CustomTextBox value={inputValue.school_name ?? ''} onChange={handleChange} name="school_name"
                className="text-style" fieldLabel={t('Schoolname')}
              />
              <div style={{color: 'red', fontSize: '12px'}}>{errorData?.school_name?.[0]}</div>
            </Grid>
            <Grid className="text_box" item md={12}>
              <Typography className="title3_text">{t('AboutSchool')}<span> *</span></Typography>
              <ReactQuill value={description} onChange={handleChange2} className="quill_style" theme="snow" modules={{toolbar: toolbarOptions}} />
              <div style={{color: 'red', fontSize: '12px'}}>{errorData?.description?.[0]}</div>
            </Grid>
            <Box>
              <CustomButton onClick={handleSubmit} className="btn_submit" title={t('Submit')} />
            </Box>
          </Grid>
        </Box>
      </Box>
      <ImageUploadDialog
        handleImageUploadDialog={handleImageUploadDialog}
        imageUploadDialog={imageUploadDialog}
        file={file}
      />
    </MyDiv>
  )
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
})

export default connect(null, mapDispatchToProps)(SchoolProfile)
