/* eslint-disable max-len */
import {ArrowDropDown} from '@mui/icons-material'
import {Box, Typography, Menu, Grid, Button} from '@mui/material'
import {styled} from '@mui/system'
import {Autocomplete} from '@react-google-maps/api'
import {t} from 'i18next'
import React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {CustomButton, CustomTextBox, SnackBar} from '../../../../components'
import {ActionCreators} from '../../../../redux/actions'
import MyDiv from './searchDialog.style'

const StyledMenu = styled((props) => (
  <Menu sx={{width: {md: '50%', sm: '95%', xs: '90%'},
    height: '100%',
    position: 'absolute',
    left: {md: '135px', sm: '8px', xs: '8px'},
    top: {md: '65px', sm: '0px', xs: '0px', xl: '0px', lg: '0px'},
    padding: '8px'}} elevation={0} anchorOrigin={{
    vertical: 'bottom',
    horizontal: 'right',
  }} transformOrigin={{
    vertical: 'top',
    horizontal: 'right',
  }} {...props}
  />
))(({theme}) => ({
  '& .MuiPaper-root': {
    'height': '300px',
    'width': '100%',
    'borderRadius': 6,
    'padding': '8px',
    'marginTop': theme.spacing(1),
    'minWidth': 180,
    'color':
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    'boxShadow':
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: '#ffffff',
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: '#ffffff',
      },
    },
  },
}))

const categoryStyle = {
  fontSize: '18px',
  fontWeight: '500',
  fontFamily: 'Proxima Nova',
  background: 'rgb(247, 248, 250)',
  padding: '7px 10px',
  color: '#000000',
}
const subCategoryStyle = {
  fontSize: '14px',
  fontWeight: '600',
  fontFamily: 'Proxima Nova',
  textTransform: 'capitalize',
  color: '#000',
  justifyContent: 'start',
}

const boxStyle = {
  display: 'flex', flexWrap: 'wrap', padding: '16px',
}


const SearchDialog = (props) => {
  const subCategories = props.filteredData?.flatMap((category) => category.admin_sub_category)
  const subCategoryName = subCategories?.filter((item) => item.id === Number(localStorage.getItem('subCat')))[0]?.sub_category_name
  return (
    <MyDiv>
      <Typography className="web_heading">
        {t('webHeading')}
      </Typography>
      <Grid container md={12} xs={12} sm={12} className="main_grid" columnSpacing={{xs: 0, md: 1, sm: 0}} rowSpacing={{xs: 1, md: 0, sm: 1}}>
        <Grid item md={5} xs={12} sm={12}>
          <Autocomplete
            className="auto_complete"
            onPlaceChanged={props.onPlaceChanged}
            onLoad={props.onPlaceLoad}
          >
            <input type="text" placeholder={t('cityAndCountry')} />
          </Autocomplete>
        </Grid>
        <Grid item md={5} xs={12} sm={12}>
          <CustomButton disabled={!props.filteredData?.length > 0} onClick={props.handleClick} className="category_btn" title={subCategoryName || t('SelectCategory')} startIcon={<ArrowDropDown />} />
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              'aria-labelledby': 'demo-customized-button',
            }}
            anchorEl={props.anchorEl}
            open={props.open}
            onClose={props.handleClose}
          >
            <Box>
              <Box sx={{
                position: 'fixed',
                marginTop: {xs: '-4px', sm: '-4px', md: '-70px', lg: '0px', xl: '0px'},
                width: {xs: '85.7%', md: '47.7%', sm: '85.7%'},
                zIndex: 11111,
              }}
              >
                <CustomTextBox
                  onKeyDown={(e) => {
                    e.stopPropagation()
                  }}
                  autoFocus
                  fieldLabel={t('SearchCategory')}
                  value={props.searchTerm}
                  onChange={props.handleSearchCateg}
                />
              </Box>

              <Box sx={{paddingTop: '40px'}}>
                <Grid container columnSpacing={{sm: 4, md: 4}} mt={1}>
                  {props.filteredData?.map((item, index) => {
                    return (
                      <Grid item xs={12} key={index} mb={2}>
                        <Typography style={categoryStyle}>{item.category_name}</Typography>
                        <Box style={boxStyle}>
                          {item?.admin_sub_category?.map((subItem, index) => {
                            return (
                              <Box sx={{width: {xs: '100%', md: '50%', sm: '100%'}}} key={index}>
                                <Button onClick={(e) => props.handleSubCategoryChange(e, subItem.id, subItem.sub_category_name)} style={subCategoryStyle}>{subItem.sub_category_name}</Button>
                              </Box>
                            )
                          })}
                        </Box>
                      </Grid>
                    )
                  })}
                </Grid>
              </Box>
            </Box>
          </StyledMenu>
        </Grid>
        <Grid item md={2} xs={12} sm={12}>
          <CustomButton
            onClick={props.handleWebSearch}
            title={t('search')}
            className="btn-search"
          />
        </Grid>
      </Grid>
      <SnackBar
        message={props.message}
        severity={status}
        open={props.message?.length > 0}
        close={props.handleSnackBarClose}
      />
    </MyDiv>
  )
}


const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
})

export default connect(null, mapDispatchToProps)(SearchDialog)
