import styled from 'styled-components'

const MyDiv = styled.div`

.no_data{
    padding: 100px 350px 100px 300px;
    border-radius: 5px 5px 0px 0px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
    background-color: rgb(255, 255, 255);
    @media(max-width:767px){
        padding: 0px;
        border-radius: 0px;
    box-shadow: rgba(0, 0, 0, 0) 0px;
    background-color: rgb(255, 255, 255);
    }
    @media(min-width:768px) and (max-width:1023px){
        padding: 0px;
        border-radius: 5px 5px 0px 0px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
    background-color: rgb(255, 255, 255);
    }
}
.css-1ex1afd-MuiTableCell-root {
    padding: 5px;
}
.card_data{
    padding:30px;
    @media(max-width:767px){
        padding:0px;
    }
    @media(min-width:768px) and (max-width:1023px){
        padding: 0px 0px 20px 0px;
        margin-top: 20px;
        border-bottom: 1px solid #eee;
    }
}
.category_name{
    font-family: "Arial";
    font-size: 20px;
    font-weight: 400;
    color: #f0cb47;
    @media(max-width:767px){
    font-size: 20px;
    }
}
.category_data{
    font-family: "Arial";
    font-size: 20px;
    font-weight: 600;
    color: #000000;
    @media(max-width:767px){
        font-size: 20px;
        }
}
.flex_wrapper{
    display: flex;
    column-gap: 20px;
    margin-top: 16px;
}
.list_icons svg {
    width: 0.8em;
    height: 0.8em;
    @media(max-width:767px){
        width: 1em;
        height: 1em;
        }
}
.list_icons{
    width: 35px;
    height: 35px;
    background-color: #fff;
    border: 1px solid #f0cb47;
    color: #f0cb47;
    @media(max-width:767px){
    width: 40px;
    height: 40px;
    }
}
.data_label{
    font-family: "Arial";
    font-size: 16px;
    font-weight: 600;
    color: #9998A7;
    @media(max-width:767px){
        color: #000000;
        font-size: 15px;
    }
}
.data_label2{
    font-family: "Arial";
    font-size: 16px;
    font-weight: 600;
    color: #9998A7;
    text-transform: capitalize;
    marginTop: 8px;
    align-items: start;
    padding: 15px 30px;
    @media(max-width:767px){
        color: #f0cb47;
        font-size: 15px;
    }
}
.select_menu{
    height: 40px;
    background-color: #FFFFFF;
    border-radius: 8px;
}
.MuiTabs-indicator {
    background-color:#000000!important;
}
.Mui-selected .span {
    color:#000000!important;
}
.Mui-selected{
    color: #000000!important;
}
.data_name{
    font-family: "Arial";
    font-size: 16px;
    font-weight: 400;
    color: #9998A7;
    // word-break: break-all;
    @media(max-width:767px){
        color: #000000;
        font-size: 15px;
    }
}
`
export default MyDiv
