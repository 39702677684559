/* eslint-disable no-unused-vars */
import {Logout, Menu as MenuIcon, Person, Search} from '@mui/icons-material'
import {AppBar, Box, Button, Divider, Drawer, Grid, IconButton, Link, ListItemButton, MenuItem, Select, Toolbar, Typography} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {bindActionCreators} from 'redux'
import {connect, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {styled} from '@mui/system'
import ReactFlagsSelect from 'react-flags-select'
import {Autocomplete} from '@react-google-maps/api'
import {isMobile} from 'react-device-detect'
import MobSidebar from '../MobSidebar'
import {CustomButton} from '../../../../components/Inputs'
import {getToken, removeToken} from '../../../../utilities/authUtils'
import {ActionCreators} from '../../../../redux/actions'
import logo from '../../../../images/logo.png'
import i18n from '../../../../i18n'
import * as routesNames from '../../../../constants/routes'
import MyDiv from './authTopPanelMain.style'

const ITEM_HEIGHT = 60
const Menus = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5,
    },
  },
}

const CustomMenuItem = styled(MenuItem)(({theme}) => ({
  '&.MuiMenuItem-root': {
    backgroundColor: '#ffffff',
    fontFamily: 'Proxima Nova',
    fontSize: '15px',
    color: '#000000',
    display: 'flex',
    justifyContent: 'space-between',
  },
  '&.MuiMenuItem-root:nth-child(1)': {
    // borderTop: '3px solid #1D3160',
    marginTop: '-8px',
  },
  '&.MuiMenuItem-root:last-child': {
    marginBottom: '-8px',
  },
}))


const languages = [
  {
    id: 1,
    title: 'En',
    value: 'English',
    key: 'EN',

  },
  {
    id: 2,
    title: 'Ελ',
    value: 'Ελληνικά',
    key: 'GK',

  },
]

const AuthTopPanelMain = (props) => {
  const {t} = useTranslation()
  const [sidebar, setSidebar] = useState(false)
  const [selectedFlag, setSelectedFlag] = useState()
  const history = useHistory()
  const [lang, setLang] = useState(localStorage.getItem('lang'))
  const [selected, setSelected] = useState('')
  const isLoggedIn = getToken('token') || getToken('adminToken')
  const getLanguage = localStorage.getItem('lang')
  const location = useLocation()
  const currentRoute = location.pathname
  const masterState = useSelector((state) => state.masterState)

  useEffect(() => {
    props.actions.getSummaryAction()
  }, [])

  useEffect(() => {
    if (masterState?.getDetailsData?.error === 'Unauthorized.') {
      removeToken('token')
      removeToken('adminToken')
      props.actions.logOutUser()
      history.push('/')
    }
  }, [masterState])
  useEffect(() => {
    localStorage.setItem('lang', getLanguage)
    if (getLanguage === 'EN') {
      i18n.changeLanguage('en')
    } else if (getLanguage === 'GK') {
      i18n.changeLanguage('el')
    }
  }, [getLanguage])

  useEffect(() => {
    localStorage.setItem('lang', getLanguage)
    if (getLanguage === 'EN') {
      i18n.changeLanguage('en')
      localStorage.setItem('lang', 'EN')
      setSelectedFlag('GB')
    } else if (getLanguage === 'GK') {
      i18n.changeLanguage('el')
      localStorage.setItem('lang', 'GK')
      setSelectedFlag('GR')
    }
  }, [getLanguage])


  const handleSidebarOpen = () => {
    setSidebar(!sidebar)
  }
  const handleLogout = () => {
    removeToken('token')
    removeToken('adminToken')
    props.actions.logOutUser()
    history.push('/login')
  }

  const handleLink = () => {
    localStorage.removeItem('country')
    localStorage.removeItem('state')
    localStorage.removeItem('city')
    localStorage.removeItem('subCat')
    // props.setCategoryName('')
    props.setCategoryValue(null)
    props.setDistanceValue([])
    props.setFilterProvidedTo({})
    props.setFilterProvidedType({})
    props.setFilterProvidedWhere({})
    props.setFilterDay({})
    props.setFilterSlot({})
    history.push(routesNames.HOME)
  }

  const handleLinkMainDashboardClick = () => {
    history.push(routesNames.HOME)
    localStorage.removeItem('country')
    localStorage.removeItem('state')
    localStorage.removeItem('city')
    localStorage.removeItem('subCat')
  }

  // const handlePopUp = () => {
  //   setPopUp(!popUp)
  //   props.setSearchName('')
  // }

  const handleLang = (e) => {
    const selectedLang = e.target.value
    setLang(selectedLang)
    i18n.changeLanguage(selectedLang)
    localStorage.setItem('lang', selectedLang)
  }


  const handleFlagChange = (lng) => {
    if (lng === 'GB') {
      i18n.changeLanguage('en')
      localStorage.setItem('lang', 'EN')
      setSelectedFlag('GB')
    } else if (lng === 'GR') {
      i18n.changeLanguage('el')
      localStorage.setItem('lang', 'GK')
      setSelectedFlag('GR')
    }
    setSelected(lng)
  }

  const handleMyProfile = () => {
    if (getToken('adminToken')) {
      history.push('/zunpakaloudela')
    } else if (getToken('token')) {
      history.push('/dashboard')
    }
  }

  const onPlaceChanged = () => {
    if (props.searchResult != null) {
      const place = props.searchResult.getPlace()
      props.setLocation(place)
    }
  }

  const onPlaceLoad = (autocomplete) => {
    props.setSearchResult(autocomplete)
  }

  const filteredData = props?.categoryList?.filter((item) => item.admin_sub_category.length > 0)
  const subCategories = filteredData?.flatMap((category) => category.admin_sub_category)
  const subCategoryName = subCategories?.filter((item) => item.id === Number(localStorage.getItem('subCat')))[0]?.sub_category_name


  return (
    <MyDiv>
      <AppBar elevation={0} position="static" className="app_bar">
        <Toolbar className="tool_bar">
          <Box className="btn_flex">
            <IconButton onClick={handleSidebarOpen}><MenuIcon className="icn_btn" /></IconButton>
            <Drawer anchor="left" open={sidebar} onClose={handleSidebarOpen} transitionDuration={500}>
              <MobSidebar handleLink={currentRoute === '/search' ? handleLink : handleLinkMainDashboardClick} onClose={handleSidebarOpen} />
            </Drawer>
            {isMobile && currentRoute === '/search' ? <Box onClick={props.handleButtonClick} component={Button} className="mob_box">
              <Search />
              <Box>
                <Typography className="search_results">location,</Typography>
                <Typography className="search_results">category</Typography>
              </Box>
            </Box> : <Link onClick={currentRoute === '/search' ? handleLink : handleLinkMainDashboardClick}><img src={logo} /></Link>}
          </Box>
          {currentRoute === '/search' ? <Box className="search_filter_box">
            <CustomButton onClick={props.handleMenu} className="nav_btn" title={subCategoryName || t('Categories')} />
            <Box className={currentRoute === '/search' ? 'w-60' : 'w-40'}>
              <Autocomplete
                className="auto_complete"
                onPlaceChanged={onPlaceChanged}
                onLoad={onPlaceLoad}
              >
                <Box>
                  <input type="text" placeholder={t('cityAndCountry')} />
                </Box>
              </Autocomplete>
            </Box>
            <IconButton onClick={(e) => props.handleWebSearch(e, 'webSearch')} className="mob_search"><Search /></IconButton>
            <CustomButton onClick={(e) => props.handleWebSearch(e, 'webSearch')} className="nav_btn" title={t('Submit')} />
          </Box> : null}
          <Box className="btn_gap">
            <Select value={lang} className="menu_item" onChange={handleLang} MenuProps={Menus}>
              {languages.map((item) => {
                return (
                  <CustomMenuItem value={item.key} key={item.id}>
                    {item.title}
                  </CustomMenuItem>
                )
              })}
            </Select>
            {isLoggedIn ? <>
              <CustomButton title={t('Logout')} onClick={handleLogout} className="btn-logout" />
              <CustomButton title={getToken('adminToken') ? t('Dashboard') : t('MyProfile')} onClick={handleMyProfile} className="btn_profile" startIcon={<Person />} />
            </> : <>
              <CustomButton title={props.title5} onClick={() => history.push('/login')} className="btn-logout" />
              <CustomButton title={props.title6} onClick={() => history.push('/signup')} className="btn-logout" />
            </>}
          </Box>
          <Box className="mobile_btn">
            <Box className="flex_box">
              <ReactFlagsSelect
                countries={['GR', 'GB']}
                customLabels={{GR: '', GB: ''}}
                selected={selectedFlag}
                showSelectedLabel={false}
                showOptionLabel={false}
                onSelect={handleFlagChange}
              />
              {isLoggedIn ? <IconButton onClick={handleLogout}><Logout className="icn_btn" /></IconButton> : null}
              <IconButton onClick={isLoggedIn ? () => history.push('/dashboard') : () => history.push('/login')}>
                <Person className="icn_btn" />
              </IconButton>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      {props.openMenu && (
        <div className="menu_container">
          <Box className="menu_wrapper">
            <Box
              sx={{
                display: 'flex',
                columnGap: '20px',
                alignItems: 'center',
                margin: '10px',
              }}
            >
              <CustomButton
                title={t('Close')}
                className="btn-close"
                onClick={props.handleMenu}
              />
              <CustomButton
                onClick={props.handleSubmit}
                title={t('ViewResults')}
                className="btn-green"
              />
              <Box className="selected_items">
                {Object.keys(props.valueListItem).map((value) => {
                  return (
                    <Typography key={value}>
                      {props.valueListItem[value]}
                      { Object.keys(props.valueListItem).indexOf(value) < Object.keys(props.valueListItem).length - 1 ? ',' : null}
                    </Typography>
                  )
                })}
              </Box>
            </Box>
            <Grid container className="category_list">
              {filteredData?.map((item) => {
                return (
                  <Grid
                    item
                    md={2}
                    className="menu_box"
                    key={item.id}
                  >
                    <Typography
                      sx={{
                        fontFamily: 'Arial',
                        fontSize: '18px',
                        fontWeight: '400',
                      }}
                    >
                      {item.category_name}
                    </Typography>
                    <Divider
                      sx={{
                        width: '150px',
                        backgroundColor: '#FFFFFF',
                      }}
                    />
                    <Box mt={2}>
                      {item.admin_sub_category?.map((subItem) => {
                        return (
                          <Box className="" key={subItem.category_id}>
                            <ListItemButton
                              onClick={props.onSelectButton(
                                subItem.sub_category_name,
                                subItem.id
                              )}
                              className="subctegory_items"
                            >
                              {subItem.sub_category_name}
                            </ListItemButton>
                          </Box>
                        )
                      })}
                    </Box>
                  </Grid>
                )
              })}
            </Grid>
          </Box>
        </div>
      )}
    </MyDiv>
  )
}


const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
})

export default connect(null, mapDispatchToProps)(AuthTopPanelMain)
