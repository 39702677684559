/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-template */
import {Box, Checkbox, Chip, Divider, FormControlLabel, Grid, IconButton, ListItemButton, MenuItem, Pagination, Select, Typography} from '@mui/material'
import React, {useEffect, useState} from 'react'
import SearchIcon from '@mui/icons-material/Search'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {Link} from 'react-router-dom'
import {GoogleMap, InfoWindowF, MarkerF, Marker, MarkerClusterer} from '@react-google-maps/api'
import {useTranslation} from 'react-i18next'
import {styled} from '@mui/system'
import {AccountCircle, ArrowBackIos, ArrowForwardIos, LocationOn} from '@mui/icons-material'
import {ActionCreators} from '../../../../redux/actions'
import {CustomButton, CustomTextBox, Loader} from '../../../../components'
import marker from '../../../../images/marker.png'
import school from '../../../../images/school.png'
import teacher from '../../../../images/teacher.png'
import MyDiv from './mapFilter.style'

const Placeholder = styled(MenuItem)(({theme}) => ({
  '&.MuiMenuItem-root': {
    display: 'none',
  },
}))

const OPTIONS = {
  streetViewControl: false,
  styles: [
    {
      featureType: 'poi',
      stylers: [{visibility: 'off'}],
    },
    {
      featureType: 'transit',
      elementType: 'labels.icon',
      stylers: [{visibility: 'off'}],
    },
  ],
  restriction: {
    latLngBounds: {
      north: 85,
      south: -85,
      west: -170,
      east: 170,
    },
    strictBounds: true,
  },
  disableDefaultUI: true,
  clickableIcons: false,

}

const CustomMenuItem = styled(MenuItem)(({theme}) => ({
  '&.MuiMenuItem-root': {
    backgroundColor: '#ffffff',
    fontFamily: 'Proxima Nova',
    fontSize: '14px',
    fontWeight: '600',
    color: '#000000',
  },

  '&.MuiMenuItem-root:nth-child(2)': {
    marginTop: '-8px',
  },
}))

const buttonStyle = {
  backgroundColor: '#f0cb47',
  color: '#000000',
  fontSize: '16px',
  fontWeight: '500',
  fontFamily: 'Proxima Nova',
  textTransform: 'capitalize',
  border: '1px solid transparent',
  height: '32px',
  width: '90%',
}

const boxStyle = {
  textAlign: 'center',
  margin: '8px',
}

const distance = [
  {
    id: 1,
    label: '500m',
    value: 500,
  },
  {
    id: 2,
    label: '1km',
    value: 1000,
  },
  {
    id: 3,
    label: '2km',
    value: 2000,
  },
  {
    id: 4,
    label: '3km',
    value: 3000,
  },
  {
    id: 5,
    label: '4km',
    value: 4000,
  },
  {
    id: 6,
    label: '5km',
    value: 5000,
  },
]

const MapFilter = (props) => {
  const {t} = useTranslation()
  const [data, setData] = useState()

  useEffect(() => {
    setData(props.filteredTeachers?.slice(0, 15))
  }, [props.filteredTeachers])

  useEffect(() => {
    props.actions.getSummaryAction()
  }, [])

  const handleChange = (e, value) => {
    const startIndex = value * 15 - 15
    const lastIndex = value * 15
    setData(props.filteredTeachers?.slice(startIndex, lastIndex))
  }

  const filteredData = props?.categoryList?.filter((item) => item.admin_sub_category.length > 0)

  const [open, setOpen] = useState(false)


  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClusterer = (clusterer) => {
    const markers = clusterer.getMarkers()
    let hasOverlappingMarkers = false
    const epsilon = 0.0001 // Small value to check for closeness

    for (let i = 0; i < markers.length; i++) {
      for (let j = i + 1; j < markers.length; j++) {
        let pos1 = markers[i].getPosition()
        let pos2 = markers[j].getPosition()
        if (Math.abs(pos1.lat() - pos2.lat()) < epsilon && Math.abs(pos1.lng() - pos2.lng()) < epsilon) {
          hasOverlappingMarkers = true
          break
        }
      }
      if (hasOverlappingMarkers) break
    }

    // Adjusting maxZoom based on device type
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    const maxZoomLevel = isMobile ? 18 : 20 // Less zoom on mobile devices

    if (hasOverlappingMarkers) {
      clusterer.setMaxZoom(maxZoomLevel)
    } else {
      clusterer.setMaxZoom(null) // default behavior
    }

    return clusterer
  }


  return (
    <MyDiv>
      {props.masterState?.getTeacherSummary?.loading && (
        <div>
          <Loader />
        </div>
      )}
      <Grid className="main_grid" container>
        <Grid item md={3} className={props.show ? 'd-none' : '-100 overflow'}>
          <Box className="filter_wrapper">
            {/* {props.openMenu && (
              <div className="menu_container">
                <Box className="menu_wrapper">
                  <Box
                    sx={{
                      display: 'flex',
                      columnGap: '20px',
                      alignItems: 'center',
                      margin: '10px',
                    }}
                  >
                    <CustomButton
                      title={t('Close')}
                      className="btn-close"
                      onClick={props.handleMenu}
                    />
                    <CustomButton
                      onClick={props.handleSubmit}
                      title={t('ViewResults')}
                      className="btn-green"
                    />
                    <Box className="selected_items">
                      {Object.keys(props.valueListItem).map((value) => {
                        return (
                          <Typography key={value}>
                            {props.valueListItem[value]}
                            { Object.keys(props.valueListItem).indexOf(value) < Object.keys(props.valueListItem).length - 1 ? ',' : null}
                          </Typography>
                        )
                      })}
                    </Box>
                  </Box>
                  <Grid container className="category_list">
                    {filteredData?.map((item) => {
                      return (
                        <Grid
                          item
                          md={2}
                          className="menu_box"
                          key={item.id}
                        >
                          <Typography
                            sx={{
                              fontFamily: 'Arial',
                              fontSize: '18px',
                              fontWeight: '400',
                            }}
                          >
                            {item.category_name}
                          </Typography>
                          <Divider
                            sx={{
                              width: '150px',
                              backgroundColor: '#FFFFFF',
                            }}
                          />
                          <Box mt={2}>
                            {item.admin_sub_category?.map((subItem) => {
                              return (
                                <Box className="" key={subItem.category_id}>
                                  <ListItemButton
                                    onClick={props.onSelectButton(
                                      subItem.sub_category_name,
                                      subItem.id
                                    )}
                                    className="subctegory_items"
                                  >
                                    {subItem.sub_category_name}
                                  </ListItemButton>
                                </Box>
                              )
                            })}
                          </Box>
                        </Grid>
                      )
                    })}
                  </Grid>
                </Box>
              </div>
            )} */}
            {/* <Box className="text_box">
              <CustomTextBox
                onChange={props.handleSearch}
                value={props.searchName}
                fieldLabel="Search"
                className="search"
                startIcon={<SearchIcon />}
              />
            </Box> */}
          </Box>
          {/* sidebar */}
          <Box className="user_count">
            {props.filteredTeachers?.length === 0 && (
              <Typography className="count">
                {props.filteredTeachers?.length} {t('Results')}
              </Typography>
            )}
            {props.filteredTeachers?.length === 1 && (
              <Typography className="count">
                {props.filteredTeachers?.length} {t('Result')}
              </Typography>
            )}
            {props.filteredTeachers?.length >= 2 && (
              <Typography className="count">
                {props.filteredTeachers?.length} {t('Results')}
              </Typography>
            )}
            <IconButton onClick={props.hideSidebar}>
              <ArrowBackIosNewIcon />
            </IconButton>
          </Box>
          {data?.map((item, index) => {
            return (
              // eslint-disable-next-line prefer-template
              <Box
                className={
                  props.selectedLocation ? 'active_list_users' : 'list_users'
                }
                onClick={() => props.handleClickProfile(item.teacher_id)}
                key={index}
                component={Link}
                target="_blank"
                to={'/teacher/' + item?.name + item?.surname + item?.teacher_id}
              >
                <Typography className="username">
                  {item?.account_type === 2 ? item?.school_name : item?.name + ' ' + item?.surname} {item?.is_doc_verified === 1 ? <Typography className="verified_text">
                    Verified
                  </Typography> : null}
                </Typography>
                <Box className="inner_list">
                  <div className="profile_img">
                    {item?.account_type === 2 ?
                      item?.school_image?.includes('storage/upload') ? <img src={item?.school_image} /> : <Typography className="avatar_cam title_cam" >{item?.school_name?.substring(0, 1).toUpperCase()}</Typography> :
                      item?.profile_image?.includes('storage/upload') ? <img src={item?.profile_image} /> : <Typography className="avatar_cam title_cam" >{item?.name?.substring(0, 1).toUpperCase()}{item?.surname?.substring(0, 1).toUpperCase()}</Typography>}
                  </div>
                  <Box className="list_items">
                    <Box className="list_row">
                      {/* <Typography className="list_label">
                        {t('Category')}:
                      </Typography> */}
                      {item?.sub_category_name === null ? <Typography className="list_data">{t('Nodatayet')}</Typography> : <Typography className="list_data">
                        {item?.sub_category_name}
                      </Typography>}
                    </Box>
                    <Box className="list_row">
                      {/* <Typography className="list_label">
                        {t('Address')}:
                      </Typography> */}
                      <Typography className="list_data">
                        {item?.address}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )
          })}
          <Box className="user_count">
            <Pagination
              count={Math.ceil(props.filteredTeachers?.length / 15)}
              onChange={handleChange}
            />
          </Box>
        </Grid>
        <Grid item md={props.show ? 12 : 9} className="h-100">
          <Box className="map_container">
            <IconButton
              onClick={props.hideSidebar}
              className={!props.show ? 'd-none' : 'expand'}
            >
              <ArrowForwardIosIcon />
            </IconButton>
            {/* // filter box */}
            <Box className="filters_box">
              <Select
                open={props.openTo}
                onClose={(e) => props.handleClose(e, 'to')}
                onOpen={(e) => props.handleOpen(e, 'to')}
                className={Object.values(props.filterProvidedTo).reduce((a, item) => a + item, 0) > 0 ? 'marked_items' : 'menu_items'} multiple value={[0]}
              >
                <Placeholder value={0}>{t('ForAges')}</Placeholder>
                {props?.filterData?.provided_to?.map((item) => {
                  return (
                    <CustomMenuItem
                      key={item.provided_to_id}
                      value={item.provided_to_id}
                    >
                      <FormControlLabel value={item.provided_to_id} name="providedTo" control={<Checkbox onChange={(e) =>
                        props.handleCheckBox(e)
                      } checked={props.filterProvidedTo[item?.provided_to_id]}
                      />} label={
                        <Typography variant="body1" style={{fontSize: '0.9rem'}}>
                          {item?.provided_to}
                        </Typography>
                      }
                      />
                    </CustomMenuItem>
                  )
                })}
                <Divider />
                <Box style={boxStyle}>
                  <CustomButton
                    onClick={(e) => props.handleSubmit(e, 'to')}
                    style={buttonStyle}
                    title={t('Apply')}
                  />
                </Box>
              </Select>
              <Select
                open={props.openSlot}
                onClose={(e) => props.handleClose(e, 'slot')}
                onOpen={(e) => props.handleOpen(e, 'slot')}
                className={Object.values(props.filterSlot).reduce((a, item) => a + item, 0) > 0 ? 'marked_items' : 'menu_items'} multiple value={[0]}
              >
                <Placeholder value={0}>{t('Availability')}</Placeholder>
                {props?.filterData?.lot?.map((item, index) => {
                  return (
                    <CustomMenuItem key={item.lot_id}>
                      <FormControlLabel value={item.lot_id} name="slot" control={<Checkbox onChange={(e) =>
                        props.handleCheckBox(e)
                      } checked={props.filterSlot[item.lot_id]}
                      />} label={
                        <Typography variant="body1" style={{fontSize: '0.9rem'}}>
                          {item.lot}
                        </Typography>
                      }
                      />
                    </CustomMenuItem>
                  )
                })}
                <Divider />
                <Box style={boxStyle}>
                  <CustomButton
                    onClick={(e) => props.handleSubmit(e, 'slot')}
                    style={buttonStyle}
                    title={t('Apply')}
                  />
                </Box>
              </Select>
              <Select
                open={props.openDay}
                onClose={(e) => props.handleClose(e, 'day')}
                onOpen={(e) => props.handleOpen(e, 'day')}
                className={Object.values(props.filterDay).reduce((a, item) => a + item, 0) > 0 ? 'marked_items' : 'menu_items'} multiple value={[0]}
              >
                <Placeholder value={0}>{t('Day')}</Placeholder>
                {props?.filterData?.days.map((item) => {
                  return (
                    <CustomMenuItem key={item?.day_id}>
                      <FormControlLabel value={item?.day_id} name="day" control={<Checkbox onChange={(e) =>
                        props.handleCheckBox(e)
                      } checked={props.filterDay[item?.day_id]}
                      />} label={
                        <Typography variant="body1" style={{fontSize: '0.9rem'}}>
                          {item.day}
                        </Typography>
                      }
                      />
                    </CustomMenuItem>
                  )
                })}
                <Divider />
                <Box style={boxStyle}>
                  <CustomButton
                    onClick={(e) => props.handleSubmit(e, 'day')}
                    style={buttonStyle}
                    title={t('Apply')}
                  />
                </Box>
              </Select>
              <Select
                open={props.openType}
                onClose={(e) => props.handleClose(e, 'type')}
                onOpen={(e) => props.handleOpen(e, 'type')}
                className={Object.values(props.filterProvidedType).reduce((a, item) => a + item, 0) > 0 ? 'marked_items' : 'menu_items'} multiple value={[0]}
              >
                <Placeholder value={0}>{t('Where')}</Placeholder>
                {props?.filterData?.provided_via?.map((item) => {
                  return (
                    <CustomMenuItem
                      key={item.provided_via_id}
                      value={item.provided_via_id}
                    >
                      <FormControlLabel value={item.provided_via_id} name="providedType" control={<Checkbox onChange={(e) =>
                        props.handleCheckBox(e)
                      } checked={props.filterProvidedType[item?.provided_via_id]}
                      />} label={
                        <Typography variant="body1" style={{fontSize: '0.9rem'}}>
                          {item?.provided_via}
                        </Typography>
                      }
                      />
                    </CustomMenuItem>
                  )
                })}
                <Divider />
                <Box style={boxStyle}>
                  <CustomButton
                    onClick={(e) => props.handleSubmit(e, 'type')}
                    style={buttonStyle}
                    title={t('Apply')}
                  />
                </Box>
              </Select>
              <Select
                open={props.openWhere}
                onClose={(e) => props.handleClose(e, 'where')}
                onOpen={(e) => props.handleOpen(e, 'where')}
                className={Object.values(props.filterProvidedWhere).reduce((a, item) => a + item, 0) > 0 ? 'marked_items' : 'menu_items'} multiple value={[0]}
              >
                <Placeholder value={0}>{t('Type')}</Placeholder>
                {props?.filterData?.provided_member_type?.map((item) => {
                  return (
                    <CustomMenuItem
                      key={item.member_type_id}
                      value={item.member_type_id}
                    >
                      <FormControlLabel value={item.member_type_id} name="providedWhere" control={<Checkbox onChange={(e) =>
                        props.handleCheckBox(e)
                      } checked={props.filterProvidedWhere[item?.member_type_id]}
                      />} label={
                        <Typography variant="body1" style={{fontSize: '0.9rem'}}>
                          {item?.member_type}
                        </Typography>
                      }
                      />
                    </CustomMenuItem>
                  )
                })}
                <Divider />
                <Box style={boxStyle}>
                  <CustomButton
                    onClick={(e) => props.handleSubmit(e, 'where')}
                    style={buttonStyle}
                    title={t('Apply')}
                  />
                </Box>
              </Select>
              <Select
                className={props.distanceValue.length > 0 ? 'marked_items' : 'menu_items'} value={[0]} onClose={props.handleSelect} open={props.selectOpen} onOpen={props.handleSelect}
              >
                <Placeholder value={0}>{t('Distance')}</Placeholder>
                {distance?.map((item) => {
                  return (
                    <CustomMenuItem
                      key={item.id}
                      value={item.value}
                    >
                      <FormControlLabel value={item.id}
                        control={<Checkbox
                          checked={props.distanceValue?.includes(item.value)}
                          onChange={(e) => props.handleDistanceCheck(e, item.value)}
                        />} label={
                          <Typography variant="body1" style={{fontSize: '0.9rem'}}>
                            {item?.label}
                          </Typography>
                        }
                      />
                    </CustomMenuItem>
                  )
                })}
                <Divider />
                <Box style={boxStyle}>
                  <CustomButton
                    style={buttonStyle}
                    title={t('Apply')}
                    onClick={props.filterPlacesByDistance}
                  />
                </Box>
                <Divider />
                <Box style={boxStyle}>
                  <CustomButton
                    style={buttonStyle}
                    title={t('Clear')}
                    onClick={props.handleClearDistance}
                  />
                </Box>
              </Select>
              <CustomButton
                style={buttonStyle}
                title={t('Clear')}
                onClick={props.handleClearFilters}
              />
            </Box>
            {/* // filter box */}
            <GoogleMap
              center={props.center}
              onLoad={props.onLoad}
              onBoundsChanged={props.handleMapPosition}
              mapContainerStyle={props.containerStyle}
              onClick={props.handleMarkerPosition}
              zoom={3}
              options={OPTIONS}
            >
              <Marker position={props.marker} icon={marker} />
              <MarkerClusterer gridSize={60}>
                {(clusterer) =>
                  props.filteredTeachers?.map((item, index) => {
                    return (
                      <MarkerF
                        options={{
                          icon: {
                            url: item.account_type === 2 && school || item.account_type === 1 && teacher,
                          },
                        }}
                        onClick={props.handleActiveMarker(item.teacher_id)}
                        key={index}
                        position={{
                          lat: parseFloat(item.latitude),
                          lng: parseFloat(item.longitude),
                        }}
                        clusterer={handleClusterer(clusterer)}
                      >
                        {props.theCurrentTeacher && +props.theCurrentTeacher?.latitude ===
                          +item.latitude &&
                          +props.theCurrentTeacher?.longitude ===
                          +item.longitude ? (
                            <InfoWindowF position={{
                              lat: parseFloat(item.latitude),
                              lng: parseFloat(item.longitude),
                            }} onCloseClick={props.handleInfoWindowClose} options={{
                            }}
                            >
                              <Box className="list_users2" key={index}>
                                <Box className="inner_list">
                                  {props.theCurrentTeacher?.account_type === 2 ?
                                    props.theCurrentTeacher?.school_image?.includes('storage/upload') ? <img src={props.theCurrentTeacher?.school_image} /> : <Typography className="avatar_cam title_cam" >{props.theCurrentTeacher?.school_name?.substring(0, 1).toUpperCase()}</Typography> :
                                    props.theCurrentTeacher?.profile_image?.includes('storage/upload') ? <img src={props.theCurrentTeacher?.profile_image} /> : <Typography className="avatar_cam title_cam" >{props.theCurrentTeacher?.name?.substring(0, 1).toUpperCase()}{props.theCurrentTeacher?.surname?.substring(0, 1).toUpperCase()}</Typography>}
                                  <Box>
                                    <Typography className="username">
                                      {props.theCurrentTeacher?.account_type === 2 ? props.theCurrentTeacher?.school_name : props.theCurrentTeacher?.name + ' ' + props.theCurrentTeacher?.surname}
                                    </Typography>
                                    {item?.is_doc_verified === 1 ? <Typography className="verified_text">
                                      Verified
                                    </Typography> : null}
                                    {props.theCurrentTeacher?.experience_name === null ? <Typography className="list_data">
                                      0 - 1 years of experience
                                    </Typography>
                                      : <Typography className="list_data">
                                        {props.theCurrentTeacher?.experience_name}+ years of experience
                                      </Typography>}
                                  </Box>
                                </Box>
                                <Box className="category_box">
                                  {props.theCurrentTeacher?.sub_category_name === null ?
                                    <Chip
                                      label={t('Nodatayet')}
                                      className="category_items"
                                    /> : <Chip
                                      label={props.theCurrentTeacher?.sub_category_name}
                                      className="category_items"
                                    />}
                                </Box>
                                <Divider />
                                <Box className="flex_box">
                                  <Box className="list_row">
                                    <LocationOn />
                                    <Typography className="list_data">
                                      {props.theCurrentTeacher?.address}
                                    </Typography>
                                  </Box>
                                </Box>
                                <Divider />
                                <Box className="flex_box">
                                  <Link style={{textDecoration: 'none'}} target="_blank"
                                    to={'/teacher/' + props.theCurrentTeacher?.name + props.theCurrentTeacher?.surname + props.theCurrentTeacher?.teacher_id}
                                  >
                                    <Box className="list_row2">
                                      <Box className="flex_child">
                                        <AccountCircle />
                                        <Typography
                                          className="view_btn"
                                        >
                                          {t('ViewProfile')}
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <ArrowForwardIos />
                                      </Box>
                                    </Box>
                                  </Link>

                                </Box>
                                {props.hasNext || props.hasPrevious ?
                                  <Box className="icon_box"><IconButton
                                    className="icn_btn"
                                    variant="text"
                                    disabled={!props.hasPrevious}
                                    onClick={props.handlePreviousClick}
                                    color="primary"
                                  >
                                    <ArrowBackIos />
                                  </IconButton><IconButton
                                    className="icn_btn"
                                    variant="text"
                                    disabled={!props.hasNext}
                                    onClick={props.handleNextClick}
                                    color="primary"
                                  >
                                    <ArrowForwardIos />
                                  </IconButton></Box>
                                  : null}
                              </Box>
                            </InfoWindowF>
                          ) : null}
                      </MarkerF>
                    )
                  })
                }
              </MarkerClusterer>
            </GoogleMap>
          </Box>
        </Grid>
      </Grid>
    </MyDiv>
  )
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
})

export default connect(null, mapDispatchToProps)(MapFilter)
