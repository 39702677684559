/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, {useCallback, useState, useEffect} from 'react'
import {
  Box,
  Typography,
  Switch,
  Divider,
} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {
  GoogleMap,
  MarkerF,
  Autocomplete,
  useLoadScript,
} from '@react-google-maps/api'
import {CustomButton, CustomTextBox} from '../../../../components'
import MyDiv from './locationForms.style'


const containerStyle = {
  width: '100%',
  height: '450px',
}

const LIBRARIES = ['places']

const OPTIONS = {
  streetViewControl: false,
  styles: [
    {
      featureType: 'poi',
      stylers: [{visibility: 'off'}],
    },
    {
      featureType: 'transit',
      elementType: 'labels.icon',
      stylers: [{visibility: 'off'}],
    },
  ],
  restriction: {
    latLngBounds: {
      north: 85,
      south: -85,
      west: -170,
      east: 170,
    },
    strictBounds: true,
  },
}

export default function LocationForms(props) {
  const {t} = useTranslation()
  const [location, setLocation] = useState()
  const [country, setCountry] = useState()
  const [city, setCity] = useState()
  const [state, setState] = useState()
  const [searchResult, setSearchResult] = useState('Result: none')
  const [map, setMap] = useState(null) // set the map state to get the bounds of the map
  const [center, setCenter] = useState({lat: 35.1351148, lng: 32.9527879})

  const {isLoaded} = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY,
    libraries: LIBRARIES,
  })
  const onLoad = useCallback((map) => {
    setMap(map)
  }, [])

  useEffect(() => {
    if (props.marker) {
      setCenter({...props.marker})
    }
  }, [])


  const zoom = 12

  const onPlaceChanged = () => {
    if (searchResult != null) {
      const place = searchResult.getPlace()
      setCenter({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      })
      // const myPlace = place[0];
      const bounds = new window.google.maps.LatLngBounds()

      if (place.geometry.viewport) {
        bounds.union(place.geometry.viewport)
      } else {
        bounds.extend(place.geometry.location)
      }

      const polyCoordinates = [
        {lat: bounds.getSouthWest().lat(), lng: bounds.getSouthWest().lng()},
        {lat: bounds.getNorthEast().lat(), lng: bounds.getSouthWest().lng()},
        {lat: bounds.getNorthEast().lat(), lng: bounds.getNorthEast().lng()},
        {lat: bounds.getSouthWest().lat(), lng: bounds.getNorthEast().lng()},
      ]
      // setPolygonCoordinates(polyCoordinates)
      for (let i = 0; i < polyCoordinates.length; i++) {
        bounds.extend(polyCoordinates[i])

      }

      map.fitBounds(bounds)
    }

  }

  const onPlaceLoad = (autocomplete) => {
    setSearchResult(autocomplete)
  }

  if (!isLoaded) return

  return (
    <MyDiv>
      <Box className="sm_box">
        {/* <Box className="switch_box">
          <Switch onChange={props.handleChangeSwitch} />
          <Typography>{t('checkbox')}</Typography>
        </Box> */}
        {props.showContent ? (
          <Box className="data_box">
            <Box>
              <Typography className="media_url">{t('fullAddress')}<span>*</span></Typography>
              <CustomTextBox
                name="address"
                value={props.locationData?.address}
                onChange={props.handleChange}
                className="text-style"
              />
              <div style={{color: 'red', fontSize: '12px'}}>{props.errorData?.address?.[0]}</div>
              <Typography mt={1} mb={1} className="title_text">{t('LocationonGoogleMaps')}</Typography>
              <Divider style={{background: '#000000'}} />
              <Box mt={2}>
                <Typography mt={1} className="media_url">{t('locationSearch')}</Typography>
                <Autocomplete
                  className="auto_complete"
                  onPlaceChanged={onPlaceChanged}
                  onLoad={onPlaceLoad}
                >
                  <input
                    type="text"
                    placeholder={t('search')}
                  />
                </Autocomplete>
              </Box>
              <Typography className="media_url">{t('urlgoogle')}</Typography>
              <CustomTextBox
                name="google_map_url"
                value={props.locationData?.google_map_url}
                onChange={props.handleChange}
                className="text-style"
                fieldLabel={t('Hyperlink')}
              />
              <Typography className="map_text">{t('markertext')}</Typography>
              <GoogleMap
                center={center}
                onLoad={onLoad}
                onClick={props.handleMarkerPosition}
                mapContainerStyle={containerStyle}
                options={OPTIONS}
                zoom={zoom}
              >
                <MarkerF position={props.marker} />
              </GoogleMap>
            </Box>
          </Box>
        ) : (
          null
        )}

        <Box className="flex_btn">
          <CustomButton
            onClick={props.handleSubmit}
            className="btn_submit"
            title={t('Submit')}
          />
          <CustomButton
            onClick={props.handleClick}
            className="btn_cancel"
            title={t('Cancel')}
          />
        </Box>
      </Box>
    </MyDiv>
  )
}
