import styled from 'styled-components'

const MyDiv = styled.div`
.box_style{
    width: 100%;
    height: 100%;
    background-color: #F1F5FC;
    text-align: center;
    padding: 35px 25px;
}
.title_text{
    font-family: "Arial";
    font-size: 24px;
    font-weight: 400;
    color: #000000;
}
`
export default MyDiv
