import React, {useState, useEffect} from 'react'
import ReactQuill from 'react-quill'
import {Box, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {connect, useSelector} from 'react-redux'
import {bindActionCreators} from 'redux'
import {isMobile} from 'react-device-detect'
import {CustomButton, SnackBar, Loader} from '../../components'
import {ActionCreators} from '../../redux/actions'
import {toolbarOptions} from '../../constants/constant'
import MyDiv from './aboutMe.style'
import 'react-quill/dist/quill.bubble.css'

const AboutMe = (props) => {

  const {t} = useTranslation()
  const textdata = t('textdatanew')

  const [aboutMe, setAboutMe] = useState({})
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('')
  const [messageForPublish, setMessageForPublish] = useState('')

  const userGet = useSelector((state) => state.masterState)
  const userPost = useSelector((state) => state.userDataState)

  useEffect(() => {
    if (userGet?.getDetailsData?.data === null) {
      props.actions.getDetailsAction()
    }
  }, [])

  useEffect(() => {
    setAboutMe(userGet?.getDetailsData?.data?.userAboutMe[0]?.about_me)
  }, [userGet?.getDetailsData?.data])

  // post state
  useEffect(() => {
    if (userPost?.postAboutMe?.data?.status === 'success') {
      setMessageForPublish(t('messageForPublish'))
      // localStorage.setItem('messageForPublish', t('messageForPublish'))
      setMessage(userPost?.postAboutMe?.data?.message)
      setStatus('success')
      setAboutMe({})
    } else {
      setMessage(userPost?.postAboutMe?.data?.message)
      setStatus('error')
    }
  }, [userPost?.postAboutMe?.data?.status])


  const handleChange = (e) => {
    setAboutMe(e)
  }

  const handleSubmit = () => {
    if (aboutMe?.length > 2500) {
      setMessage(t('aboutMevalid'))
      setStatus('error')
    } else if (aboutMe?.length <= 2500) {
      props.actions.postAboutMeAction({description: aboutMe})
    }
  }

  const handleSnackBarClose = () => {
    setMessageForPublish('')
    setMessage('')
    setStatus('')
    props.actions.cleanUpUserState()
  }


  const msg = t('messageForPublish')
  const publishFlag = localStorage.getItem('flag')
  return (
    <MyDiv>
      {(userPost?.postAboutMe?.loading || userGet?.getDetailsData?.loading) &&
      <div>
        <Loader />
      </div>
      }
      <SnackBar
        message={message}
        severity={status}
        open={message?.length > 0}
        close={handleSnackBarClose}
      />
      <Box className="aboutme_box">
        {(isMobile && publishFlag) && <>{messageForPublish ?
          <Typography className="publish_msg">
            {messageForPublish}
          </Typography> : msg ? <Typography className="publish_msg">
            {msg}
          </Typography> : null
        }</>}
        <Typography className="page_title">{t('AboutMe')}</Typography>
        <Box>
          <Typography className="info_text">{textdata}</Typography>
          <ReactQuill value={aboutMe} onChange={handleChange} className="quill_style" theme="snow" modules={{toolbar: toolbarOptions}} />
        </Box>
        <Box mt={17}>
          <CustomButton onClick={handleSubmit} className="btn_submit" title={t('Submit')} />
        </Box>
      </Box>
    </MyDiv>
  )
}


const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
})

export default connect(null, mapDispatchToProps)(AboutMe)
