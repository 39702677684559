import {Typography, Box} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import NoDataCard from '../../../../components/NoDataCard/noDataCard'
import MyDiv from './skillsInterests.style'
export default function SkillsInterests(props) {
  const {t} = useTranslation()
  // const textHandle = (text) => {
  //   const regex = /(<([^>]+)>)/ig
  //   return text?.replace(regex, '')
  // }
  return (
    <MyDiv>

      <Box className="main_box">
        <Box className="card_box">
          <Typography className="heading_name">{t('Skills&Interests')}</Typography>
          {props.teachersData?.data?.userSkillInterest.length === 0 ? <NoDataCard /> : <>
            {props.teachersData?.data?.userSkillInterest?.map((val, index) => {
              return (
                <Box className="card_data" key={index}>
                  <Typography className="title_dark">{val?.skill_interest_name}</Typography>
                  {val.description !== null ? <Box className="desc_box">
                    <Typography className="desc_name">{t('Description')}</Typography>
                    <Typography className="title_light">
                      <div dangerouslySetInnerHTML={{__html: val.description}} />
                    </Typography>
                  </Box> : null}
                </Box>
              )
            })}
          </>}
        </Box>
      </Box>
    </MyDiv>
  )
}
