/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react'
import {connect, useSelector} from 'react-redux'
import {useHistory, useLocation} from 'react-router-dom' // Assuming you're using React Router
import {bindActionCreators} from 'redux'
import {ActionCreators} from '../../redux/actions'
import MyDiv from './upgradeSuccess.style'

function UpgradeSuccess(props) {
  const history = useHistory()
  const [redirectTime, setRedirectTime] = useState(5)
  const redirectUrl = '/paymentdetails'
  const paymentState = useSelector((state) => state.paymentState)
  useEffect(() => {
    if (paymentState?.postConfirmUpgrade?.data?.meta?.status === 'success') {
      const intervalId = setInterval(() => {
        setRedirectTime((prevTime) => prevTime - 1)
      }, 1000)

      if (redirectTime === 0) {
        history.push(redirectUrl)
      }

      return () => {
        clearInterval(intervalId)
      }
    }
  }, [paymentState, history, redirectTime, redirectUrl])

  const url = useLocation()
  const sessionId = url?.search?.split('=').pop()
  useEffect(() => {
    if (sessionId) {
      props.actions.postConfirmUpgradeAction(sessionId)
    }
  }, [sessionId])
  return (
    <MyDiv>
      <div className="App">
        <header className="App-header">
          <h1>Upgrade Payment Success</h1>
        </header>
        <div className="App-content">
          <p>Thank you for your payment. Your transaction was successful.</p>
          <p>An email receipt has been sent to your registered email address.</p>
          <div className="button-container">
            <a href="/">Return to Home</a>
            <a href="/paymentdetails">Go to the billing details</a>
          </div>
          <p>Redirecting to the Billing details page in {redirectTime} seconds.</p>
        </div>
      </div>
    </MyDiv>
  )
}
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
})

export default connect(null, mapDispatchToProps)(UpgradeSuccess)
