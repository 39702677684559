import styled from 'styled-components'

const MyDiv = styled.div`
.App {
  text-align: center;
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
  margin: 100px;
  padding: 100px;
}

.header {
  background-color: #4CAF50;
  color: #fff;
  padding: 10px;
}

.header h1 {
  margin: 0;
}

.content {
  max-width: 600px;
  margin: 100px auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.success-icon {
  font-size: 64px;
  color: #4CAF50;
  margin: 20px 0;
}

.content p {
  font-size: 18px;
  margin: 10px 0;
}

.button-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  column-gap: 8px;
}

.button-container a {
  text-decoration: none;
  background-color: #f0cb47;
  color: #fff;
  padding: 10px 20px;
 

`
export default MyDiv
