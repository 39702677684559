export const GET_COVERIMAGE_REQUEST = 'GET_COVERIMAGE_REQUEST'
export const GET_COVERIMAGE_FAILURE = 'GET_COVERIMAGE_FAILURE'
export const GET_COVERIMAGE_SUCCESS = 'GET_COVERIMAGE_SUCCESS'
export const POST_COVERIMAGE_REQUEST = 'POST_COVERIMAGE_REQUEST'
export const POST_COVERIMAGE_FAILURE = 'POST_COVERIMAGE_FAILURE'
export const POST_COVERIMAGE_SUCCESS = 'POST_COVERIMAGE_SUCCESS'

export const GET_IMAGEGALLERY_REQUEST = 'GET_IMAGEGALLERY_REQUEST'
export const GET_IMAGEGALLERY_FAILURE = 'GET_IMAGEGALLERY_FAILURE'
export const GET_IMAGEGALLERY_SUCCESS = 'GET_IMAGEGALLERY_SUCCESS'
export const POST_IMAGEGALLERY_REQUEST = 'POST_IMAGEGALLERY_REQUEST'
export const POST_IMAGEGALLERY_FAILURE = 'POST_IMAGEGALLERY_FAILURE'
export const POST_IMAGEGALLERY_SUCCESS = 'POST_IMAGEGALLERY_SUCCESS'

export const GET_VIDEOLINK_REQUEST = 'GET_VIDEOLINK_REQUEST'
export const GET_VIDEOLINK_FAILURE = 'GET_VIDEOLINK_FAILURE'
export const GET_VIDEOLINK_SUCCESS = 'GET_VIDEOLINK_SUCCESS'
export const POST_VIDEOLINK_REQUEST = 'POST_VIDEOLINK_REQUEST'
export const POST_VIDEOLINK_FAILURE = 'POST_VIDEOLINK_FAILURE'
export const POST_VIDEOLINK_SUCCESS = 'POST_VIDEOLINK_SUCCESS'

