import styled from 'styled-components'

const MyDiv = styled.div`
.userName{
    font-family: "Arial";
    font-size: 26px;
    font-weight: 400;
    color: #1D3160;
}
.pending_label{
    margin-top: 8px;
    font-family: "Arial";
    font-size: 15px;
    padding: 8px 22px;
    border-radius: 4px;
    line-height: 26px;
    font-weight: 400;
    color: #fff;
    background-color: #F50057;
    width: 150px;
}
.user-info{
    margin-top:24px;
}
.user_location{
    font-family: "Arial";
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}
.wrap-flex{
    display: flex;
    column-gap: 10px;
    margin-top: 8px;
}
.labelName{
    font-family: "Arial";
    font-size: 14px;
    font-weight: 400;
    color: #f0cb47;
}
.labelData{
    font-family: "Arial";
    font-size: 14px;
    font-weight: 400;
    color: #9998A7;
    // word-break: break-all;
}
.about_box{
    margin-top: 40px;
}
.card_heading{
    font-family: "Arial";
    font-size: 18px;
    font-weight: 400;
    color: #000000;
    padding: 15px 30px;
    background-color: #EAECEF; 
    border-radius: 5px 5px 0px 0px;
}
.grid_space{
    margin-top:15px;
}
.box-space{
    margin-bottom: 20px;
}
.left_space{
    width: 25%;
}
.profile_img{
    width: 80px;
    height: 80px;
    object-fit:cover;
    border-radius: 50%;
    border: 1px solid transparent;
}
.right_space{
    margin-left: 8px;
}
.inner-wrap-flex{
    display: flex;
    column-gap: 4px;
    align-items: center;
}
.info-space{
    padding: 0px 0px 0px 18px;
}
.info-icons{
    width: 53px;
    height: 53px;
    background-color: #fff;
    border: 1px solid #f0cb47;
}
.info-icons svg{
    color: #f0cb47;
}
.sm_icn{
    width: 28px;
}
.label_space{
    margin-bottom:15px;
}
.lang-space{
    margin-bottom:15px;
}
.labelNameBold{
    font-family: "Arial";
    font-size: 16px;
    font-weight: 600;
    color: #9998A7;
}
.category_box{
    padding: 0px 18px;
}
.categoryName{
    font-family: "Arial";
    font-size: 30px;
    font-weight: 400;
    color: #f0cb47;
}
.categoryData{
    font-family: "Arial";
    font-size: 30px;
    font-weight: 400;
    color: #9998A7;
}
.lesson_about{
    padding: 0px 30px;
}
.education_box{
    border: 1px solid #C4D2DF;
}
.education_data{
    padding: 30px;
    border-bottom: 1px solid #C4D2DF;
}
.education_data:last-child{
    border-bottom: 0;
}
.desc_space{
    padding: 0px 20px;
}
.underline_text{
    text-decoration: underline;
}
.image_wrapper{
    width: 100%;
}
.image_list{
    margin: 0;
    background: #ffffff;
    padding: 8px 8px 0px;
}
.existing_img{
    border: 1px solid transparent;
}
`
export default MyDiv
