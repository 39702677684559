export const LIST_GET_REQUEST = 'LIST_GET_REQUEST'
export const LIST_GET_FAILURE = 'LIST_GET_FAILURE'
export const LIST_GET_SUCCESS = 'LIST_GET_SUCCESS'

export const DELETE_POST_REQUEST = 'DELETE_POST_REQUEST'
export const DELETE_POST_FAILURE = 'DELETE_POST_FAILURE'
export const DELETE_POST_SUCCESS = 'DELETE_POST_SUCCESS'

export const MESSAGE_POST_REQUEST = 'MESSAGE_POST_REQUEST'
export const MESSAGE_POST_FAILURE = 'MESSAGE_POST_FAILURE'
export const MESSAGE_POST_SUCCESS = 'MESSAGE_POST_SUCCESS'
export const MESSAGE_GET_REQUEST = 'MESSAGE_GET_REQUEST'
export const MESSAGE_GET_FAILURE = 'MESSAGE_GET_FAILURE'
export const MESSAGE_GET_SUCCESS = 'MESSAGE_GET_SUCCESS'

export const MESSAGE_CLEAN_REQUEST = 'MESSAGE_CLEAN_REQUEST'
export const MESSAGE_CLEAN_FAILURE = 'MESSAGE_CLEAN_FAILURE'
export const MESSAGE_CLEAN_SUCCESS = 'MESSAGE_CLEAN_SUCCESS'

export const ADD_PACKAGE_REQUEST = 'ADD_PACKAGE_REQUEST'
export const ADD_PACKAGE_FAILURE = 'ADD_PACKAGE_FAILURE'
export const ADD_PACKAGE_SUCCESS = 'ADD_PACKAGE_SUCCESS'

export const POST_PACKAGE_REQUEST = 'POST_PACKAGE_REQUEST'
export const POST_PACKAGE_FAILURE = 'POST_PACKAGE_FAILURE'
export const POST_PACKAGE_SUCCESS = 'POST_PACKAGE_SUCCESS'

export const DELETE_PACKAGE_REQUEST = 'DELETE_PACKAGE_REQUEST'
export const DELETE_PACKAGE_FAILURE = 'DELETE_PACKAGE_FAILURE'
export const DELETE_PACKAGE_SUCCESS = 'DELETE_PACKAGE_SUCCESS'

export const GET_USERS_REQUEST = 'GET_USERS_REQUEST'
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE'
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'

export const UPDATE_ORDER_REQUEST = 'UPDATE_ORDER_REQUEST'
export const UPDATE_ORDER_FAILURE = 'UPDATE_ORDER_FAILURE'
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS'


export const GET_STATIC_DATA_REQUEST = 'GET_STATIC_DATA_REQUEST'
export const GET_STATIC_DATA_FAILURE = 'GET_STATIC_DATA_FAILURE'
export const GET_STATIC_DATA_SUCCESS = 'GET_STATIC_DATA_SUCCESS'

export const GET_SALES_REQUEST = 'GET_SALES_REQUEST'
export const GET_SALES_FAILURE = 'GET_SALES_FAILURE'
export const GET_SALES_SUCCESS = 'GET_SALES_SUCCESS'


export const GET_CARD_DETAILS_PDF_REQUEST = 'GET_CARD_DETAILS_PDF_REQUEST'
export const GET_CARD_DETAILS_PDF_FAILURE = 'GET_CARD_DETAILS_PDF_FAILURE'
export const GET_CARD_DETAILS_PDF_SUCCESS = 'GET_CARD_DETAILS_PDF_SUCCESS'


export const POST_REPORT_REQUEST = 'POST_REPORT_REQUEST'
export const POST_REPORT_FAILURE = 'POST_REPORT_FAILURE'
export const POST_REPORT_SUCCESS = 'POST_REPORT_SUCCESS'


export const DISABLE_ENABLE_SUBCATEGORY_REQUEST = 'DISABLE_ENABLE_SUBCATEGORY_REQUEST'
export const DISABLE_ENABLE_SUBCATEGORY_FAILURE = 'DISABLE_ENABLE_SUBCATEGORY_FAILURE'
export const DISABLE_ENABLE_SUBCATEGORY_SUCCESS = 'DISABLE_ENABLE_SUBCATEGORY_SUCCESS'
