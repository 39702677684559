export const POST_USER_REQUEST = 'POST_USER_REQUEST'
export const POST_USER_FAILURE = 'POST_USER_FAILURE'
export const POST_USER_SUCCESS = 'POST_USER_SUCCESS'

export const POST_ABOUTME_REQUEST = 'POST_ABOUTME_REQUEST'
export const POST_ABOUTME_SUCCESS = 'POST_ABOUTME_SUCCESS'
export const POST_ABOUTME_FAILURE = 'POST_ABOUTME_FAILURE'

export const POST_PROFILEPICTURE_REQUEST = 'POST_PROFILEPICTURE_REQUEST'
export const POST_PROFILEPICTURE_FAILURE = 'POST_PROFILEPICTURE_FAILURE'
export const POST_PROFILEPICTURE_SUCCESS = 'POST_PROFILEPICTURE_SUCCESS'

export const POST_SCHOOL_PROFILE_REQUEST = 'POST_SCHOOL_PROFILE_REQUEST'
export const POST_SCHOOL_PROFILE_FAILURE = 'POST_SCHOOL_PROFILE_FAILURE'
export const POST_SCHOOL_PROFILE_SUCCESS = 'POST_SCHOOL_PROFILE_SUCCESS'
