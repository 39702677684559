/* eslint-disable no-use-before-define */
/* eslint-disable no-constant-condition */
/* eslint-disable no-unused-vars */
import {Box, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {connect, useSelector} from 'react-redux'
import {bindActionCreators} from 'redux'
import {useJsApiLoader} from '@react-google-maps/api'
import {useHistory, useLocation} from 'react-router-dom'
import axios from 'axios'
import {CustomButton, Loader, SnackBar} from '../../components'
import {ActionCreators} from '../../redux/actions'
import AuthTopPanelMain from '../MainDashboard/components/AuthTopPanelMain/authTopPanelMain'
import {getToken} from '../../utilities/authUtils'
import {SearchDialog} from './components'
import MyDiv from './webSearch.style'
const consentStyle = {
  background: '#2B373B',
  fontFamily: 'Arial',
  fontWeight: '400',
  fontSize: '16px',
}

const consentButtonStyle = {
  background: '#f0cb47',
  fontFamily: 'Arial',
  fontWeight: '400',
  fontSize: '16px',
  color: '#ffffff',
}
const LIBRARIES = ['places']
const WebSearch = (props) => {
  const [teacherCardList, setTeacherCardList] = useState()
  const [newRegistrations, setNewRegistrations] = useState()
  const masterState = useSelector((state) => state.masterState)
  const {t} = useTranslation()

  const history = useHistory()
  const [categoryList, setCategoryList] = useState()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredCategories, setFilteredCategories] = useState()
  const [categoryValue, setCategoryValue] = useState('')
  // const [categoryName, setCategoryName] = useState('')
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('')
  const [searchResult, setSearchResult] = useState('Result: none')
  const [location, setLocation] = useState()
  const [country, setCountry] = useState()
  const [city, setCity] = useState()
  const [state, setState] = useState()
  const isLoggedInAdmin = getToken('adminToken') || getToken('token')
  const categoryState = useSelector((state) => state.categoryState)
  const loc = useLocation()

  useEffect(() => {
    const handlePageRefresh = () => {
      localStorage.removeItem('subCat')
      localStorage.removeItem('country')
      localStorage.removeItem('city')
      localStorage.removeItem('state')
    }
    window.addEventListener('beforeunload', handlePageRefresh)
    return () => {
      window.removeEventListener('beforeunload', handlePageRefresh)
    }
  }, [])

  useEffect(() => {
    if (document.location.href === `${process.env.REACT_APP_REDIRECT_URL}/`) {
      localStorage.removeItem('subCat')
      localStorage.removeItem('country')
      localStorage.removeItem('city')
      localStorage.removeItem('state')
    }
  }, [])


  useEffect(() => {
    setFilteredCategories(categoryState?.getCategorySubCategory?.data)
    setCategoryList(categoryState?.getCategorySubCategory?.data)
  }, [categoryState?.getCategorySubCategory?.data])

  useEffect(() => {
    if (loc.pathname !== '/') {
      if (masterState?.getTeacherSummary?.data?.length === 0) {
        setMessage(t('noTeachers'))
        setStatus('error')
      } else if (masterState?.getTeacherSummary?.data?.length > 0) {
        // setCategoryName('')
      }
    }
  }, [masterState?.getTeacherSummary?.data, loc.pathname])

  useEffect(() => {
    if (location?.types?.[0] === 'country') {
      setCountry(location?.formatted_address)
      setState('')
      setCity('')
      // localStorage.setItem('country', location?.formatted_address)
      // localStorage.setItem('state', '')
      // localStorage.setItem('city', '')
    } else if (location?.types?.[0] === 'locality' && location.formatted_address.split(',').length === 2) {
      const locality = location?.address_components.filter(
        (i) => i.types[0] === 'locality'
      )[0].long_name
      setState(locality)
      setCountry('')
      setCity('')
      // localStorage.setItem('state', locality)
      // localStorage.setItem('country', '')
      // localStorage.setItem('city', '')
    } else if (location?.types?.[0] === 'administrative_area_level_1') {
      const state = location?.address_components.filter(
        (i) => i.types[0] === 'administrative_area_level_1'
      )[0].long_name
      setState(state)
      setCountry('')
      setCity('')
      // localStorage.setItem('state', state)
      // localStorage.setItem('country', '')
      // localStorage.setItem('city', '')
    } else if (location?.types?.[0] === 'locality') {
      const city = location?.address_components.filter(
        (i) => i.types[0] === 'locality'
      )[0].long_name
      setCity(city)
      setState('')
      setCountry('')
      // localStorage.setItem('city', city)
      // localStorage.setItem('state', '')
      // localStorage.setItem('country', '')
    } else if (location?.formatted_address?.split(',').length > 3) {
      setMessage(t('locValid'))
      setStatus('error')
    } else if (location !== undefined) {
      if (location?.types?.[0] !== 'country' || location?.types?.[0] !== 'locality' || location?.types?.[0] !== 'administrative_area_level_1') {
        setMessage(t('wrongLocationValidation'))
        setStatus('error')
      }
    }
  }, [location])

  useEffect(() => {
    props.actions.getTeacherAction()
    props.actions.getListAction()
    props.actions.getTeachersCardListAction()
    if (country || state || city) {
      props.actions.getCategoryAction('search', country, state, city)
    }
    props.actions.getRegisteredTeachersListAction()
  }, [country, state, city])

  useEffect(() => {
    if (localStorage.getItem('lang') === 'EN' || 'GK') {
      if (country || state || city) {
        props.actions.getCategoryAction('search', country, state, city)
      }
      props.actions.getTeachersCardListAction()
    }
  }, [localStorage.getItem('lang'), country, state, city])

  useEffect(() => {
    setNewRegistrations(masterState?.getRegisteredTeachersList?.data?.data?.payload)
    setTeacherCardList(masterState?.getTeachersCardList?.data?.data?.payload)
  }, [masterState?.getRegisteredTeachersList, masterState?.getTeachersCardList])

  useEffect(() => {
    if (country || state || city) {
      getTranslatedData()
    }
  }, [country, state, city])

  const getTranslatedData = async() => {
    let res = await axios.post(
      `https://translation.googleapis.com/language/translate/v2?key=${process.env.REACT_APP_TRANSLATION_API_KEY}`,
      // {q: 'Κύπρος', target: 'en'}
      {q: country || city || state, target: 'en'}
    )
    if (country) {
      setCountry(res.data.data.translations[0]?.translatedText)
      localStorage.setItem('country', res.data.data.translations[0]?.translatedText)
      localStorage.setItem('state', '')
      localStorage.setItem('city', '')
    }
    if (city) {
      setCity(res.data.data.translations[0]?.translatedText)
      localStorage.setItem('country', '')
      localStorage.setItem('state', '')
      localStorage.setItem('city', res.data.data.translations[0]?.translatedText)
    }
    if (state) {
      setState(res.data.data.translations[0]?.translatedText)
      localStorage.setItem('country', '')
      localStorage.setItem('state', res.data.data.translations[0]?.translatedText)
      localStorage.setItem('city', '')
    }
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
    setSearchTerm('')
    setFilteredCategories(categoryState?.getCategorySubCategory?.data)
  }

  const searchSubCategories = (searchTerm) => {
    const normalizedSearchTerm = searchTerm.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    const results = categoryList.map((category) => {
      const filteredSubCategories = category.admin_sub_category?.filter((subCategory) => {
        const normalizedSubCategoryName = subCategory.sub_category_name?.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        return normalizedSubCategoryName.toLowerCase().includes(normalizedSearchTerm.toLowerCase())
      })
      return {...category, admin_sub_category: filteredSubCategories}
    })
    return results
  }

  const handleSearchCateg = (e) => {
    setSearchTerm(e.target.value)
    const results = searchSubCategories(e.target.value)
    setFilteredCategories(results)
  }


  const filteredData = filteredCategories?.filter((item) => item.admin_sub_category.length > 0)

  const handleSubCategoryChange = (event, val, valName) => {
    handleClose()
    setCategoryValue(event.target.value || val)
    // setCategoryName(valName)
    localStorage.setItem('subCat', event.target.value || val)
  }


  // autocomplete
  const onPlaceChanged = () => {
    if (searchResult != null) {
      const place = searchResult.getPlace()
      setLocation(place)
    }
  }
  const onPlaceLoad = (autocomplete) => {
    setSearchResult(autocomplete)
  }

  // for web search/filters
  const handleWebSearch = (e, type) => {
    const myCountry = localStorage.getItem('country')
    const myCity = localStorage.getItem('city')
    const myState = localStorage.getItem('state')
    if ((myCountry || myCity || myState) && categoryValue) {
      // let payload = `subCategory=${categoryValue}&country=${country === undefined ? '' : country}&state=${state === undefined ? '' : state}&city=${city === undefined ? '' : city}`
      let payload = `subCategory=${categoryValue}&country=${myCountry === undefined ? '' : myCountry}&state=${myState === undefined ? '' : myState}&city=${myCity === undefined ? '' : myCity}`
      props.actions.getSummaryAction(payload)
      history.push('/search')
    } else {
      setMessage(t('bothFields'))
      setStatus('error')
    }
  }

  const handleSnackBarClose = () => {
    setMessage('')
    setStatus('')
    props.actions.cleanUpUserState()
  }
  // for clicking a particular profile card in map, or listview/sidebar
  const handleClickProfile = (id) => {
    let obj = {
      teacher_id: id,
    }
    props.actions.postProfileClickCountAction(obj)
  }


  //---------------------------------------map------------------------------------------------//
  const {isLoaded} = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY,
    libraries: LIBRARIES,
  })

  if (!isLoaded) return

  return (
    <MyDiv>
      {(masterState?.getTeacherSummary?.loading ||
        masterState?.getTeacherData?.loading ||
        masterState?.getCategorySubCategory?.loading) && (
        <div>
          <Loader />
        </div>
      )}
      <AuthTopPanelMain
        // setCategoryName={setCategoryName}
        categoryList={categoryList}
        title1={t('Home')}
        title2={t('allCategories')}
        title3={t('Blog')}
        title4={t('ContactUs')}
        title5={t('Login')}
        title6={t('Register')}
        handleWebSearch={handleWebSearch}
        searchResult={searchResult}
        setSearchResult={setSearchResult}
        setLocation={setLocation}
        setCategoryValue={setCategoryValue}
      />
      <Box className="main_home_box">
        <Box className="home_box">
          <SearchDialog
            handleClick={handleClick}
            // categoryName={categoryName}
            anchorEl={anchorEl}
            handleClose={handleClose}
            searchTerm={searchTerm}
            handleSearchCateg={handleSearchCateg}
            filteredData={filteredData}
            handleSubCategoryChange={handleSubCategoryChange}
            onPlaceChanged={onPlaceChanged}
            onPlaceLoad={onPlaceLoad}
            handleWebSearch={handleWebSearch}
            message={message}
            handleSnackBarClose={handleSnackBarClose}
            open={open}
          />
          {!isLoggedInAdmin && <Typography className="web_subheading1">
            {t('webHeading2')}
          </Typography>}
          <Typography className="web_subheading2">
            {t('webHeading3')}
          </Typography>
          {!isLoggedInAdmin ? <CustomButton
            onClick={() => history.push('/signup')}
            className="register_btn"
            title={t('Registernow')}
          /> : null}
        </Box>
      </Box>

      {/* {isMobile ? <><Box>
        <Typography className="text_title" mt={2} ml={2} mr={2}>
          {t('regText2')}
        </Typography>
        <CategoryCard teacherCardList={teacherCardList} />
      </Box><Box>
        <Typography className="text_title" mt={2} ml={2} mr={2}>{t('regText')}</Typography>
        <RegistrationCard
          handleClickProfile={handleClickProfile}
          teacherSummary={newRegistrations}
        />
      </Box></> : <><Box className="popular_box" mt={7}>
        <Typography className="text_title">{t('regText2')}</Typography>
        <CategoryCard
          teacherCardList={teacherCardList}
        />
        <Box className="btn_box">
          <CustomButton
            title={t('allCategoriesText')}
            className="btn_style"
          />
        </Box>
      </Box><Box className="popular_box" mt={5}>
        <Typography className="text_title">{t('regText')}</Typography>
        <RegistrationCard
          handleClickProfile={handleClickProfile}
          teacherSummary={newRegistrations}
        />
      </Box></>} */}
      {/* {!isMobile &&
      <Grid className="img_grid" container md={12} style={{
        backgroundImage: 'url(https://blog.academbee.com/wp-content/uploads/2023/03/teacher.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'}}
      >
        <Box className="text_img">
          <Box className="width_style">
            <Typography className="img_text1">
              {t('imageText1')}
            </Typography>
            <Typography className="img_text2">
              {t('imageText2')}
            </Typography>
            <Typography className="img_text3">
              {t('imageText3')}
            </Typography>
            <List className="list_texts">
              <ListItem>
                <ListItemAvatar>
                  <Done />
                </ListItemAvatar>
                <ListItemText
                  primary={t('imageText4')}
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Done />
                </ListItemAvatar>
                <ListItemText
                  primary={t('imageText5')}
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Done />
                </ListItemAvatar>
                <ListItemText
                  primary={t('imageText6')}
                />
              </ListItem>
            </List>
            {!isLoggedInAdmin && <CustomButton
              onClick={() => history.push('/signup')}
              title={t('Register')}
              className="btn_style2"
            />}
          </Box>
        </Box>
      </Grid>
      } */}


      {/* <AuthBottomPanelMain isLoggedInAdmin={isLoggedInAdmin} removeCookie={removeCookie} /> */}
      <SnackBar
        message={message}
        severity={status}
        open={message?.length > 0}
        close={handleSnackBarClose}
      />
    </MyDiv>
  )
}


const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
})

export default connect(null, mapDispatchToProps)(WebSearch)
