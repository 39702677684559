/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react'
import {styled} from '@mui/system'
import {Search} from '@mui/icons-material'
import {Box, Typography, MenuItem, Select} from '@mui/material'
import {connect, useSelector} from 'react-redux'
import {bindActionCreators} from 'redux'
import moment from 'moment'
import {CSVLink} from 'react-csv'
import {useTranslation} from 'react-i18next'
import {ActionCreators} from '../../../../redux/actions'
import {CustomTextBox, CustomButton} from '../../../../components/Inputs'
import {CustomDateTimePicker, CustomTable, Loader} from '../../../../components'
import CustomDialog from '../../../../components/CustomDialog/customDialog'
import SnackbarComponent from '../../../../components/SnackBar'
import MyDiv from './customers.style'

const CustomMenuItem = styled(MenuItem)(({theme}) => ({
  '&.MuiMenuItem-root': {
    backgroundColor: '#ffffff',
    fontFamily: 'Proxima Nova',
    fontSize: '15px',
    fontWeight: '600',
    color: '#000000',
  },
  '&.MuiMenuItem-root:first-child': {
    // borderTop: '3px solid #1D3160',
    marginTop: '-8px',
  },
  '&.MuiMenuItem-root:last-child': {
    marginBottom: '-8px',
  },
}))


const tableHeading = [
  {
    id: 1,
    title: 'ID',

  },
  {
    id: 2,
    title: 'Customer Name',

  },
  {
    id: 3,
    title: 'Email',

  },
  {
    id: 4,
    title: 'Registration',

  },
  {
    id: 5,
    title: 'Package',

  },
  {
    id: 6,
    title: 'Status',

  },
  {
    id: 7,
    title: 'View',

  },
  {
    id: 8,
    title: 'Delete',

  },
  {
    id: 9,
    title: 'Degree Upload',

  },
]

const paidStatus = [
  {
    id: 1,
    title: 'All',

  },
  {
    id: 2,
    title: 'Paid',

  },
  {
    id: 3,
    title: 'Unpaid',

  },
]

const Customers = (props) => {
  const [show, setShow] = useState(true)
  const [customerList, setCustomerList] = useState([])
  const [filteredCustomerList, setFilteredCustomerList] = useState([])
  const [searchName, setSearchName] = useState('')
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [customerEmail, setCustomerEmail] = useState(true)
  const [openDialog, setOpenDialog] = useState(false)
  const [openSelect, setOpenSelect] = useState(false)
  const [customerId, setCustomerId] = useState(true)
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('')
  const [checked, setChecked] = useState({})
  const globalState = useSelector((state) => state.globalState)
  const masterState = useSelector((state) => state.masterState)

  useEffect(() => {
    props.actions.getUsersAction()
  }, [])

  useEffect(() => {
    const checkedData = []
    filteredCustomerList?.map((item, index) => {
      checkedData.push(item?.is_doc_verified)
      let obj = Object.assign({}, checkedData)
      setChecked(obj)
    })

  }, [filteredCustomerList])


  useEffect(() => {
    setCustomerList(globalState?.getUsers?.data?.filter((item) => item.is_publish === 'Published'))
    setFilteredCustomerList(globalState?.getUsers?.data?.filter((item) => item.is_publish === 'Published'))
  }, [globalState?.getUsers])

  useEffect(() => {
    if (masterState?.postTeacherData?.data?.status === 'success') {
      setMessage(masterState?.postTeacherData?.data?.message)
      setStatus('success')
    } else {
      setMessage(masterState?.postTeacherData?.data?.message)
      setStatus('error')
    }
  }, [masterState?.postTeacherData?.data?.status])

  useEffect(() => {
    if (masterState?.deleteUser?.data?.data?.meta?.status === 'success') {
      setMessage(masterState?.deleteUser?.data?.data?.meta?.message)
      setStatus('success')
    }
  }, [masterState?.deleteUser])

  useEffect(() => {
    if (masterState?.degreeVerification?.data?.data?.meta?.status === 'success') {
      props.actions.getUsersAction()
    }
  }, [masterState])

  useEffect(() => {
    if (searchName !== '') {
      const result = customerList?.filter((item) => {
        return item.email.toLowerCase().includes(searchName.toLowerCase()) || item.name.toLowerCase().includes(searchName.toLowerCase())
      })
      setFilteredCustomerList(result)
    } else {
      setFilteredCustomerList(customerList)
    }
  }, [searchName])

  const handleSearch = (e) => {
    const searchValue = e.target.value
    setSearchName(searchValue)
    if (searchValue !== '') {
      const result = customerList.filter((item) => {
        return item.email.toLowerCase().includes(searchValue.toLowerCase())
      })
      setFilteredCustomerList(result)
    } else {
      setFilteredCustomerList(customerList)
    }
  }

  const handleStartDate = (date) => {
    setStartDate(date)
    setOpenSelect(true)
  }

  const handleEndDate = (date) => {
    setEndDate(date)
    setOpenSelect(true)
  }

  const handleSearchDate = () => {
    let obj = {
      start: moment(startDate).format('YYYY-MM-DD'),
      end: moment(endDate).format('YYYY-MM-DD'),
    }
    props.actions.getUsersAction(obj)
    const filterOnDate = customerList.filter((item) => {
      return (moment(item.created_at).format('YYYY-MM-DD') <= moment(endDate).format('YYYY-MM-DD'))
      && (moment(item.created_at).format('YYYY-MM-DD') >= moment(startDate).format('YYYY-MM-DD'))
    }
    )
    setCustomerList(filterOnDate)
    setOpenSelect(false)
  }

  const closeMenu = () => {
    setOpenSelect(false)
  }


  const handleDeleteUser = (id, email) => {
    setCustomerId(id)
    setCustomerEmail(email)
    setOpenDialog(!openDialog)
  }

  const handleConfirm = () => {
    setOpenDialog(!openDialog)
    let obj = {
      user_id: customerId,
      email: customerEmail,
    }
    props.actions.deleteUserAction(obj, 'tempDelete')
  }

  const handleSwitch = (event) => {
    if (event.target.value === 1) {
      setFilteredCustomerList(customerList)
    }
    if (event.target.value === 2) {
      const result = customerList?.filter((item) => item.paid_status === 'Paid')
      setFilteredCustomerList(result)
    }
    if (event.target.value === 3) {
      const result = customerList?.filter((item) => item.paid_status === 'Unpaid')
      setFilteredCustomerList(result)
    }
  }


  const handleSnackBarClose = () => {
    setMessage('')
    setStatus('')
    props.actions.cleanUpUserState()
  }
  const sortedDataDesc = filteredCustomerList?.sort((a, b) => {
    return new Date(b.created_at) - new Date(a.created_at)
  })

  const handleChangeSwitch = (e, id) => {
    let obj
    if (e.target.checked === true) {
      obj = {
        user_id: +id,
        is_verified: 1,
      }
    } else if (e.target.checked === false) {
      obj = {
        user_id: +id,
        is_verified: 0,
      }
    }
    props.actions.postDegreeVerifyAction(obj)
  }

  return (
    <MyDiv>
      {(globalState?.getUsers?.loading || masterState?.degreeVerification?.loading) && (
        <div>
          <Loader />
        </div>
      )}
      {show ? <Box className="main_box1">
        {/* <Box classname="main_box">
        </Box> */}
        <Box className="header_top">
          <Typography className="page_title">Customers</Typography>
          <Box className="flex_box">
            <CustomTextBox
              onChange={handleSearch}
              value={searchName} fieldLabel="Search" endIcon={<Search className="icn_size" />}
            />
            <Select value={0} onOpen={() => setOpenSelect(true)} open={openSelect}>
              <CustomMenuItem disabled value={0}>Filter by Date</CustomMenuItem>
              <Box p={2}>
                <Typography>From</Typography>
                <CustomDateTimePicker handleDate={handleStartDate} date={startDate} />
                <Typography mt={1}>To</Typography>
                <CustomDateTimePicker minDate={startDate} handleDate={handleEndDate} date={endDate} />
                <Box sx={{display: 'flex', columnGap: 1}} mt={1}>
                  <CustomButton title="Search" onClick={handleSearchDate} />
                  <CustomButton title="Close" onClick={closeMenu} />
                </Box>
              </Box>
            </Select>
            <Select value={0} onChange={handleSwitch}>
              <CustomMenuItem disabled value={0}>Filter by Payment Status</CustomMenuItem>
              {paidStatus.map((item) => {
                return (
                  <CustomMenuItem value={item.id} key={item.id}>{item.title}</CustomMenuItem>
                )
              })}
            </Select>
            {(filteredCustomerList) && (
              <CSVLink
                data={filteredCustomerList}
                filename={'my-file.csv'}
                className="link_style"
                target="_blank"
              >
                <CustomButton className="btn_style" title="Export CSV" />
              </CSVLink>
            )}
          </Box>
        </Box>
        <CustomTable
          handleDeleteUser={handleDeleteUser}
          tableHeading={tableHeading}
          type="customers"
          // setChecked={setChecked}
          tableData={sortedDataDesc}
          handleChangeSwitch={handleChangeSwitch}
          checked={checked}
        />

      </Box> : null}
      <CustomDialog
        title="Do you want to delete the Customer?"
        handleDelete={handleDeleteUser}
        handleConfirm={handleConfirm}
        openDialog={openDialog}
        type="admin"

      />
      <SnackbarComponent
        message={message}
        severity={status}
        open={message?.length > 0}
        close={handleSnackBarClose}
        type="admin"
      />
    </MyDiv>
  )
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
})

export default connect(null, mapDispatchToProps)(Customers)
