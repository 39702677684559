import styled from 'styled-components'

const MyDiv = styled.div`
.quill_style{
    height: 200px;
    width: 100%;
    @media(max-width:767px){
      height: 200px;
        width: 100%; 
    }
    @media(min-width:768px) and (max-width:1023px){
        width: 100%;
        height: 200px;
    }
}
.quill_style .ql-editor{
    background: #FFFFFF;
    @media(max-width:767px){
        border: 1px solid #ccc;
        width: 100%;
    }
    @media(min-width:768px) and (max-width:1023px){
        width: 100%;
        border: 1px solid #ccc;
    }
}
.ql-toolbar.ql-snow {
    background: #ffffff;
}
.ql-container.ql-snow{ 
    @media(max-width:767px){
      border: none !important;
      background: #FFFFFF;
    }
    @media(min-width:768px) and (max-width:1023px){
      border: none !important;
      background: #FFFFFF;
    }
}
.text-style input{
    font-family: "Proxima Nova";
    font-size: 14px;
    font-weight: 400;
    height: 1.6375em;
  }
.avatar_box{
    position:relative;
}
.camera_upload{
    position: absolute;
    top: 124px;
    left: 130px;
    box-shadow: 0px 3px 6px #00000029;
    background-color: #fff!important;
}
.delete_img{
    position: absolute;
    top: 124px;
    left: 97px;
    box-shadow: 0px 3px 6px #00000029;
    background-color: #fff!important;
    @media(max-width:767px){
        right: 250px;
    }
    @media(min-width:768px) and (max-width:1023px){
        right: 250px;
    }
}
.camera_icon{
    color: #41516C;
    height: 16px;
    width: 16px;
}
.delete_icon{
    color: #f50057;
    height: 16px;
    width: 16px;
}
.avatar_cam{
    font-family: "Arial";
    font-weight: 400;
    font-size: 42px;
    background-color: #FFFFFF;
    border: 1px solid #1D3160;
    color: #1D3160;
    height: 150px;
    width: 150px;
    border-radius:0%;
    object-fit: cover;
}
.demo_box{
    margin-top: 80px;
}
.text_box{
    margin-top: 24px;
}
.school_box{
    padding-left: 50px;
    padding-right: 50px;
    height: 100%;
    @media(max-width:767px){
      border-left: none;
      padding-right: 0;
      padding-left: 0;
      margin-top: 0px;
      height: 100%;
    background-color: #EDF2F7;
    }
    @media(min-width:768px) and (max-width:1023px){
      border-left: none;
      padding-right: 0;
      padding-left: 0;
      margin-top: 0px;
      height: 100%;
    background-color: #EDF2F7;
    }
}
.flex_box{
    @media(max-width:767px){
        display: grid;
    }
    @media(min-width:768px) and (max-width:1023px){
        display: grid;
    }
}
.page_title{
    font-family: "Arial";
    font-size: 18px;
    font-weight: 400;
    color: #1D3160;
    padding:30px 0px;
    @media(max-width:767px){
        text-align: center;
        color: #000000;
    }
    @media(min-width:768px) and (max-width:1023px){
        text-align: center;
        color: #000000;
    }
  }
.title2_text{
    font-family: "Proxima Nova";
    font-size: 24px;
    font-weight: 500;
    color: #000;
}
.title3_text{
    font-family: "Arial";
    font-size: 14px;
    font-weight: 400;
    color: #000000;
}
.title3_text span{
    color: #FF0000;
}
.text_area1{
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    width: 100%;
    height: 40px!important;
    resize: none;
    font-family: "Arial";
    font-size: 14px;
    font-weight: 400;
}
.text_area2{
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    width: 100%;
    height: 100px!important;
    resize: none;
    font-family: "Arial";
    font-size: 14px;
    font-weight: 400;
}
.text_area1: hover{
    border: 1px solid blue;
}
.text_area2: hover{
    border: 1px solid blue;
}
.text_area1: focus-visible{
    border: 1px solid blue;
    outline: 0!important;
}
.text_area2: focus-visible{
    border: 1px solid blue;
    outline: 0!important;
}
.btn_submit{
    top: 64px;
    font-family: "Arial";
    font-weight: 400;
    font-size: 15px;
    color: #0F1350;
    text-transform: capitalize;
    background-color: #fff;
    border: 1px solid #C4D2DF;
    border-radius: 5px;
  }
  .btn_submit:hover{
    color: #0F1350;
    background-color: #fff;
    border: 1px solid #C4D2DF;
  }
`
export default MyDiv
