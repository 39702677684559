import styled from 'styled-components'

const MyDiv = styled.div`
.img_styl{
    width: 100%;
    height: 100%;
}
.text_name{
    font-family: "Arial";
    font-size: 15px;
    font-weight: 600;
    background-color: white;
    color: #000000;
}
.text_name2{
    font-family: "Arial";
    font-size: 15px;
    font-weight: 400;
    background-color: white;
    color: #000000;
}
`
export default MyDiv
