/* eslint-disable max-len */
/* eslint-disable no-constant-condition */
import React, {useEffect, useState} from 'react'
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Typography,
  IconButton,
} from '@mui/material'
import {AccountBalance, Person} from '@mui/icons-material'
import {useTranslation} from 'react-i18next'
import {bindActionCreators} from 'redux'
import {connect, useSelector} from 'react-redux'
import moment from 'moment'
import {ActionCreators} from '../../redux/actions'
import {CustomButton, Loader} from '../../components'
import * as paymentServices from '../../services/paymentServices'
import MyDiv from './subscription.style'

const Subscription = (props) => {
  const {t} = useTranslation()
  const [packagesList, setPackagesList] = useState([])

  const globalState = useSelector((state) => state.globalState)
  const paymentState = useSelector((state) => state.paymentState)
  const masterState = useSelector((state) => state.masterState)

  const expiryDate = masterState?.getIsPublish?.data?.expiry_date
  const accountType = masterState?.getIsPublish?.data?.account_type

  const isPaid = masterState?.getIsPublish?.data?.is_paid
  const unsubscribeStatus = masterState?.getIsPublish?.data?.unsubscribe_status
  const isTrail = masterState?.postAccountStatus?.data?.data?.payload?.is_trial

  const [monthName, setMonthName] = useState(moment(expiryDate).format('MMMM'))

  useEffect(() => {
    if (localStorage.getItem('lang') === 'GK') {
      moment(expiryDate).format('MMMM') === 'January' && setMonthName('Ιανουάριο')
      moment(expiryDate).format('MMMM') === 'February' && setMonthName('Φεβρουάριο')
      moment(expiryDate).format('MMMM') === 'March' && setMonthName('Μάρτιο')
      moment(expiryDate).format('MMMM') === 'April' && setMonthName('Απρίλη')
      moment(expiryDate).format('MMMM') === 'May' && setMonthName('Μάιο')
      moment(expiryDate).format('MMMM') === 'June' && setMonthName('Ιούνιο')
      moment(expiryDate).format('MMMM') === 'July' && setMonthName('Ιούλιο')
      moment(expiryDate).format('MMMM') === 'August' && setMonthName('Αύγουστο')
      moment(expiryDate).format('MMMM') === 'September' && setMonthName('Σεπτέμβριο')
      moment(expiryDate).format('MMMM') === 'October' && setMonthName('Οκτώβριο')
      moment(expiryDate).format('MMMM') === 'November' && setMonthName('Νοέμβριο')
      moment(expiryDate).format('MMMM') === 'December' && setMonthName('Δεκέμβριο')

    } else if (localStorage.getItem('lang') === 'EN') {
      setMonthName(moment(expiryDate).format('MMMM'))
    }
  })

  useEffect(() => {
    if (localStorage.getItem('lang') === 'EN' || 'GK') {
      props.actions.getListAction()
    }
  }, [localStorage.getItem('lang')])


  useEffect(() => {
    let obj = {user_id: masterState?.getIsPublish?.data?.id}
    props.actions.postAccountStatusAction(obj)
    props.actions.getListAction()
  }, [masterState?.getIsPublish?.data?.id])

  useEffect(() => {
    setPackagesList(globalState.getList.data?.payload?.packages)
  }, [globalState.getList.data])

  const getDaysToExpire = (date) => {
    let current = moment().startOf('day')
    let given = moment(date, 'YYYY-MM-DD')
    return !!(
      moment.duration(given.diff(current)).asDays() > 0 &&
      moment.duration(given.diff(current)).asDays() < 31
    )
  }

  const handlePaymentRequest = (paymentId) => () => {
    paymentServices.paymentRequest({package_id: paymentId}).then((response) => {
      window.open(response.redierct_url, '_blank')
    })
  }

  const handleUpgradeRequest = (paymentId) => () => {
    paymentServices.upgradeRequest({package_id: paymentId}).then((response) => {
      window.open(response.redierct_url, '_blank')
    })
  }


  return (
    <MyDiv>
      {(globalState?.getList?.loading ||
        paymentState?.postPayment?.loading ||
        paymentState?.postConfirmPayment?.loading) && (
        <div>
          <Loader />
        </div>
      )}
      <Box className="sub_box">
        <Box className="billing_details">
          <Typography className="page_title">
            {t('SubscriptionDetails')}
          </Typography>
        </Box>
        {unsubscribeStatus === 1 ? <Typography className="card_heading4">{t('cancellationMsg')}</Typography> : <>{isPaid ? <Typography className="card_heading4">{t('subTextPaid')}{' '}{t('monthTill')}{' '}{monthName}{' '}{t('yearTill')}{' '}{moment(expiryDate).format('YYYY')}.{' '}{t('subTextPaid2')}</Typography> : <Typography className="card_heading4">{t('subText')}</Typography>}
          <Box className="card_flex">
            {packagesList?.map((item, index) => (
              <>
                <Card className="card_prop" key={index}>
                  <CardContent>
                    <Typography mb={1} className="card_heading">
                      {item.name}
                    </Typography>
                    <Typography mb={1} className="card_heading4">
                      {item.description}
                    </Typography>
                    <IconButton className="subs_icons">
                      {item.package_id !== 1 ? <AccountBalance /> : <Person />}
                    </IconButton>
                    <Typography mt={1} className="card_heading2">
                      <span>{item.currency}</span>
                      {item.amount} / {item.amount_type}
                    </Typography>
                  </CardContent>
                  <CardActions className="action_btns">
                    {!isPaid &&
                      <CustomButton
                        onClick={handlePaymentRequest(item.package_version_id)}
                        title={item.button_name}
                        className="btn_subs"
                      />}
                    {(isPaid && accountType === item.package_id) &&
                      <CustomButton
                        title={t('subscribedConfirm')}
                        className="btn_subs"
                        disabled
                      />}
                    {(accountType !== item.package_id && isPaid) && <CustomButton
                      onClick={handleUpgradeRequest(item.package_version_id)}
                      title={accountType === 1 ? 'Upgrade to School account' : 'Upgraded to School account'}
                      className="btn_subs"
                      disabled={accountType !== 1}
                    />}
                    {isTrail === 0 && isPaid === true && getDaysToExpire(expiryDate) === true &&
                      <CustomButton
                        onClick={handlePaymentRequest(item.package_version_id)}
                        title={item.upgrade}
                        className="btn_subs"
                      />}
                  </CardActions>
                </Card>
              </>
            ))}
          </Box></>}

      </Box>
    </MyDiv>
  )
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
})

export default connect(null, mapDispatchToProps)(Subscription)
