import {Box, IconButton, Typography} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {connect, useSelector} from 'react-redux'
import {bindActionCreators} from 'redux'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import {CustomTable, Loader, SnackBar} from '../../../../components'
import {ActionCreators} from '../../../../redux/actions'
import RegistrationData from '../RegistrationData'
import CustomDialog from '../../../../components/CustomDialog/customDialog'
import MyDiv from './registrations.style'

const tableHeading = [
  {
    id: 1,
    title: 'ID',

  },
  {
    id: 2,
    title: 'Customer Name',

  },
  {
    id: 3,
    title: 'Email',

  },
  {
    id: 4,
    title: 'Registration',

  },
  {
    id: 5,
    title: 'View',

  },
  {
    id: 6,
    title: 'Delete',

  },
]


const Registrations = (props) => {

  const [customers, setCustomers] = useState()
  const [customerData, setCustomerData] = useState()
  const [customerId, setCustomerId] = useState(true)
  const [customerEmail, setCustomerEmail] = useState(true)
  const [show, setShow] = useState(false)
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('')
  const [openDialog, setOpenDialog] = useState(false)
  const masterState = useSelector((state) => state.masterState)

  useEffect(() => {
    props.actions.unPublishedTeachersAction()
  }, [])

  useEffect(() => {
    setCustomers(masterState?.unPublishedTeachers?.data?.data?.payload)
    setCustomerData(masterState?.getDetailsData?.data)
  }, [masterState])

  useEffect(() => {
    if (masterState?.deleteUser?.data?.data?.meta?.status === 'success') {
      setMessage(masterState?.deleteUser?.data?.data?.meta?.message)
      setStatus('success')
      // props.actions.cleanUpUserState()
    }
  }, [masterState?.deleteUser?.data])

  const handleViewClick = (id) => {
    let obj = {
      user_id: id,
    }
    setShow(!show)
    props.actions.getDetailsAction(id)
    props.actions.postAccountStatusAction(obj)
  }

  const handleDeleteClick = (id, email) => {
    setCustomerId(id)
    setCustomerEmail(email)
    setOpenDialog(!openDialog)
  }

  const handleConfirm = () => {
    setOpenDialog(!openDialog)
    let obj = {
      user_id: customerId,
      email: customerEmail,
    }
    props.actions.deleteUserAction(obj, 'hardDelete')
  }


  const handleSnackBarClose = () => {
    setMessage('')
    setStatus('')
    props.actions.cleanUpUserState()
  }


  return (
    <MyDiv>
      {(masterState?.unPublishedTeachers?.loading || masterState?.deleteUser?.loading) ?
        <div>
          <Loader />
        </div> : <Box className="main_box">
          <Box className="header_top">
            <Typography mb={3} className="page_title">Registrations</Typography>
            {show ?
              <Box>
                <IconButton className="back_btn" onClick={handleViewClick}>
                  <ArrowBackIosNewIcon />
                </IconButton>
                <RegistrationData
                  customerData={customerData}
                />
              </Box>
              : <CustomTable tableHeading={tableHeading} type="registrations" handleViewClick={handleViewClick} handleDeleteClick={handleDeleteClick} tableData={customers} />}
          </Box>
        </Box>}
      <CustomDialog
        title="Do you want to delete the Customer?"
        handleDelete={handleDeleteClick}
        handleConfirm={handleConfirm}
        openDialog={openDialog}
        type="admin"
      />
      <SnackBar
        message={message}
        severity={status}
        open={message?.length > 0}
        close={handleSnackBarClose}
        type="admin"
      />
    </MyDiv>
  )
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch),
})

export default connect(null, mapDispatchToProps)(Registrations)
