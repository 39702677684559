
import styled from 'styled-components'

const MyDiv = styled.div`
.main_grid{
    margin-top: 20px;
    border-radius: 8px;
    background: #ffffff;
    @media(min-width:768px) and (max-width:1023px){
        margin-bottom: 8px;
    }
    @media(max-width:767px){
        margin-bottom: 8px;
    }
}
.web_heading{
    font-family: "Proxima Nova";
    font-size: 45px;
    font-weight: 600;
    line-height: 50px;
    width: 75%;
    @media(min-width:768px) and (max-width:1023px){
        font-size: 30px;
    font-weight: 600;
    text-align: center;
    width: 100%;
    line-height: 40px;
    padding-top: 30px;
    }
    @media(max-width:767px){
        font-size: 30px;
    font-weight: 600;
    text-align: center;
    width: 100%;
    line-height: 40px;
    padding-top: 30px;
    }
}
.category_btn svg{
    position: absolute;
    color: #000;
    top: 7px;
    right: 9px;
}
.category_btn{
    width: 100%;
    flex: 1;
    text-align: start;
    height: 40px;
    color: #000;
    font-family: "Proxima Nova";
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
    position: relative;
    display: block;
    background: #fff;
    border: 1px solid rgb(217 218 216);
    @media(max-width:767px){
        width: -webkit-fill-available;
        width: -moz-available!important;
    }
    @media(min-width:768px) and (max-width:1023px){
        width: -webkit-fill-available;
        width: -moz-available!important;
    }
}
.category_btn:hover{
    display: block;
    background: #fff;
}
.category_btn svg{
    position: absolute;
    color: #000;
    top: 7px;
    right: 9px;
}
.auto_complete input{
    padding: 0px 10px 0px 10px;
    width: -webkit-fill-available;
    width: -moz-available;
    font-family: "Proxima Nova";
    font-size: 14px;
    font-weight: 400;
    border-radius: 3px;
    height: 38px;
    border: 1px solid rgb(217, 217, 217);
    @media(max-width:767px){
        padding: 0px 20px 0px 20px;
        width: -webkit-fill-available;
        width: -moz-available;
    }
    @media(min-width:768px) and (max-width:1023px){
        padding: 0px 20px 0px 20px;
        width: -webkit-fill-available;
        width: -moz-available;
    }
  }
.auto_complete input:hover{
    border: 1px solid blue;
  }
.auto_complete input:focus-visible{
    outline: none;
    border-radius: 0px!important;
  }

.btn-search {
    width: 100%;
    background-color: #F0CB47;
    color: #000;
    font-size: 16px;
    font-weight: 600;
    font-family: "Proxima Nova";
    text-transform: capitalize;
    border: 1px solid transparent;
    height: 40px;
    border-radius: 10px;
    @media(max-width:767px){
        margin-top: 8px;
    }
    @media(min-width:768px) and (max-width:1023px){
        margin-top: 8px;
    }
}
.btn-search:hover{
    background-color: #F0CB47;
    color: #000;
}
`
export default MyDiv
